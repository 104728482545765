import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { MarketplacePage, MarketplacePageStatus } from 'src/api/v1-api';
import RichTextEditor from 'src/components/atoms/rich-text-editor';

interface MarketplacePageFormProps {
  page: Partial<MarketplacePage> | null;
  onCancel: () => void;
  onCreate: (data: Partial<MarketplacePage>) => void;
  onUpdate: (data: MarketplacePage) => void;
}

interface MarketplacePageFields {
  title: string;
  slug: string;
  content: string;
  include_in_sitemap: boolean;
  status: MarketplacePageStatus;
}

const MarketplacePageForm = ({ page, onUpdate, onCreate, onCancel }: MarketplacePageFormProps) => {
  const { control, handleSubmit, setValue } = useForm<MarketplacePageFields>({
    defaultValues: {
      title: page?.title ?? '',
      slug: page?.slug ?? '',
      content: page?.content ?? '',
      include_in_sitemap: page?.include_in_sitemap ?? true,
      status: page?.status ?? MarketplacePageStatus.PUBLISHED,
    },
  });

  useEffect(() => {
    setValue('title', page?.title ?? '');
    setValue('slug', page?.slug ?? '');
    setValue('content', page?.content ?? '');
    setValue('include_in_sitemap', page?.include_in_sitemap ?? true);
    setValue('status', page?.status ?? MarketplacePageStatus.PUBLISHED);
  }, [page]);

  const onSubmit = (data: MarketplacePageFields) => {
    if (page?.id) {
      onUpdate({ ...page, ...data } as MarketplacePage);
    } else {
      onCreate(data);
    }
  };

  return page ? (
    <Flex direction="column" width="100%" pt={8} onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Text fontWeight="semibold" pb={4}>
          {page.id ? 'Edit' : 'New'} Page
        </Text>
      </Flex>
      <Flex as="form" direction="column" w="100%" py={4} gap={4}>
        <Stack>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <FormControl id="title" isRequired>
                <FormLabel>Title</FormLabel>
                <Input type="text" {...field} />
              </FormControl>
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="slug"
            control={control}
            render={({ field }) => (
              <FormControl id="slug" isRequired>
                <FormLabel>Slug</FormLabel>
                <Input type="text" pattern="^[a-zA-Z0-9]+(?:(?:-|_)+[a-zA-Z0-9]+)*$" {...field} />
                <FormHelperText>
                  Enter a valid URL slug, no special characters or spaces.
                </FormHelperText>
              </FormControl>
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="include_in_sitemap"
            control={control}
            render={({ field }) => (
              <FormControl id="include_in_sitemap" display="flex" alignItems="center">
                <FormLabel htmlFor="include_in_sitemap" m={0} mr={4}>
                  Include in Sitemap?
                </FormLabel>
                <Switch
                  size="md"
                  isChecked={field.value}
                  onBlur={field.onBlur}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              </FormControl>
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <FormControl id="status" display="flex" alignItems="center">
                <FormLabel htmlFor="status" m={0} mr={4}>
                  Status
                </FormLabel>
                <Select {...field}>
                  {Object.values(MarketplacePageStatus).map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <FormControl id="content" isRequired>
                <FormLabel>Content</FormLabel>
                <RichTextEditor value={field.value} onChange={(value) => field.onChange(value)} />
              </FormControl>
            )}
          />
        </Stack>
        <Flex direction="row" alignItems="center">
          <Flex direction="row" alignItems="center" gap={4} justifyContent="flex-end" flexGrow="1">
            <Button type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button colorScheme="primary" type="submit">
              Save
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : null;
};

export default MarketplacePageForm;
