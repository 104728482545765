import { Divider, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Attribute, ValueObject } from 'src/api/v1-api';
import InfoTooltip from '../info-tooltip';

type UrlProps = {
  attribute: Attribute;
  urlValue: ValueObject | undefined;
  setChange: ((value: ValueObject) => void) | undefined;
  width?: string;
  isMobileView?: boolean;
  disabled?: boolean;
};

const UrlInput = ({ attribute, urlValue, setChange, width, disabled }: UrlProps): JSX.Element => {
  const [urlValueObj, setUrlValueObj] = useState<ValueObject>({
    id: null,
    value: '',
    text_link: '',
  });

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let urlValueTemp: ValueObject = {
      id: null,
      value: '',
      text_link: '',
    };
    if (e.target.name === 'url') {
      urlValueTemp = { ...urlValueObj, value: e.target.value };
      setUrlValueObj(urlValueTemp);
    } else {
      urlValueTemp = { ...urlValueObj, text_link: e.target.value };
      setUrlValueObj(urlValueTemp);
    }
    if (setChange) setChange(urlValueTemp);
  };

  useEffect(() => {
    setUrlValueObj({ ...(urlValue as ValueObject) });
  }, []);

  const optionalText = attribute?.required ? '' : ' (Optional)';
  return (
    <>
      <FormLabel>
        {attribute?.name} {optionalText}
        <InfoTooltip description={attribute?.seller_description} />
      </FormLabel>

      <FormControl ml={3}>
        <FormLabel>URL</FormLabel>
        <Input
          name="url"
          width={width}
          size="md"
          type="url"
          value={urlValue?.value as string}
          onChange={handleUrlChange}
          disabled={disabled}
          isRequired={attribute?.required}
          maxLength={1000} // Set on the AttributeValueURL django model
        />
      </FormControl>
      <FormControl ml={3} mt={4}>
        <FormLabel>Text</FormLabel>
        <Input
          name="text_link"
          width={width}
          size="md"
          type="text"
          value={urlValue?.text_link}
          onChange={handleUrlChange}
          disabled={disabled}
          placeholder={urlValue?.value as string}
          maxLength={1000} // Set on the AttributeValueURL django model
        />
      </FormControl>
    </>
  );
};

export default UrlInput;
