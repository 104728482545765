import {
  Box,
  ChakraProps,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import React, { ReactNode, useCallback } from 'react';
import { ImageWithFallback } from 'src/components/atoms';
import NextLink from 'next/link';
import { FaChevronRight } from 'react-icons/fa';

interface Props extends ChakraProps {
  categoryId?: string;
  name?: string | null;
  description?: string | null;
  imageURL?: string | null;
  cta: {
    label?: string | null;
    link?: string | null;
    is_external?: boolean;
  };
  className?: string;
  createListing?: boolean;
}

const CategoryCard = ({
  categoryId,
  name,
  description,
  imageURL,
  cta,
  className,
  createListing = false,
  ...rest
}: Props): JSX.Element => {
  const theme = useTheme();
  const ImageLinkWrapper = useCallback(({ children }: { children: ReactNode }) => {
    if (createListing) {
      return (
        <NextLink href={`/listing/create?category=${categoryId ?? ''}`} draggable={false}>
          {children}
        </NextLink>
      );
    }

    if (cta.link && cta.link.length > 0) {
      if (cta.is_external) {
        return (
          <Link href={cta.link} draggable={false}>
            {children}
          </Link>
        );
      } else {
        return (
          <NextLink href={cta.link} draggable={false}>
            {children}
          </NextLink>
        );
      }
    }

    return (
      <NextLink href={`/category/${categoryId}`} draggable={false}>
        {children}
      </NextLink>
    );
  }, []);

  const CtaLinkWrapper = ({ children }: { children: ReactNode }) => {
    if (!cta.link || cta.link === '' || !cta.label || cta.label === '') {
      return null;
    }

    if (cta.is_external) {
      return <Link href={cta.link}>{children}</Link>;
    } else {
      return <NextLink href={cta.link}>{children}</NextLink>;
    }
  };

  const getThemeStyles = () => {
    switch (theme.name) {
      case 'Magazine':
        return {
          borderColor: 'white',
          border: '1px',
          padding: '16px',
        };
      default:
        return {
          borderColor: 'none',
          border: 'none',
          padding: '0',
        };
    }
  };

  return (
    <Flex
      direction={'column'}
      className={className}
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      width={'100%'}
      {...getThemeStyles()}
      {...rest}
    >
      <ImageLinkWrapper>
        <Box
          height="240px"
          width={'100%'}
          minWidth="250px"
          position={'relative'}
          overflow="hidden"
          borderRadius={theme.border_radius.border_radius_1}
        >
          {imageURL && imageURL.length > 0 && (
            <ImageWithFallback src={imageURL} alt={name ?? 'Category Image'} />
          )}
          <Box
            position="absolute"
            inset="0"
            bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 47.92%, #000000 100%)"
            boxSize="full"
          />
          <Flex
            color="white"
            direction="column-reverse"
            position="absolute"
            inset="0"
            // boxSize="full"
            px={{ base: '4', md: '8' }}
            py={{ base: '6', md: '8', lg: '10' }}
          >
            <Stack spacing="5">
              <Stack spacing="1">
                <Heading fontSize="2xl" fontWeight="extrabold" noOfLines={2}>
                  {name}
                </Heading>

                <Text fontSize="md" fontWeight="medium" noOfLines={2}>
                  {description}
                </Text>
                {cta.label && cta.label.length > 0 && (
                  <HStack>
                    <Text fontSize="lg" fontWeight="bold" textDecoration="underline">
                      {cta.label}
                    </Text>
                    <Icon as={FaChevronRight} />
                  </HStack>
                )}
              </Stack>
            </Stack>
          </Flex>
        </Box>
      </ImageLinkWrapper>
    </Flex>
  );
};

export default CategoryCard;
