import {
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Switch,
  Tooltip,
  Icon,
  Divider,
} from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
import { Attribute, ShippingInfo, defaultShippingInfo } from 'src/api/v1-api';
import { AddressInput } from 'src/components/atoms';
import { CATEGORY_TYPE_MARKETPLACE } from 'src/constants/marketplace';

type ListingLocationFormProps = {
  hasLocation: boolean;
  setHasLocation: (value: boolean) => void;
  location: ShippingInfo | null;
  setLocation: (location: ShippingInfo | null) => void;
  shippingInfo: ShippingInfo | null;
  sameAsShippingInfo: boolean;
  setSameAsShippingInfo: (value: boolean) => void;
  requiresLocation?: boolean;
  categoryType?: string;
  addressAttribute?: Attribute;
};

const ListingLocationForm = ({
  hasLocation,
  setHasLocation,
  location,
  setLocation,
  shippingInfo,
  sameAsShippingInfo,
  setSameAsShippingInfo,
  requiresLocation,
  categoryType,
  addressAttribute,
}: ListingLocationFormProps): JSX.Element => {
  // @ts-ignore
  const { id, street_address2, ...shippingCopyWithoutId } = shippingInfo;
  const shippingInfoExists =
    shippingInfo && JSON.stringify(shippingCopyWithoutId) !== JSON.stringify(defaultShippingInfo);
  const switchDisabled = requiresLocation;

  if (!addressAttribute) {
    return <></>;
  }

  const isLocationRequired = requiresLocation || hasLocation;

  return (
    <>
      <Grid width="full" column={2} gap={{ base: 3, md: 5 }} autoColumns="1fr">
        <GridItem colSpan={{ base: 3, md: 3 }}>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">
              Add location?&nbsp;
              <Tooltip label="This will make your listing visible on a map." aria-label="A tooltip">
                <span>
                  <Icon as={MdInfoOutline} boxSize={4} />
                </span>
              </Tooltip>
            </FormLabel>
            <Switch
              size="md"
              isChecked={isLocationRequired}
              isDisabled={switchDisabled}
              onChange={(e) => setHasLocation(e.target.checked)}
              colorScheme="primary"
            />
          </FormControl>
        </GridItem>

        {isLocationRequired && shippingInfoExists && (
          <GridItem colSpan={{ base: 3, md: 3 }}>
            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Same as Shipping Info?</FormLabel>
              <Switch
                size="md"
                isChecked={sameAsShippingInfo}
                onChange={(e) => setSameAsShippingInfo(e.target.checked)}
                colorScheme="primary"
              />
            </FormControl>
          </GridItem>
        )}

        {isLocationRequired && !sameAsShippingInfo && (
          <GridItem colSpan={{ base: 3, md: 3 }}>
            <AddressInput
              addressValue={location}
              setAddressValue={setLocation}
              formType="listing"
              enableCanadianAddresses={categoryType !== CATEGORY_TYPE_MARKETPLACE}
            />
          </GridItem>
        )}
      </Grid>
      <Divider my={{ base: 4, lg: 6 }} />
    </>
  );
};

export default ListingLocationForm;
