import { Category, PaginatedListingsResponse, RootCategory, ViewTypes } from 'src/api/v1-api';
import ListingsGrid from '../listings-grid';
import ListingsMap from '../listings-map';
import ListingsListView from '../listings-list-view';
import { useEffectOnce } from 'usehooks-ts';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface Props {
  propListings?: PaginatedListingsResponse;
  fetchUrl: string;
  initialMapFetchUrl?: string;
  requiresAuth?: boolean;
  inventoryDisplay?: string;
  paginationStyle?: string;
  resultsPerPage?: number;
  mapResultsPerPage?: number;
  actionButtons?: string;
  category?: Category | RootCategory | null;
  propNumDisplayListings?: number;
  userChangedPriceRange?: boolean;
  deactivatedListings?: boolean;
  onMountComplete?: () => void;
}

const ListingsView = (props: Props): JSX.Element => {
  const { fetchUrl, initialMapFetchUrl, requiresAuth, deactivatedListings, mapResultsPerPage } =
    props;
  const { viewType, setPage } = useAttributeSelector();
  const isListViewTypeEnabled = useFeatureFlagEnabled('list_view_type');

  useEffectOnce(() => {
    const url = new URL(window.location.href);
    let parsedPage = 0;
    if (props.deactivatedListings) {
      parsedPage = parseInt(url.searchParams.get('deactivated_listings_page') ?? '1');
    } else {
      parsedPage = parseInt(url.searchParams.get('page') ?? '1');
    }
    setPage(parsedPage);
  });

  if (viewType === ViewTypes.MAP) {
    return (
      <ListingsMap
        fetchUrl={fetchUrl}
        requiresAuth={requiresAuth}
        initialMapFetchUrl={initialMapFetchUrl}
        mapResultsPerPage={mapResultsPerPage}
      />
    );
  }

  if (viewType === ViewTypes.LIST && isListViewTypeEnabled) {
    return <ListingsListView {...props} />;
  }

  return <ListingsGrid {...props} deactivatedListings={deactivatedListings} />;
};

export default ListingsView;
