import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Text,
  FormControl,
  FormLabel,
  Button,
  Box,
  Flex,
  Link,
  Icon,
  Spinner,
  Menu,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList,
  useToast,
  Switch,
  Tooltip,
  theme,
  useTheme,
} from '@chakra-ui/react';
import { SocialProfile } from 'src/api/v1-api';
import { getSocialProfileIcon } from 'src/utils/socialProfileIcons';
import { MdAddCircleOutline, MdMoreVert, MdOutlineAutoMode, MdOutlineDelete } from 'react-icons/md';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { AddSocialProfileForm } from 'src/components/molecules';
import useRequest from 'src/utils/hooks/useRequest';
import { SUPPORTED_AYRSHARE_SOCIAL_NETWORKS } from 'src/utils/marketplaceUtils';
import { InfoOutlineIcon } from '@chakra-ui/icons';

interface SocialProfilesFormProps {
  isSubmitting: boolean;
  updateMarketplaceHandler: (
    payload: object,
    successMessage: string,
    errorMessage: string,
  ) => Promise<void>;
}

const AUTOPILOT_HELP_TEXT =
  'In order to enable Loma Autopilot features for any of your social accounts, we need you to directly connect your account(s). Establishing this initial connection won’t create any automation, but will allow you to enable different Autopilot features afterward. Contact Support with questions.';

const SocialProfilesForm = ({
  isSubmitting,
  updateMarketplaceHandler,
}: SocialProfilesFormProps) => {
  const {
    marketplace,
    connectedSocialProfiles,
    setConnectedSocialProfiles,
    setConnectedSocialProfilesFetched,
  } = useContext(MarketplaceContext);
  const { api } = useRequest();
  const toast = useToast();
  const theme = useTheme();

  const [socialProfiles, setSocialProfiles] = useState<SocialProfile[]>([]);
  const [socialProfile, setSocialProfile] = useState<SocialProfile>({
    id: null,
    network: '',
    username: '',
    url: '',
    total_followers: 0,
    show_on_footer_menu: true,
    show_on_header_menu: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showAutoPilotToolTip, setShowAutoPilotToolTip] = useState<string | null>(null);

  useEffect(() => {
    if (marketplace?.social_profiles) {
      setSocialProfiles(marketplace.social_profiles);
    }
  }, [marketplace?.social_profiles]);

  useEffect(() => {
    if (marketplace) {
      getConnectedSocialProfiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace]);

  const getConnectedSocialProfiles = async () => {
    if (!marketplace) return;

    try {
      setIsLoading(true);
      const response = await api.shareautomation.connectedSocialProfiles.list(marketplace.id);
      setConnectedSocialProfiles(response.connected_accounts);
      setConnectedSocialProfilesFetched(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let updatedSocialProfiles: SocialProfile[] = [...socialProfiles];

    if (socialProfile.id) {
      const index = socialProfiles.findIndex((obj) => obj.id === socialProfile.id);
      if (index !== -1) {
        updatedSocialProfiles = [
          ...socialProfiles.slice(0, index),
          socialProfile,
          ...socialProfiles.slice(index + 1),
        ];
      }
    }

    const emptyLinkExists = updatedSocialProfiles.some((profile) => profile.url === '');
    if (emptyLinkExists) {
      toast({
        title: 'Error',
        description: 'Please fill all social profile links.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }

    const payload = {
      social_profiles: JSON.stringify(updatedSocialProfiles),
    };
    const successMessage = 'Social profiles updated successfully.';
    const errorMessage = 'Unable to save social profiles. Please try again.';
    await updateMarketplaceHandler(payload, successMessage, errorMessage);
  };

  const handleAddSocialProfile = (newSocialProfile: SocialProfile) => {
    if (newSocialProfile.id) {
      const index = socialProfiles.findIndex((obj) => obj.id === newSocialProfile.id);
      if (index !== -1) {
        const updatedSocialProfiles = [
          ...socialProfiles.slice(0, index),
          newSocialProfile,
          ...socialProfiles.slice(index + 1),
        ];
        setSocialProfiles(updatedSocialProfiles);
      }
    } else {
      setSocialProfiles([...socialProfiles, newSocialProfile]);
    }
  };

  const isSocialProfileConnected = (socialProfile: SocialProfile): boolean => {
    const connectedProfile = connectedSocialProfiles.find(
      (profile) =>
        profile.profileUrl.toLowerCase() === socialProfile.url.replace(/\/$/, '').toLowerCase() ||
        (profile.platform === socialProfile.network &&
          (profile.username?.toLowerCase() ===
            socialProfile.username?.replace(/^@/, '').toLowerCase() ||
            profile.pageName?.toLowerCase() ===
              socialProfile.username?.replace(/^@/, '').toLowerCase())),
    );
    return !!connectedProfile;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack position="relative" justify="flex-start" align="flex-start" spacing="28px" w="100%">
        {socialProfiles.length > 0 && (
          <Flex flexDirection="column" px="20px" width="100%">
            {/* desktop table */}
            <Flex
              py={4}
              w="100%"
              borderBottom="1px solid"
              borderColor="gray.200"
              gap={4}
              display={{ base: 'none', md: 'flex' }}
            >
              <Flex minW="100px" w="15%" pl={4}>
                Network
              </Flex>
              <Flex minW="150px" w="25%">
                Username (optional)
              </Flex>
              <Flex minW="200px" w="45%">
                Link
              </Flex>
              <Flex minW="170px" w="10%" justifyContent="end" pr={4}>
                Actions
              </Flex>
            </Flex>
            {socialProfiles.map((socialProfile, index) => (
              <Flex
                flexDirection={'row'}
                key={index}
                w="100%"
                borderBottom="1px solid"
                borderColor="gray.200"
                py={2}
                gap={4}
                display={{ base: 'none', md: 'flex' }}
                alignItems="center"
              >
                <Flex minW="100px" w="15%" pl={4}>
                  {getSocialProfileIcon({
                    networkName: socialProfile.network,
                    fillColor: theme.colors.mainText,
                  })}
                </Flex>
                <Flex minW="150px" w="25%">
                  <Text
                    maxW="12em"
                    flex={1}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {socialProfile.username ?? 'N/A'}
                  </Text>
                </Flex>
                <Flex minW="300px" flex={1} maxW="215px">
                  <Link
                    href={socialProfile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    overflow="hidden"
                  >
                    <Text
                      maxW="300px"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {socialProfile.url}
                    </Text>
                  </Link>
                </Flex>
                <Flex
                  minW="170px"
                  w="10%"
                  justifyContent="end"
                  alignItems="end"
                  pr={4}
                  flex={1}
                  direction="column"
                  gap={2}
                >
                  <Menu>
                    <MenuButton as={IconButton} aria-label="Actions" icon={<MdMoreVert />} />
                    <MenuList>
                      <MenuItem
                        onClick={() => setSocialProfile(socialProfile)}
                        icon={<MdOutlineDelete />}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          setSocialProfiles(socialProfiles.filter((_, i) => i !== index))
                        }
                        icon={<MdOutlineDelete />}
                      >
                        Remove
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          setSocialProfiles(
                            socialProfiles.map((sp, i) =>
                              index === i
                                ? { ...sp, show_on_header_menu: !socialProfile.show_on_header_menu }
                                : sp,
                            ),
                          )
                        }
                        closeOnSelect={false}
                        icon={
                          socialProfile.show_on_header_menu ? (
                            <MdOutlineDelete />
                          ) : (
                            <MdAddCircleOutline />
                          )
                        }
                      >
                        {socialProfile.show_on_header_menu ? 'Remove from header' : 'Add to header'}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          setSocialProfiles(
                            socialProfiles.map((sp, i) =>
                              index === i
                                ? { ...sp, show_on_footer_menu: !socialProfile.show_on_footer_menu }
                                : sp,
                            ),
                          )
                        }
                        closeOnSelect={false}
                        icon={
                          socialProfile.show_on_footer_menu ? (
                            <MdOutlineDelete />
                          ) : (
                            <MdAddCircleOutline />
                          )
                        }
                      >
                        {socialProfile.show_on_footer_menu ? 'Remove from footer' : 'Add to footer'}
                      </MenuItem>
                      {SUPPORTED_AYRSHARE_SOCIAL_NETWORKS.includes(socialProfile.network) && (
                        <MenuItem
                          as={Link}
                          href={`/api/marketplace/${marketplace?.id}/manage_social_connected_accounts`}
                          target="_blank"
                          onMouseEnter={() => setShowAutoPilotToolTip(`desktop-${index}`)}
                          onMouseLeave={() => setShowAutoPilotToolTip(null)}
                          background={isSocialProfileConnected(socialProfile) ? 'green.200' : ''}
                          icon={<MdOutlineAutoMode />}
                        >
                          <Flex alignItems="center">
                            {isSocialProfileConnected(socialProfile)
                              ? 'Autopilot Connected'
                              : 'Connect Autopilot'}
                            <Tooltip
                              hasArrow
                              isOpen={showAutoPilotToolTip === `desktop-${index}`}
                              label={AUTOPILOT_HELP_TEXT}
                              bg="gray.100"
                              color="black"
                            >
                              <InfoOutlineIcon ml={4} />
                            </Tooltip>
                          </Flex>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
            ))}

            {/* mobile table */}
            {socialProfiles.map((socialProfile, index) => (
              <Flex
                flexDirection={'column'}
                key={index}
                w="100%"
                borderBottom="1px solid"
                borderColor="gray.200"
                py={4}
                gap={4}
                display={{ base: 'flex', md: 'none' }}
              >
                <Flex gap={6} maxW="100%">
                  <Flex>
                    {getSocialProfileIcon({
                      networkName: socialProfile.network,
                      fillColor: theme.colors.mainText,
                    })}
                  </Flex>
                  <Flex>
                    <Text
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxW="200px"
                    >
                      {socialProfile.username ?? 'N/A'}
                    </Text>
                  </Flex>
                  <Flex ml="auto">
                    <Button
                      onClick={() =>
                        setSocialProfiles(socialProfiles.filter((_, i) => i !== index))
                      }
                      colorScheme="gray"
                      size="small"
                      px="1"
                    >
                      <Icon as={MdOutlineDelete} boxSize={6} color="red.400" cursor="pointer" />
                    </Button>
                  </Flex>
                </Flex>
                <Flex maxW="250px">
                  <Link
                    href={socialProfile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    overflow="hidden"
                  >
                    <Text
                      maxW="250px"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {socialProfile.url}
                    </Text>
                  </Link>
                </Flex>
                <Flex maxW="250px">
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor={`show-on-menu-${index}`} mb="0">
                      Show on menu?
                    </FormLabel>
                    <Switch
                      isChecked={socialProfile.show_on_header_menu}
                      id={`show-on-menu-${index}`}
                      onChange={(e) =>
                        setSocialProfiles(
                          socialProfiles.map((sp, i) =>
                            index === i ? { ...sp, show_on_header_menu: e.target.checked } : sp,
                          ),
                        )
                      }
                    />
                  </FormControl>
                </Flex>
                <Flex maxW="250px">
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor={`show-on-menu-${index}`} mb="0">
                      Show on menu?
                    </FormLabel>
                    <Switch
                      isChecked={socialProfile.show_on_footer_menu}
                      id={`show-on-menu-${index}`}
                      onChange={(e) =>
                        setSocialProfiles(
                          socialProfiles.map((sp, i) =>
                            index === i ? { ...sp, show_on_footer_menu: e.target.checked } : sp,
                          ),
                        )
                      }
                    />
                  </FormControl>
                </Flex>
                <Flex maxW="250px" alignItems="center">
                  <Button
                    as={Link}
                    href={`/api/marketplace/${marketplace?.id}/manage_social_connected_accounts`}
                    target="_blank"
                    colorScheme={isSocialProfileConnected(socialProfile) ? 'green' : 'gray'}
                    size="small"
                    p="2"
                    leftIcon={<MdOutlineAutoMode />}
                  >
                    {isSocialProfileConnected(socialProfile)
                      ? 'Autopilot Connected'
                      : 'Connect Autopilot'}
                  </Button>
                  <Tooltip
                    hasArrow
                    isOpen={showAutoPilotToolTip === `mobile-${index}`}
                    label={AUTOPILOT_HELP_TEXT}
                    bg="gray.100"
                    color="black"
                  >
                    <InfoOutlineIcon
                      ml={4}
                      onMouseEnter={() => setShowAutoPilotToolTip(`mobile-${index}`)}
                      onMouseLeave={() => setShowAutoPilotToolTip(null)}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}

        <AddSocialProfileForm
          autoPilotHelpText={AUTOPILOT_HELP_TEXT}
          handleAddSocialProfile={handleAddSocialProfile}
          supportedNetworksExternalConnector={SUPPORTED_AYRSHARE_SOCIAL_NETWORKS}
          connectedSocialProfiles={connectedSocialProfiles}
          selectedSocialProfile={socialProfile}
          setSocialProfile={setSocialProfile}
        />
        <Box display="flex" justifyContent="flex-end" pt={10} w="100%">
          <Link href="/">
            <Button variant="outline" colorScheme="primary" mr={4}>
              Cancel
            </Button>
          </Link>
          <Button isLoading={isSubmitting} colorScheme="primary" type="submit">
            Save
          </Button>
        </Box>
      </Stack>
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor="rgba(255, 255, 255, 0.7)"
        >
          <Spinner />
        </Box>
      )}
    </form>
  );
};

export default SocialProfilesForm;
