import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import Slider from 'react-slick';

interface CarouselProps {
  cards: ReactNode[];
}

const settings = {
  arrows: false,
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  lazyLoad: 'ondemand' as const,
  cssEase: 'linear',
  waitForAnimate: false,
};

export default function Carousel({ cards }: CarouselProps) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Box
      position={'relative'}
      width={'full'}
      height={'full'}
      overflow={'visible'}
      visibility={isLoaded ? 'visible' : 'hidden'}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Slider */}
      <Slider {...settings}>
        {cards.map((card, index) => (
          <Box
            key={index}
            display="flex !important"
            justifyContent="center"
            alignItems="center"
            h="full"
            overflow="visible"
          >
            {card}
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
