import { createContext, useContext } from 'react';
import { useAuth } from '../auth';
import { apiRequest } from '../fetchUtils';
import { toFormData } from 'axios';
import {
  GlobalPromoMediaType,
  MarketplacePayout,
  MarketplaceRole,
  Attribute,
  RoleAccount,
  Listing,
  Order,
  MarketplaceEarning,
  SocialConnectedProfile,
  BasicCategory,
  DomainInfo,
  GlobalRole,
  Lead,
  MarketplaceInfo,
  PromoAutomation,
  ThirdPartyQuery,
  CategoryGeneratorBody,
  CategoryGeneratorResponse,
  CategoryRolePermission,
  Bid,
  MaxBid,
  MarketplaceTheme,
  ThemeOptions,
  AttributeOption,
  PromoAutomationTemplateNamespaceMapping,
  TaskLog,
  DigestEmailType,
  DigestCategoryEmailTemplate,
  EmailPreference,
  MarketplacePayoutConfig,
  PaginatedListingsResponse,
  MarketplacePage,
  MarketplacePreviewResponse,
  MarketplaceLinkInBioItem,
  HiddenLayerData,
  MarketplaceUserAccount,
  FallbackListingsResponse,
  ListingsQueriesResponse,
  ListingsLomaResponse,
  ListingAnalytics,
} from 'src/api/v1-api';
import { StripeConnectComponents } from 'src/components/organisms/stripe-connect-form/stripe-connect-onboarding';
import { StripeAccount } from 'src/components/organisms/stripe-connect-form';

export type ApiRequestContextParams = {
  /**
   * Base URL of the endpoint. Could contain final slashes
   */
  baseUrl?: string;
};

const ApiRequestContext = createContext<ApiRequestContextParams>({
  baseUrl: '',
});

export const ApiRequestProvider = ApiRequestContext.Provider;

const useApiRequestConfig = () => {
  return useContext(ApiRequestContext);
};

export type RequestUrlParams = {
  /**
   * Relative URL for the endpoint
   */
  url: string;

  /**
   * Provide a body that will be serialized and sent to the endpoint in JSON format
   */
  body?: unknown;

  /**
   * Provide a pre-formatted body that will be sent to the endpoint in JSON format
   */
  formData?: FormData;

  /**
   * Indicates whether the body contains images and should be treated as form data instead
   */
  hasFiles?: boolean;

  /**
   * Use the access token for this request.
   */
  useToken?: boolean;

  /**
   * Indicates if the token should be refreshed
   */
  shouldRefreshToken?: boolean;

  /**
   * URL parameters to be appended to the URL
   */
  urlParams?: unknown;
};

export type MethodRequestUrlParams = RequestUrlParams & {
  method: string;
};

const useRequest = () => {
  const { getToken, refreshToken, isAuthenticated } = useAuth();
  const { baseUrl } = useApiRequestConfig();

  const performTokenedRequest = async <T>(params: MethodRequestUrlParams) => {
    if (!isAuthenticated) {
      throw new Error('User is not authenticated');
    }
    const { method, url, body, formData, hasFiles } = params;
    const token = await getToken();
    const completeUrl = `${baseUrl}${url}`;

    let parsedBody = body ?? formData;

    if (body && hasFiles) {
      parsedBody = toFormData(body as any);
    }
    const treatAsFormData = hasFiles || !!formData;

    return (await apiRequest(method, completeUrl, token, parsedBody, treatAsFormData)) as T;
  };

  const performUnauthRequest = async <T>(params: MethodRequestUrlParams) => {
    const { method, url, body, formData, hasFiles } = params;
    const completeUrl = `${baseUrl}${url}`;

    let parsedBody = body ?? formData;

    if (body && hasFiles) {
      parsedBody = toFormData(body as any);
    }
    const treatAsFormData = hasFiles || !!formData;

    // Create a new Headers object with CORS-specific headers
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    if (!treatAsFormData) {
      headers.append('Content-Type', 'application/json');
    }

    // Use fetch directly instead of apiRequest
    const response = await fetch(completeUrl, {
      method,
      headers,
      mode: 'cors',
      credentials: 'include',
      body: treatAsFormData
        ? (parsedBody as FormData)
        : parsedBody
        ? JSON.stringify(parsedBody)
        : undefined,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Handle both JSON and text responses
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return (await response.json()) as T;
    }
    return (await response.text()) as T;
  };

  const post = <T>(params: RequestUrlParams) =>
    performTokenedRequest<T>({ method: 'POST', ...params });

  const remove = <T>(params: RequestUrlParams) =>
    performTokenedRequest<T>({ method: 'DELETE', ...params });

  const put = <T>(params: RequestUrlParams) =>
    performTokenedRequest<T>({ method: 'PUT', ...params });

  const deleteRequest = <T>(params: RequestUrlParams) =>
    performTokenedRequest<T>({ method: 'DELETE', ...params });

  const get = async <T>(params: RequestUrlParams) => {
    const { url, body, useToken, shouldRefreshToken, urlParams } = params;
    if (useToken && !isAuthenticated) {
      throw new Error('User is not authenticated');
    }

    const completeUrl = `${baseUrl}${url}`;
    let token: string | null = null;

    if (shouldRefreshToken) {
      token = await refreshToken();
    } else if (useToken) {
      token = await getToken();
    }

    return (await apiRequest('GET', completeUrl, token, body, false, urlParams)) as T;
  };

  const api = {
    ai: {
      getImportLogResults: async (run_ids: string[], key: string) =>
        performUnauthRequest<{ results: unknown | unknown[] }>({
          method: 'POST',
          url: `/get_import_log_results/`,
          useToken: false,
          body: { run_ids, key },
        }),
      createInitialMarketplaceDataFromUsername: async (username: string, network: string) =>
        performUnauthRequest<string | HiddenLayerData>({
          method: 'POST',
          url: `/generate_hidden_layer_data/`,
          useToken: false,
          body: { username, network },
        }),
      createInitialMarketplaceData: async (body: object) =>
        performUnauthRequest<string | MarketplacePreviewResponse>({
          method: 'POST',
          url: `/generate_marketplace_data/`,
          useToken: false,
          body,
        }),
      createListingsStep: async (body: object) =>
        performUnauthRequest<
          | string
          | ListingsQueriesResponse
          | ListingsLomaResponse
          | PaginatedListingsResponse
          | FallbackListingsResponse
        >({
          method: 'POST',
          url: `/generate_listings_step/`,
          useToken: false,
          body,
        }),
      getPreviewImage: async (
        previewType: 'marketplace' | 'seller' | 'payouts',
        marketplace_preview_data_id: string,
      ) =>
        performUnauthRequest<number | string>({
          method: 'GET',
          url: `/generate_marketplace_preview_image/${previewType}/${marketplace_preview_data_id}/`,
          useToken: false,
        }),
      getListingPromoPreviewImage: async (marketplace_preview_data_id: string) =>
        performUnauthRequest<string | { preview_images: string[] }>({
          method: 'GET',
          url: `/generate_listing_promo_preview_image/${marketplace_preview_data_id}/`,
          useToken: false,
        }),
    },
    shareautomation: {
      listings: {
        sharePromoMedia: async (marketplaceId: number, listingId: number, body?: object) =>
          post({
            url: `/shareautomation/${marketplaceId}/listings/${listingId}/promo_media/share`,
            useToken: true,
            body,
          }),
      },
      connectedSocialProfiles: {
        manageConnectionsUrl: async (marketplaceId: number) =>
          get<{ url: string }>({
            url: `/marketplaces/${marketplaceId}/social_connector_profile/manage_connections_url`,
            useToken: true,
          }),
        list: async (marketplaceId: number) =>
          get<{ connected_accounts: SocialConnectedProfile[] }>({
            url: `/marketplaces/${marketplaceId}/social_connector_profile/connected_accounts`,
            useToken: true,
          }),
      },
      globalPromoMediaTypes: {
        list: async (marketplaceId: number) =>
          get<GlobalPromoMediaType[]>({
            url: `/shareautomation/${marketplaceId}/global_promo_media_types/`,
            useToken: true,
          }),
        namespaceMapping: async (marketplaceId: number) =>
          get<PromoAutomationTemplateNamespaceMapping>({
            url: `/shareautomation/${marketplaceId}/global_promo_media_types/namespace_mapping/`,
            useToken: true,
          }),
      },
      promoAutomations: {
        getByCategoryId: async (marketplaceId: number, categoryId: number) =>
          get<PromoAutomation[]>({
            url: `/shareautomation/marketplace/${marketplaceId}/category/${categoryId}/promo_automation/`,
            useToken: true,
          }),
        getByMarketplaceId: async (marketplaceId: number) =>
          get<PromoAutomation[]>({
            url: `/shareautomation/marketplace/${marketplaceId}/promo_automation/`,
            useToken: true,
          }),
        create: async (marketplaceId: number, categoryId: number, body: object) =>
          post<PromoAutomation>({
            url: `/shareautomation/marketplace/${marketplaceId}/category/${categoryId}/promo_automation/`,
            useToken: true,
            body,
          }),
        update: async (
          marketplaceId: number,
          categoryId: number,
          promoAutomationId: number,
          body: Partial<PromoAutomation>,
        ) =>
          put<PromoAutomation>({
            url: `/shareautomation/marketplace/${marketplaceId}/category/${categoryId}/promo_automation/${promoAutomationId}/`,
            useToken: true,
            body,
          }),
        delete: async (marketplaceId: number, categoryId: number, promoAutomationId: number) =>
          deleteRequest<PromoAutomation>({
            url: `/shareautomation/marketplace/${marketplaceId}/category/${categoryId}/promo_automation/${promoAutomationId}/`,
            useToken: true,
          }),
        createPreview: async (marketplaceId: number, categoryId: number, body: object) =>
          post<{ image_url: string; caption: string }>({
            url: `/shareautomation/marketplace/${marketplaceId}/category/${categoryId}/promo_automation/preview/`,
            useToken: true,
            body,
          }),
        duplicate: async (
          marketplaceId: number,
          categoryId: number,
          promoAutomationId: number,
          body: object,
        ) =>
          post<PromoAutomation>({
            url: `/shareautomation/marketplace/${marketplaceId}/category/${categoryId}/promo_automation/${promoAutomationId}/duplicate/`,
            useToken: true,
            body,
          }),
      },
    },
    marketplace: {
      create: {
        create: async (body?: object) =>
          post<MarketplaceInfo>({
            url: `/marketplaces/create/`,
            useToken: true,
            body,
          }),
      },
      update: {
        put: async (marketplaceId: number, formData: FormData) =>
          put<MarketplaceInfo>({
            url: `/marketplaces/${marketplaceId}/`,
            useToken: true,
            formData,
          }),
      },
      info: {
        get: async (marketplaceId: number) =>
          get<MarketplaceInfo>({
            url: `/marketplaces/${marketplaceId}/info/`,
            useToken: true,
          }),
      },
      hostname: {
        get: async (hostname: string) =>
          get<MarketplaceInfo>({
            url: `/marketplaces/${hostname}/hostname/`,
          }),
      },
      roles: {
        list: async (marketplaceId: number) =>
          get<MarketplaceRole[]>({
            url: `/marketplaces/${marketplaceId}/roles/`,
            useToken: true,
          }),
        get: async (marketplaceRoleId: number | string) =>
          get<MarketplaceRole>({
            url: `/marketplaces/roles/${marketplaceRoleId}/`,
            useToken: true,
          }),
        attributes: {
          list: async (marketplaceId: number, marketplaceRoleId: number) =>
            get<Attribute[]>({
              url: `/marketplaces/${marketplaceId}/roles/${marketplaceRoleId}/attributes/`,
              useToken: true,
            }),
        },
        create: async (marketplaceId: number, body: object) =>
          post<MarketplaceRole>({
            url: `/marketplaces/${marketplaceId}/roles/`,
            useToken: true,
            body,
          }),
      },
      earnings: {
        list: async (marketplaceId: number, params?: any) =>
          get<{
            results: MarketplaceEarning[];
            previous: string;
            next: string;
            count: number;
            page_size: number;
            page_number: string;
          }>({
            url: `/marketplaces/${marketplaceId}/earnings/`,
            useToken: true,
            urlParams: params,
          }),
      },
      payouts: {
        list: async (marketplaceId: number, params?: { paid: boolean }) =>
          get<MarketplacePayout[]>({
            url: `/marketplaces/${marketplaceId}/payouts/`,
            useToken: true,
            urlParams: params,
          }),
        create: async (marketplaceId: number, body: object) =>
          post<MarketplacePayout>({
            url: `/marketplaces/${marketplaceId}/payouts/`,
            useToken: true,
            body,
          }),
        balance: async (marketplaceId: number) =>
          get<{ payouts: number; earnings: number; balance: number }>({
            url: `/marketplaces/${marketplaceId}/payout_balance/`,
            useToken: true,
          }),
      },
      payoutConfig: {
        get: async (marketplaceId: number) =>
          get<MarketplacePayoutConfig>({
            url: `/marketplaces/${marketplaceId}/payout_config/`,
            useToken: true,
          }),
        update: async (marketplaceId: number, body: Partial<MarketplacePayoutConfig>) =>
          put<MarketplacePayoutConfig>({
            url: `/marketplaces/${marketplaceId}/payout_config/`,
            useToken: true,
            body,
          }),
      },
      query: {
        list: (marketplaceId: number) =>
          get<ThirdPartyQuery[]>({
            url: `/marketplaces/${marketplaceId}/queries/`,
            useToken: true,
          }),
        get: async (marketplaceId: number, queryId: string) =>
          get<ThirdPartyQuery>({
            url: `/marketplaces/${marketplaceId}/queries/${queryId}/`,
            useToken: true,
          }),
        create: async (marketplaceId: number, body: object) =>
          post<ThirdPartyQuery>({
            url: `/marketplaces/${marketplaceId}/queries/`,
            useToken: true,
            body,
          }),
        update: async (marketplaceId: number, queryId: number, body: object) =>
          put<ThirdPartyQuery>({
            url: `/marketplaces/${marketplaceId}/queries/${queryId}/`,
            useToken: true,
            body,
          }),
        logs: {
          list: async (marketplaceId: number, queryId: string) =>
            get<{
              results: TaskLog[];
              previous: string | null;
              next: string | null;
              count: number;
              page_size: number;
              page_number: number;
            }>({
              url: `/marketplaces/${marketplaceId}/queries/${queryId}/logs/`,
              useToken: true,
            }),
        },
      },
      user: {
        list: async (marketplaceId: number, params?: any) =>
          get<{
            results: MarketplaceUserAccount[];
            previous: string;
            next: string;
            count: number;
            page_size: number;
            page_number: string;
          }>({
            url: `/marketplaces/${marketplaceId}/users/`,
            useToken: true,
            urlParams: params,
          }),
        all: async (marketplaceId: number) =>
          get<MarketplaceUserAccount[]>({
            url: `/marketplaces/${marketplaceId}/users/all`,
            useToken: true,
          }),
        role: {
          get: async (userId: string, roleId: number) =>
            get<RoleAccount>({
              url: `/users/${userId}/role/${roleId}`,
              useToken: true,
            }),
        },
        roles: {
          list: async (marketplaceId: number) =>
            get<RoleAccount[]>({
              url: `/marketplaces/${marketplaceId}/user/roles/`,
              useToken: true,
            }),
          add: async (userId: string, body?: object) =>
            post<RoleAccount>({
              url: `/users/${userId}/roles/`,
              useToken: true,
              body,
            }),
          remove: async (userId: string, roleId: number, body?: object) =>
            remove({
              url: `/users/${userId}/roles/${roleId}`,
              useToken: true,
              body,
            }),
          global: {
            list: async () =>
              get<GlobalRole[]>({
                url: `/roles/global/`,
                useToken: true,
              }),
          },
        },
      },
      listings: {
        get: (listingId: number) =>
          get<Listing>({
            url: `/listings/${listingId}`,
            useToken: true,
          }),
      },
      concierge_listings: {
        get: (marketplaceId: number) =>
          get<Listing[]>({
            url: `/listings/${marketplaceId}/concierge/`,
            useToken: true,
          }),
      },
      leads: {
        create: async (marketplaceId: number, listingId: number, formData: FormData) =>
          post<Lead>({
            url: `/leads/${marketplaceId}/${listingId}/create/`,
            useToken: true,
            formData,
          }),
        followUp: async (marketplaceId: number, leadId: number, body: object) =>
          performUnauthRequest<Lead>({
            method: 'POST',
            url: `/leads/${leadId}/${marketplaceId}/follow_up/`,
            useToken: false,
            body,
          }),
      },
      orders: {
        get: (marketplaceId: number, orderId: number) =>
          get<Order>({
            url: `/orders/${marketplaceId}/${orderId}/`,
            useToken: true,
          }),
      },
      categories: {
        list: async (marketplaceId: number) =>
          get<BasicCategory[]>({
            url: `/marketplaces/${marketplaceId}/all_categories/`,
            useToken: true,
          }),
        createCheckoutSession: async (
          categoryId: number,
          body: { return_url: string; client_reference_id: string },
        ) =>
          post<string>({
            url: `/categories/${categoryId}/checkout_session/`,
            useToken: true,
            body,
          }),
        permissions: {
          get: async (categoryId: number) =>
            get<CategoryRolePermission[]>({
              url: `/marketplaces/categories/${categoryId}/`,
              useToken: true,
            }),
        },
        parents: {
          list: async (categoryId: number) =>
            get<BasicCategory[]>({
              url: `/categories/${categoryId}/parent_list/`,
              useToken: true,
            }),
        },
        generate: async (body: CategoryGeneratorBody) =>
          put<string>({
            url: `/marketplaces/category/json/generator/`,
            useToken: true,
            body,
          }),
        import: async (body: CategoryGeneratorBody) =>
          put<CategoryGeneratorResponse>({
            url: `/marketplaces/category/json/import/`,
            useToken: true,
            body,
          }),
        update: async (marketplaceId: number, categoryId: number, body: Partial<BasicCategory>) =>
          put<BasicCategory>({
            url: `/categories/${marketplaceId}/${categoryId}/`,
            useToken: true,
            body,
          }),
      },
      check_dns: {
        get: (marketplaceId: number) =>
          get<DomainInfo>({
            url: `/marketplaces/${marketplaceId}/check_dns/`,
            useToken: true,
          }),
      },
      check_email_dns: {
        get: (marketplaceId: number) =>
          get<DomainInfo>({
            url: `/marketplaces/${marketplaceId}/check_email_dns/`,
            useToken: true,
          }),
      },
      generate_apex_email_dns: async (
        marketplaceId: number,
        body: { email_domain: string; support_email: string },
      ) =>
        post<DomainInfo>({
          url: `/marketplaces/${marketplaceId}/generate_apex_email_dns/`,
          useToken: true,
          body,
        }),
      domain: {
        get: (marketplaceId: number) =>
          get<DomainInfo>({
            url: `/marketplaces/${marketplaceId}/domain/`,
          }),
      },
      auctions: {
        bids: {
          create: async (auctionId: number, body: { bid_amount: string }) =>
            post<MaxBid>({
              url: `/auctions/${auctionId}/bid/`,
              useToken: true,
              body,
            }),
          list: async (auctionId: number) =>
            get<Bid[]>({
              url: `/auctions/${auctionId}/bids/`,
              useToken: true,
            }),
        },
        anon_max_bid: {
          get: async (auctionId: number) =>
            get<MaxBid>({
              url: `/auctions/${auctionId}/max_bid/`,
            }),
        },
        max_bid: {
          get: async (auctionId: number) =>
            get<MaxBid>({
              url: `/auctions/${auctionId}/max_bid/`,
              useToken: true,
            }),
        },
        relist: {
          post: async (auctionId: number) =>
            post({
              url: `/auctions/${auctionId}/relist-auction/`,
              useToken: true,
            }),
        },
      },
      theme: {
        options: {
          get: async (marketplaceId: number) =>
            get<{ marketplaceTheme: MarketplaceTheme; options: ThemeOptions }>({
              url: `/marketplaces/${marketplaceId}/theme/`,
            }),
        },
      },
      digestCategoryEmailTemplates: {
        getByCategoryId: async (marketplaceId: number, categoryId: number) =>
          get<DigestCategoryEmailTemplate[]>({
            url: `/marketplaces/${marketplaceId}/category/${categoryId}/digest_category_email_templates/`,
            useToken: true,
          }),
        getByMarketplaceId: async (marketplaceId: number) =>
          get<DigestCategoryEmailTemplate[]>({
            url: `/marketplaces/${marketplaceId}/digest_category_email_templates/`,
            useToken: true,
          }),
        update: async (marketplaceId: number, categoryId: number, id: number, payload: object) =>
          put<DigestCategoryEmailTemplate>({
            url: `/marketplaces/${marketplaceId}/category/${categoryId}/digest_category_email_templates/${id}/`,
            useToken: true,
            body: payload,
          }),
        create: async (marketplaceId: number, categoryId: number, payload: object) =>
          post<DigestCategoryEmailTemplate>({
            url: `/marketplaces/${marketplaceId}/category/${categoryId}/digest_category_email_templates/`,
            useToken: true,
            body: payload,
          }),
        delete: async (marketplaceId: number, categoryId: number, id: number) =>
          remove<DigestCategoryEmailTemplate>({
            url: `/marketplaces/${marketplaceId}/category/${categoryId}/digest_category_email_templates/${id}/`,
            useToken: true,
          }),
      },
      emailPreferences: {
        list: async (marketplaceId: number) =>
          get<EmailPreference[]>({
            url: `/marketplaces/${marketplaceId}/email_preferences/`,
            useToken: true,
          }),
        manageSubscription: async (
          marketplaceId: number,
          payload: { digest_category_email_template_id: number; subscribe: boolean },
        ) =>
          post<EmailPreference>({
            url: `/marketplaces/${marketplaceId}/email_preferences/`,
            useToken: true,
            body: payload,
          }),
        manageAllSubscriptions: async (marketplaceId: number, payload: { subscribe: boolean }) =>
          post<EmailPreference[]>({
            url: `/marketplaces/${marketplaceId}/email_preferences/manage_subscriptions/`,
            useToken: true,
            body: payload,
          }),
      },
      pages: {
        list: async (marketplaceId: number) =>
          get<MarketplacePage[]>({
            url: `/marketplaces/${marketplaceId}/pages/`,
            useToken: true,
          }),
        delete: async (marketplaceId: number, pageId: number) =>
          remove<MarketplacePage>({
            url: `/marketplaces/${marketplaceId}/pages/${pageId}/`,
            useToken: true,
          }),
        update: async (marketplaceId: number, pageId: number, body: Partial<MarketplacePage>) =>
          put<MarketplacePage>({
            url: `/marketplaces/${marketplaceId}/pages/${pageId}/`,
            useToken: true,
            body,
          }),
        create: async (marketplaceId: number, body: Partial<MarketplacePage>) =>
          post<MarketplacePage>({
            url: `/marketplaces/${marketplaceId}/pages/`,
            useToken: true,
            body,
          }),
      },
      linkInBioItems: {
        list: async (marketplaceId: number) =>
          get<MarketplaceLinkInBioItem[]>({
            url: `/marketplaces/${marketplaceId}/link_in_bio_items/`,
            useToken: true,
          }),
        update: async (marketplaceId: number, itemId: number, body: object) =>
          put<MarketplaceLinkInBioItem>({
            url: `/marketplaces/${marketplaceId}/link_in_bio_items/${itemId}/`,
            useToken: true,
            body,
          }),
        create: async (marketplaceId: number, body: object) =>
          post<MarketplaceLinkInBioItem>({
            url: `/marketplaces/${marketplaceId}/link_in_bio_items/`,
            useToken: true,
            body,
          }),
        delete: async (marketplaceId: number, itemId: number) =>
          remove<MarketplaceLinkInBioItem>({
            url: `/marketplaces/${marketplaceId}/link_in_bio_items/${itemId}/`,
            useToken: true,
          }),
        updatePositions: async (marketplaceId: number, body: object) =>
          put<MarketplaceLinkInBioItem[]>({
            url: `/marketplaces/${marketplaceId}/link_in_bio_items/update_positions/`,
            useToken: true,
            body,
          }),
      },
      analytics: async (marketplaceId: number) =>
        get<ListingAnalytics[]>({
          url: `/marketplaces/${marketplaceId}/analytics/`,
          useToken: true,
        }),
    },
    users: {
      account: {
        admin: {
          create: async (marketplaceId: number, formData: FormData) =>
            post<string>({
              url: `/marketplaces/${marketplaceId}/create_admin/`,
              useToken: true,
              formData,
            }),
        },
        roles: {
          get: async (userId: number | string, roleId: number) =>
            get<RoleAccount>({
              url: `/users/${userId}/roles/${roleId}/`,
              useToken: true,
            }),
          createCheckoutSession: async (
            userId: number | string,
            roleId: number,
            body: { return_url: string; client_reference_id: string; preauth?: boolean },
          ) =>
            post<string>({
              url: `/users/${userId}/roles/${roleId}/checkout_session/`,
              useToken: true,
              body,
            }),
        },
      },
    },
    checkoutSession: {
      get: async (marketplaceId: number, sessionId: string) =>
        get<any>({
          url: `/checkout_session/${marketplaceId}/${sessionId}/`,
          useToken: true,
        }),
    },
    stripe: {
      account: {
        get: async (marketplaceId: number) =>
          get<StripeAccount>({
            url: `/stripe/${marketplaceId}/account/`,
            useToken: true,
          }),
      },
      accountSession: {
        post: async (marketplaceId: number, body: StripeConnectComponents) =>
          post<string>({
            url: `/stripe/${marketplaceId}/account_session/`,
            useToken: true,
            body,
          }),
      },
      settings: {
        post: async (marketplaceId: number, formData: FormData) =>
          post<StripeAccount>({
            url: `/stripe/${marketplaceId}/settings/`,
            useToken: true,
            formData,
          }),
      },
      oauth: {
        get: async (hostname: string, code: string) =>
          get<string>({
            url: `/stripe/code/${hostname}/${code}/`,
            useToken: false,
          }),
      },
    },
    attributes: {
      options: {
        put: async (
          marketplaceId: number,
          attributeId: number,
          optionId: number,
          body: AttributeOption,
        ) =>
          put<AttributeOption>({
            url: `/attributes/${marketplaceId}/${attributeId}/options/${optionId}/`,
            useToken: true,
            body,
          }),
      },
      get: async (attributeId: number) =>
        get<Attribute>({
          url: `/attributes/${attributeId}/`,
          useToken: false,
        }),
      createValue: async (marketplaceId: number, attributeId: number, body: object) =>
        post<AttributeOption>({
          url: `/attributes/${marketplaceId}/${attributeId}/values/create/`,
          useToken: true,
          body,
        }),
    },
    listings: {
      admin_set_status: async (listingId: number, marketplaceId: number, body: object) =>
        put<Listing>({
          url: `/listings/${listingId}/${marketplaceId}/admin_set_status/`,
          useToken: true,
          body,
        }),
      loma_import: {
        post: async (formData: FormData) =>
          post<number[]>({
            url: `/listings/loma_import/`,
            useToken: true,
            formData,
          }),
      },
      enrich: {
        post: async (formData: FormData) =>
          post<number[]>({
            url: `/listings/enrich_listings/`,
            useToken: true,
            formData,
          }),
      },
    },
    digestEmailTypes: {
      list: async () => get<DigestEmailType[]>({ url: `/digest_email_types/`, useToken: true }),
    },
  };

  return {
    get,
    authGet: <T>(params: RequestUrlParams) =>
      performTokenedRequest<T>({ method: 'GET', ...params }),
    post,
    patch: <T>(params: RequestUrlParams) =>
      performTokenedRequest<T>({ method: 'PATCH', ...params }),
    put,
    delete: deleteRequest,
    api,
    unauthPost: <T>(params: RequestUrlParams) =>
      performUnauthRequest<T>({ method: 'POST', ...params }),
  };
};

export default useRequest;
