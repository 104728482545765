import React, { ReactNode } from 'react';
import { ThemeProvider } from 'src/theme';
import { ErrorBoundary } from 'src/components/system';
import { MarketplaceInfo } from 'src/api/v1-api';
interface AILayoutProps {
  children: ReactNode;
  marketplaceData: MarketplaceInfo;
}

const AILayout: React.FC<AILayoutProps> = ({ children, marketplaceData }) => {
  return (
    <ThemeProvider marketplaceProvider={marketplaceData}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ThemeProvider>
  );
};

export default AILayout;
