import {
  CATEGORY_TYPE_DIRECTORY,
  CATEGORY_TYPE_LEADS,
  CATEGORY_TYPE_MARKETPLACE,
} from 'src/constants/marketplace';
import { apiRequest } from './fetchUtils';
import { User, CategoryRolePermission } from 'src/api/v1-api';

export const fetchCategoryPermissionsRoles = async (categoryId: string | undefined) => {
  if (categoryId) {
    try {
      const url = `${process.env.NEXT_PUBLIC_API_HOST}/categories/${categoryId}/permissions/roles/`;
      const response: CategoryRolePermission[] = await apiRequest('GET', url, null);
      return response;
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
};

export const categoryTypeHasPrice = (categoryType: string | undefined) => {
  return categoryType == CATEGORY_TYPE_MARKETPLACE || categoryType == CATEGORY_TYPE_LEADS;
};

export const categoryTypeLeadOrDirectory = (categoryType: string | undefined) => {
  return categoryType == CATEGORY_TYPE_LEADS || categoryType == CATEGORY_TYPE_DIRECTORY;
};

interface CheckCategoryPermissionsResult {
  hasPermission: boolean;
  errorMessage: string | null;
}

export const checkCategoryPermissions = async (
  categoryId: number | undefined,
  marketplaceId: number | undefined,
  user: User | null,
  hasRoles: (marketplaceId: number, roleNames: string[]) => boolean,
): Promise<CheckCategoryPermissionsResult> => {
  if (!marketplaceId || !categoryId) {
    return { hasPermission: false, errorMessage: null };
  }

  const categoryIdStr = categoryId.toString();
  const categoryRoles = await fetchCategoryPermissionsRoles(categoryIdStr);

  // Filter roles to only include those that have "can_view_listing" permission as true
  const canViewListingsRoles = categoryRoles.filter((role) => role.can_view_listing);
  const canViewListingsRoleNames = canViewListingsRoles.map((role) => role.role.name);

  if (user && hasRoles(marketplaceId, canViewListingsRoleNames)) {
    return { hasPermission: true, errorMessage: null };
  } else if (canViewListingsRoleNames.length === 0) {
    return { hasPermission: true, errorMessage: null };
  }

  let rolesString = '';
  if (canViewListingsRoles.length > 1) {
    rolesString = `The Roles [${canViewListingsRoles
      .map((role) => role.role.name)
      .join(', ')}] are required to view listings in this category.`;
  } else if (canViewListingsRoles.length === 1) {
    const roleName = canViewListingsRoles[0].role.name;
    if (roleName === 'User') {
      rolesString = `Please log in to view listings in this category.`;
    } else {
      rolesString = `The Role "${roleName}" is required to view listings in this category.`;
    }
  } else {
    rolesString = `You must be an Admin to view listings in this category.`;
  }

  return { hasPermission: false, errorMessage: rolesString };
};

export const checkCategoryPermissionsViewListing = async (
  viewListingRoles: string[] | undefined,
  marketplaceId: number | undefined,
  user: User | null,
  hasRoles: (marketplaceId: number, roleNames: string[]) => boolean,
): Promise<CheckCategoryPermissionsResult> => {
  if (!viewListingRoles) {
    return { hasPermission: true, errorMessage: null };
  }

  if (!marketplaceId) {
    return { hasPermission: false, errorMessage: null };
  }

  if (user && hasRoles(marketplaceId, viewListingRoles)) {
    return { hasPermission: true, errorMessage: null };
  } else if (viewListingRoles.length === 0) {
    return { hasPermission: true, errorMessage: null };
  }

  let rolesString = '';
  if (viewListingRoles.length > 1) {
    rolesString = `The Roles [${viewListingRoles}] are required to view listings in this category.`;
  } else if (viewListingRoles.length === 1) {
    const roleName = viewListingRoles[0];
    if (roleName === 'User') {
      rolesString = `Please log in to view listings in this category.`;
    } else {
      rolesString = `The Role "${roleName}" is required to view listings in this category.`;
    }
  } else {
    rolesString = `You must be an Admin to view listings in this category.`;
  }

  return { hasPermission: false, errorMessage: rolesString };
};
