import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  useToast,
  Link,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  Spinner,
  ModalContent,
  ModalHeader,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Textarea,
} from '@chakra-ui/react';
import {
  GlobalPromoMediaType,
  PromoAutomation,
  PromoAutomationEventType,
  PromoAutomationTemplate,
  Schedule,
  SocialConnectedProfile,
  PromoAutomationOtherDestination,
  PromoAutomationTemplateNamespaceMapping,
} from 'src/api/v1-api';
import { Controller, useForm } from 'react-hook-form';
import * as _ from 'lodash';
import { DAILY_OPTION, DAYS_OPTIONS, TIME_OPTIONS } from 'src/utils/common';
import {
  INSTAGRAM_POST_NETWORK_TYPES,
  FACEBOOK_POST_NETWORK_TYPES,
  YOUTUBE_POST_NETWORK_TYPES,
} from 'src/utils/marketplaceUtils';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import useRequest from 'src/utils/hooks/useRequest';
import PromoAutomationTemplateForm from './PromoAutomationTemplateForm';
import CategorySelector from 'src/components/molecules/category-selector';
import DynamicFieldsGuide from './DynamicFieldsGuide';

interface PromoAutomationFormProps {
  promoAutomation: Partial<PromoAutomation>;
  globalPromoMediaTypes: GlobalPromoMediaType[];
  globalPromoMediaTypeNamespaceMapping: PromoAutomationTemplateNamespaceMapping | undefined;
  connectedSocialProfiles: SocialConnectedProfile[];
  onCancel: () => void;
  onUpdatePromoAutomation: (promoAutomation: Partial<PromoAutomation>) => void;
  onCreatePromoAutomation: (promoAutomation: Partial<PromoAutomation>) => void;
  isCategoryEditable?: boolean;
}

enum AutomationEventType {
  CRON_SOCIAL_NETWORK = 'Scheduled',
  EVERY_LISTING_PUBLICATION = 'Created with Every New Listing',
}

interface PromoAutomationFields {
  automationEventType: PromoAutomationEventType;
  globalPromoMediaTypeId: string;
  generatePromoMediaForNewListings: boolean;
  networks: string[];
  otherDestinations: PromoAutomationOtherDestination[];
  time: string;
  days: string[];
  enableAutomation: boolean;
  allowShareDuplicates: boolean;
  useStaticImage: boolean;
  promoImageUrl: string;
  captionText: string;
  automaticallyShareToNetworks: boolean;
  templates: Partial<PromoAutomationTemplate>[];
  entity_id: number | null;
  nickname: string;
  textOnly: boolean;
}

const defaultSchedule: Schedule = {
  time: '1pm ET, 10am PT',
  days: [DAILY_OPTION],
};

const MULTI_TEMPLATE_NETWORKS = ['instagram', 'facebook'];

const PromoAutomationForm = React.forwardRef(function PromoAutomationForm(
  {
    promoAutomation,
    globalPromoMediaTypes,
    globalPromoMediaTypeNamespaceMapping,
    connectedSocialProfiles,
    onCancel,
    onUpdatePromoAutomation,
    onCreatePromoAutomation,
    isCategoryEditable = false,
  }: PromoAutomationFormProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const [filteredGlobalPromoMediaTypes, setFilteredGlobalPromoMediaTypes] = useState<
    GlobalPromoMediaType[]
  >([]);
  const [customSchedule, setCustomSchedule] = useState<boolean>(false);
  const [instagramPostType, setInstagramPostType] = useState<string>('');
  const [facebookPostType, setFacebookPostType] = useState<string>('');
  const [youtubePostType, setYoutubePostType] = useState<string>('');
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [isVideoPostType, setIsVideoPostType] = useState<boolean>(false);
  const [previewPromoMediaData, setPreviewPromoMediaData] = useState<{
    image_url: string;
    caption: string;
  } | null>(null);
  const [filteredOtherDestinations, setFilteredOtherDestinations] = useState<
    PromoAutomationOtherDestination[]
  >([]);

  const { api } = useRequest();
  const { marketplace } = useMarketplaceContext();
  const toast = useToast();

  const newEmptyTemplate: Partial<PromoAutomationTemplate> = {
    use_static_image: false,
    promo_image_url: '',
    global_promo_media_type_id: globalPromoMediaTypes[0]?.id,
    template_values: {},
  };

  const defaultValues = {
    automationEventType:
      promoAutomation.automation_event_type ?? PromoAutomationEventType.CRON_SOCIAL_NETWORK,
    networks: promoAutomation.networks ?? [],
    otherDestinations: promoAutomation.other_destinations ?? [],
    time: promoAutomation.schedule?.time ?? defaultSchedule.time,
    days: promoAutomation.schedule?.days ?? defaultSchedule.days,
    enableAutomation: (promoAutomation.status ?? 'active') === 'active',
    allowShareDuplicates: promoAutomation.allow_share_duplicates ?? false,
    captionText: promoAutomation.caption_text ?? '',
    automaticallyShareToNetworks: promoAutomation.automatically_share_to_networks ?? true,
    templates: promoAutomation.templates ?? [newEmptyTemplate],
    entity_id: promoAutomation.entity_id ?? null,
    nickname: promoAutomation.nickname ?? '',
    textOnly: promoAutomation.text_only ?? false,
  };
  const { control, handleSubmit, watch, setValue, getValues } = useForm<PromoAutomationFields>({
    defaultValues: defaultValues,
  });

  const automationEventTypeSelected = watch(
    'automationEventType',
    PromoAutomationEventType.CRON_SOCIAL_NETWORK,
  );
  const isSharingToNetworksAutomatically = watch('automaticallyShareToNetworks', true);
  const daysSelected = watch('days', defaultSchedule.days);
  const selectedNetworks = watch('networks', []);
  const templates = watch('templates', [newEmptyTemplate]);
  const otherDestinations = watch('otherDestinations', []);
  const entityId = watch('entity_id', null);
  const textOnly = watch('textOnly', false);

  const isMultiTemplate = MULTI_TEMPLATE_NETWORKS.some((network) =>
    selectedNetworks.includes(network),
  );

  useEffect(() => {
    if (isMultiTemplate && templates.length > 1) {
      setValue(
        'networks',
        selectedNetworks.filter((network) => MULTI_TEMPLATE_NETWORKS.includes(network)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  const setInstagramSubNetworks = (networks: string[]) => {
    const instagramSelection = promoAutomation.networks?.find((network) =>
      network.match(/instagram/),
    );
    if (instagramSelection && networks.includes('instagram')) {
      const postType = instagramSelection.split(':')[1] ?? 'stories';
      setInstagramPostType(postType);
    } else {
      setInstagramPostType('');
    }
  };

  const setFacebookSubnetworks = (networks: string[]) => {
    const facebookSelection = promoAutomation.networks?.find((network) =>
      network.match(/facebook/),
    );
    if (facebookSelection && networks.includes('facebook')) {
      const postType = facebookSelection.split(':')[1] ?? 'post';
      setFacebookPostType(postType);
    } else {
      setFacebookPostType('');
    }
  };

  const setYoutubeSubnetworks = (networks: string[]) => {
    const youtubeSelection = promoAutomation.networks?.find((network) => network.match(/youtube/));
    if (youtubeSelection && networks.includes('youtube')) {
      const postType = youtubeSelection.split(':')[1] ?? 'post';
      setYoutubePostType(postType);
    } else {
      setYoutubePostType('');
    }
  };

  useEffect(() => {
    setValue(
      'automationEventType',
      promoAutomation.automation_event_type ?? PromoAutomationEventType.CRON_SOCIAL_NETWORK,
    );
    const promoAutomationNetworks = (promoAutomation.networks ?? []).map(
      (network) => network.split(':')[0],
    );
    const connectedNetworks = connectedSocialProfiles.map((profile) => profile.platform);
    const networks = connectedNetworks.filter((network) =>
      promoAutomationNetworks.includes(network),
    );
    setValue('networks', networks);
    setValue('otherDestinations', promoAutomation.other_destinations ?? []);
    setValue('time', promoAutomation.schedule?.time ?? defaultSchedule.time);
    setValue('days', promoAutomation.schedule?.days ?? defaultSchedule.days);
    setValue('enableAutomation', (promoAutomation.status ?? 'active') === 'active');
    setValue('allowShareDuplicates', promoAutomation.allow_share_duplicates ?? false);
    setValue('captionText', promoAutomation.caption_text ?? '');
    setValue('templates', promoAutomation.templates ?? [newEmptyTemplate]);
    setValue('entity_id', promoAutomation.entity_id ?? null);
    setValue(
      'automaticallyShareToNetworks',
      promoAutomation.automatically_share_to_networks ?? true,
    );
    setValue('nickname', promoAutomation.nickname ?? '');
    setInstagramSubNetworks(networks);
    setFacebookSubnetworks(networks);
    setYoutubeSubnetworks(networks);
    setValue('textOnly', promoAutomation.text_only ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoAutomation]);

  useEffect(() => {
    if (automationEventTypeSelected === PromoAutomationEventType.CRON_SOCIAL_NETWORK) {
      setValue('automaticallyShareToNetworks', true);
      setFilteredOtherDestinations([PromoAutomationOtherDestination.EMAIL]);
    }

    if (automationEventTypeSelected === PromoAutomationEventType.EVERY_LISTING_PUBLICATION) {
      setFilteredOtherDestinations([
        PromoAutomationOtherDestination.EMAIL,
        PromoAutomationOtherDestination.FILES,
      ]);
      setValue(
        'otherDestinations',
        _.uniq([...otherDestinations, PromoAutomationOtherDestination.FILES]),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automationEventTypeSelected, PromoAutomationEventType]);

  useEffect(() => {
    let isExclusiveVideoPostType = false;
    if (selectedNetworks.includes('instagram') && instagramPostType !== '') {
      const instagramPostTypeObj = INSTAGRAM_POST_NETWORK_TYPES.find(
        (type) => type.label === instagramPostType,
      );
      if (instagramPostTypeObj && _.isEqual(instagramPostTypeObj.mediaTypes, ['video'])) {
        isExclusiveVideoPostType = true;
      }
    }

    if (selectedNetworks.includes('facebook') && facebookPostType !== '') {
      const facebookPostTypeObj = FACEBOOK_POST_NETWORK_TYPES.find(
        (type) => type.label === facebookPostType,
      );
      if (facebookPostTypeObj && _.isEqual(facebookPostTypeObj.mediaTypes, ['video'])) {
        isExclusiveVideoPostType = true;
      }
    }

    if (selectedNetworks.includes('youtube') && youtubePostType !== '') {
      const youtubePostTypeObj = YOUTUBE_POST_NETWORK_TYPES.find(
        (type) => type.label === youtubePostType,
      );
      if (youtubePostTypeObj && _.isEqual(youtubePostTypeObj.mediaTypes, ['video'])) {
        isExclusiveVideoPostType = true;
      }
    }

    if (isExclusiveVideoPostType) {
      setValue('textOnly', false);
    }
    setIsVideoPostType(isExclusiveVideoPostType);

    setFilteredGlobalPromoMediaTypes(
      globalPromoMediaTypes.filter((globalPromoMediaType) => {
        if (automationEventTypeSelected === PromoAutomationEventType.EVERY_LISTING_PUBLICATION) {
          if (isExclusiveVideoPostType) {
            return (
              globalPromoMediaType.media_type === 'video' &&
              globalPromoMediaType.entity_type_name === 'Listing'
            );
          } else {
            return globalPromoMediaType.entity_type_name === 'Listing';
          }
        }

        if (isExclusiveVideoPostType) {
          return globalPromoMediaType.media_type === 'video';
        }

        return true;
      }),
    );
  }, [
    automationEventTypeSelected,
    globalPromoMediaTypes,
    selectedNetworks,
    instagramPostType,
    facebookPostType,
    youtubePostType,
  ]);

  useEffect(() => {
    if (selectedNetworks.includes('instagram') && instagramPostType === '') {
      setInstagramPostType('stories');
    }

    if (selectedNetworks.includes('facebook') && facebookPostType === '') {
      setFacebookPostType('post');
    }

    if (selectedNetworks.includes('youtube') && youtubePostType === '') {
      setYoutubePostType('post');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetworks]);

  useEffect(() => {
    setCustomSchedule(!daysSelected.includes(DAILY_OPTION));
  }, [daysSelected]);

  useEffect(() => {
    if (!textOnly && templates.length === 0) {
      setValue('templates', [newEmptyTemplate]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textOnly]);

  const validateData = (data: PromoAutomationFields): boolean => {
    if (!data.entity_id) {
      toast({
        title: 'Please select a category',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (
      data.automationEventType === PromoAutomationEventType.CRON_SOCIAL_NETWORK &&
      data.days?.length === 0
    ) {
      toast({
        title: 'Please select a schedule',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (
      data.automaticallyShareToNetworks &&
      data.networks?.length === 0 &&
      data.otherDestinations?.length === 0
    ) {
      toast({
        title: 'Please select at least one network to share to or other destination',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (data.useStaticImage && !data.promoImageUrl) {
      toast({
        title: 'Please upload an image to use as static image for Promo Automation',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const buildPayload = (
    data: PromoAutomationFields,
    template?: Partial<PromoAutomationTemplate>,
  ) => {
    const networks = [
      ...data.networks.filter((network) => !network.match(/instagram|facebook|youtube/)),
      ...(data.networks.includes('instagram') && instagramPostType !== ''
        ? [`instagram:${instagramPostType}`]
        : []),
      ...(data.networks.includes('facebook') && facebookPostType !== ''
        ? [`facebook:${facebookPostType}`]
        : []),
      ...(data.networks.includes('youtube') && youtubePostType !== ''
        ? [`youtube:${youtubePostType}`]
        : []),
    ];

    const otherDestinations =
      data.automationEventType === PromoAutomationEventType.CRON_SOCIAL_NETWORK
        ? data.otherDestinations.filter(
            (destination) => destination !== PromoAutomationOtherDestination.FILES,
          )
        : _.uniq([...data.otherDestinations, PromoAutomationOtherDestination.FILES]);

    const templates = data.textOnly ? [] : template ? [template] : data.templates;

    return {
      ..._.omit(promoAutomation, [
        'entity_type_object',
        'entity_type_name',
        'global_promo_media_type',
      ]),
      networks,
      other_destinations: otherDestinations,
      schedule: { days: data.days, time: data.time },
      status: data.enableAutomation ? 'active' : 'disabled',
      automation_event_type: data.automationEventType,
      allow_share_duplicates: data.allowShareDuplicates,
      caption_text: data.captionText,
      automatically_share_to_networks: data.automaticallyShareToNetworks,
      templates: templates,
      entity_id: data.entity_id,
      nickname: data.nickname,
      text_only: data.textOnly,
    };
  };

  const decorateBeforeSubmitData = (data: PromoAutomationFields) => {
    const isValidData = validateData(data);
    if (!isValidData) {
      return;
    }

    const payload = buildPayload(data) as Partial<PromoAutomation>;

    if (promoAutomation.id) {
      onUpdatePromoAutomation(payload);
    } else {
      onCreatePromoAutomation(payload);
    }
  };

  const previewPromoMedia = async (template?: Partial<PromoAutomationTemplate>) => {
    if (!marketplace || !entityId) return;

    const data = getValues();
    const isValidData = validateData(data);
    if (!isValidData) return;

    setShowPreviewModal(true);

    const payload = buildPayload(data, template);
    try {
      const response = await api.shareautomation.promoAutomations.createPreview(
        marketplace.id,
        entityId,
        payload,
      );
      setPreviewPromoMediaData(response);
    } catch (err) {
      console.error(err);
      toast({
        title: 'Error',
        description: 'Failed to preview Promo Media. Please review the form and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      closePreviewModal();
    }
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
    setPreviewPromoMediaData(null);
  };

  const updateTemplate = (index: number, template: Partial<PromoAutomationTemplate>) => {
    const updatedTemplates = [...templates];
    updatedTemplates[index] = template;
    setValue('templates', updatedTemplates);
  };

  const addNewTemplate = () => {
    setValue('templates', [...templates, newEmptyTemplate]);
  };

  const removeTemplate = (index: number) => {
    if (templates.length === 1) {
      return;
    }
    const updatedTemplates = templates.filter((_, i) => i !== index);
    setValue('templates', updatedTemplates);
  };

  return (
    <Flex direction="column" ref={ref}>
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Text fontWeight="semibold" pb={4}>
          {promoAutomation.id ? 'Edit' : 'New'} Promo Automation
        </Text>
      </Flex>
      <Stack as="form" onSubmit={handleSubmit(decorateBeforeSubmitData)}>
        <Flex gap={8} direction="column">
          <Stack width="fit-content">
            <FormControl id="entity_type_name">
              <FormLabel>{_.capitalize(promoAutomation.entity_type_name)}</FormLabel>
              {promoAutomation.entity_type_object?.name && !isCategoryEditable ? (
                <Input type="text" value={promoAutomation.entity_type_object.name} disabled />
              ) : (
                <CategorySelector
                  marketplaceId={marketplace?.id}
                  onCategorySelect={(category) => setValue('entity_id', category?.id ?? null)}
                  selectedCategoryId={entityId ? entityId.toString() : undefined}
                />
              )}
            </FormControl>
          </Stack>
          <Stack width="fit-content">
            <Controller
              name="nickname"
              control={control}
              render={({ field }) => (
                <FormControl id="nickname">
                  <FormLabel>Nickname</FormLabel>
                  <Input type="text" {...field} />
                </FormControl>
              )}
            />
          </Stack>
          <Stack width="fit-content">
            <Controller
              name="automationEventType"
              control={control}
              render={({ field }) => (
                <FormControl id="automationEventType" isRequired>
                  <FormLabel>Automation Event Type</FormLabel>
                  <Select {...field}>
                    <option value={PromoAutomationEventType.CRON_SOCIAL_NETWORK}>
                      {AutomationEventType.CRON_SOCIAL_NETWORK}
                    </option>
                    <option value={PromoAutomationEventType.EVERY_LISTING_PUBLICATION}>
                      {AutomationEventType.EVERY_LISTING_PUBLICATION}
                    </option>
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
          {automationEventTypeSelected === PromoAutomationEventType.EVERY_LISTING_PUBLICATION && (
            <Stack>
              <Controller
                name="automaticallyShareToNetworks"
                control={control}
                render={({ field }) => (
                  <FormControl id="automaticallyShareToNetworks" display="flex" alignItems="center">
                    <FormLabel htmlFor="automaticallyShareToNetworks" m={0} mr={4}>
                      Automatically Share To Networks?
                    </FormLabel>
                    <Switch
                      size="md"
                      isChecked={field.value}
                      onBlur={field.onBlur}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  </FormControl>
                )}
              />
            </Stack>
          )}
          {isSharingToNetworksAutomatically && !!connectedSocialProfiles.length && (
            <Controller
              name="networks"
              control={control}
              render={({ field }) => (
                <FormControl id="networks" display="flex" flexDirection="column" mb={4} gap={4}>
                  <FormLabel htmlFor="networks" m={0} mr={4}>
                    Networks
                  </FormLabel>
                  <CheckboxGroup {...field}>
                    <Flex direction="column">
                      {connectedSocialProfiles.map((profile) => (
                        <Flex direction="column" key={profile.platform}>
                          <Checkbox
                            value={profile.platform}
                            disabled={
                              !MULTI_TEMPLATE_NETWORKS.includes(profile.platform) &&
                              templates.length > 1
                            }
                          >
                            {_.capitalize(profile.platform)}
                          </Checkbox>
                          {profile.platform === 'instagram' &&
                            selectedNetworks.includes('instagram') && (
                              <RadioGroup
                                name="instagramPostType"
                                value={instagramPostType}
                                onChange={(value) => setInstagramPostType(value)}
                              >
                                <Flex direction="column" ml={6}>
                                  {INSTAGRAM_POST_NETWORK_TYPES.map((type) => (
                                    <Radio key={type.label} value={type.label}>
                                      {_.capitalize(type.label)}
                                    </Radio>
                                  ))}
                                </Flex>
                              </RadioGroup>
                            )}
                          {profile.platform === 'facebook' &&
                            selectedNetworks.includes('facebook') && (
                              <RadioGroup
                                name="facebookPostType"
                                value={facebookPostType}
                                onChange={(value) => setFacebookPostType(value)}
                              >
                                <Flex direction="column" ml={6}>
                                  {FACEBOOK_POST_NETWORK_TYPES.map((type) => (
                                    <Radio key={type.label} value={type.label}>
                                      {_.capitalize(type.label)}
                                    </Radio>
                                  ))}
                                </Flex>
                              </RadioGroup>
                            )}
                          {profile.platform === 'youtube' &&
                            selectedNetworks.includes('youtube') && (
                              <RadioGroup
                                name="youtubePostType"
                                value={youtubePostType}
                                onChange={(value) => setYoutubePostType(value)}
                              >
                                <Flex direction="column" ml={6}>
                                  {YOUTUBE_POST_NETWORK_TYPES.map((type) => (
                                    <Radio key={type.label} value={type.label}>
                                      {_.capitalize(type.label)}
                                    </Radio>
                                  ))}
                                </Flex>
                              </RadioGroup>
                            )}
                        </Flex>
                      ))}
                    </Flex>
                  </CheckboxGroup>
                </FormControl>
              )}
            />
          )}
          {!connectedSocialProfiles.length && (
            <Alert status="warning" borderRadius="lg">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justifyContent="space-between"
                gap={4}
                alignItems="center"
                w="full"
              >
                <Flex direction="row" alignItems="center">
                  <AlertIcon />
                  <AlertDescription>No connected networks to Loma</AlertDescription>
                </Flex>
                <Flex>
                  <Button
                    colorScheme="orange"
                    variant="outline"
                    as={Link}
                    href={`/api/marketplace/${marketplace?.id}/manage_social_connected_accounts`}
                    target="_blank"
                  >
                    Connect networks to Loma
                  </Button>
                </Flex>
              </Flex>
            </Alert>
          )}
          <Controller
            name="otherDestinations"
            control={control}
            render={({ field }) => (
              <FormControl
                id="otherDestinations"
                display="flex"
                flexDirection="column"
                mb={4}
                gap={4}
              >
                <FormLabel htmlFor="otherDestinations">Other Destinations</FormLabel>
                <CheckboxGroup {...field}>
                  <Flex direction="column">
                    {filteredOtherDestinations.map((destination) => (
                      <Checkbox
                        key={destination}
                        value={destination}
                        isChecked={PromoAutomationOtherDestination.FILES === destination}
                        disabled={PromoAutomationOtherDestination.FILES === destination}
                      >
                        {destination}
                      </Checkbox>
                    ))}
                  </Flex>
                </CheckboxGroup>
              </FormControl>
            )}
          />
          <Stack>
            <Controller
              name="textOnly"
              control={control}
              render={({ field }) => (
                <FormControl id="enableAutomation" display="flex" alignItems="center">
                  <FormLabel htmlFor="enableAutomation" m={0} mr={4}>
                    Create Promo Media?
                  </FormLabel>
                  <Switch
                    size="md"
                    disabled={isVideoPostType}
                    isChecked={!field.value}
                    onBlur={field.onBlur}
                    onChange={(e) => field.onChange(!e.target.checked)}
                  />
                </FormControl>
              )}
            />
          </Stack>
          <Stack>
            <Controller
              name="enableAutomation"
              control={control}
              render={({ field }) => (
                <FormControl id="enableAutomation" display="flex" alignItems="center">
                  <FormLabel htmlFor="enableAutomation" m={0} mr={4}>
                    Enabled?
                  </FormLabel>
                  <Switch
                    size="md"
                    isChecked={field.value}
                    onBlur={field.onBlur}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                </FormControl>
              )}
            />
          </Stack>
          {isSharingToNetworksAutomatically && (
            <Stack>
              <Controller
                name="allowShareDuplicates"
                control={control}
                render={({ field }) => (
                  <FormControl id="allowShareDuplicates" display="flex" alignItems="center">
                    <FormLabel htmlFor="allowShareDuplicates" m={0} mr={4}>
                      Allow Share Duplicate Content?
                    </FormLabel>
                    <Switch
                      size="md"
                      isChecked={field.value}
                      onBlur={field.onBlur}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  </FormControl>
                )}
              />
            </Stack>
          )}
          <Stack>
            <Flex direction="column" mb={4}>
              <FormControl isRequired>
                <FormLabel m={0} mr={4}>
                  Schedule
                </FormLabel>
              </FormControl>
            </Flex>
            {automationEventTypeSelected === PromoAutomationEventType.EVERY_LISTING_PUBLICATION && (
              <Alert status="info">
                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  justifyContent="space-between"
                  gap={4}
                  alignItems="center"
                  w="full"
                >
                  <Flex direction="row" alignItems="center">
                    <AlertIcon />
                    <AlertDescription>
                      Every time a new listing is published in this category, the automated media
                      will be published to the social channel(s).
                    </AlertDescription>
                  </Flex>
                </Flex>
              </Alert>
            )}
            {automationEventTypeSelected === PromoAutomationEventType.CRON_SOCIAL_NETWORK && (
              <>
                <Controller
                  name="days"
                  control={control}
                  render={({ field }) => (
                    <FormControl id="schedule" display="flex" flexDirection="column" mb={4} gap={4}>
                      <RadioGroup
                        name="schedule"
                        value={customSchedule ? 'Custom Schedule' : (daysSelected ?? [])[0]}
                        onChange={(value) => {
                          if (value === 'Custom Schedule') {
                            setCustomSchedule(true);
                            setValue('days', []);
                          } else {
                            setCustomSchedule(false);
                            setValue('days', [value]);
                          }
                        }}
                      >
                        <Flex direction="column">
                          <Radio value={DAILY_OPTION} ml={{ base: 0, md: '0.5rem' }} mt={2}>
                            {DAILY_OPTION}
                          </Radio>
                          <Radio value="Custom Schedule" ml={{ base: 0, md: '0.5rem' }} mt={2}>
                            Custom Schedule
                          </Radio>
                        </Flex>
                      </RadioGroup>
                      {customSchedule && (
                        <CheckboxGroup {...field}>
                          <Flex direction="column" ml={6}>
                            {DAYS_OPTIONS.map((schedule) => (
                              <Checkbox key={schedule} value={schedule} name="schedule">
                                {schedule}
                              </Checkbox>
                            ))}
                          </Flex>
                        </CheckboxGroup>
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      id="time"
                      display="flex"
                      flexDirection="column"
                      mb={4}
                      gap={4}
                      w="fit-content"
                    >
                      <Select {...field}>
                        {TIME_OPTIONS.map((time) => (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </>
            )}
          </Stack>
          <Stack>
            <Controller
              name="captionText"
              control={control}
              render={({ field }) => (
                <FormControl
                  id="captionText"
                  display="flex"
                  flexDirection="column"
                  mb={4}
                  gap={4}
                  isRequired={textOnly}
                >
                  <FormLabel htmlFor="captionText">Caption Text</FormLabel>
                  <Textarea {...field} />
                </FormControl>
              )}
            />
          </Stack>
          {textOnly && (
            <DynamicFieldsGuide
              globalPromoMediaTypeNamespaceMapping={globalPromoMediaTypeNamespaceMapping}
            />
          )}
          {textOnly && (
            <Flex
              direction="row"
              alignItems="center"
              gap={4}
              justifyContent="flex-start"
              flexGrow="1"
            >
              <Button colorScheme="secondary" type="button" onClick={() => previewPromoMedia()}>
                Preview
              </Button>
            </Flex>
          )}
          <Flex direction="row" alignItems="center" gap={4} justifyContent="flex-end" flexGrow="1">
            {isMultiTemplate && !textOnly && (
              <Button type="button" colorScheme="secondary" onClick={addNewTemplate}>
                Add new template
              </Button>
            )}
          </Flex>
          {!textOnly && (
            <Accordion defaultIndex={0} allowMultiple allowToggle>
              {templates.map((template, index) => (
                <AccordionItem key={index}>
                  <h2>
                    <AccordionButton>
                      <Box as="span" textAlign={'left'} flex={1}>
                        Template {index + 1}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <PromoAutomationTemplateForm
                      canRemoveTemplate={templates.length > 1}
                      template={template}
                      globalPromoMediaTypeNamespaceMapping={globalPromoMediaTypeNamespaceMapping}
                      globalPromoMediaTypes={filteredGlobalPromoMediaTypes}
                      removeTemplate={() => removeTemplate(index)}
                      updateTemplateValues={(template: Partial<PromoAutomationTemplate>) =>
                        updateTemplate(index, template)
                      }
                      previewPromoMedia={() => previewPromoMedia(template)}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}

          <Flex direction="row" alignItems="center">
            <Flex
              direction="row"
              alignItems="center"
              gap={4}
              justifyContent="flex-end"
              flexGrow="1"
            >
              <Button type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button colorScheme="primary" type="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Stack>
      <Modal onClose={closePreviewModal} isOpen={showPreviewModal} isCentered>
        <ModalOverlay />
        <ModalContent minH="500px">
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody display="flex">
            <Flex
              alignItems="center"
              direction="column"
              justifyContent="center"
              height="100%"
              margin="auto"
            >
              {!previewPromoMediaData ? (
                <Flex
                  alignItems="center"
                  direction="column"
                  gap={4}
                  justifyContent="center"
                  height="100%"
                  margin="auto"
                >
                  <Text fontWeight="semibold">
                    Most recent Listings in Category will be used to generate preview...
                  </Text>
                  <Spinner />
                </Flex>
              ) : (
                <Flex direction="column" gap={4}>
                  <Text whiteSpace={'pre-wrap'} fontWeight={'semibold'}>
                    {previewPromoMediaData.caption}
                  </Text>
                  {previewPromoMediaData.image_url && (
                    <Image
                      src={previewPromoMediaData.image_url}
                      alt="Promo Media"
                      maxH="500px"
                      w="auto"
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
});

export default PromoAutomationForm;
