import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { IoIosShareAlt } from 'react-icons/io';
import { InstagramSharePreview } from 'src/components/molecules';
import { Listing, PromoMedia as PromoMediaType } from 'src/api/v1-api';
import { useContext, useEffect, useState } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import { PromoMedia } from 'src/components/atoms';
import useRequest from 'src/utils/hooks/useRequest';
import { FiInstagram } from 'react-icons/fi';

interface ShareButtonProps {
  listing: Listing | null;
}

const ShareButton = ({ listing }: ShareButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [promoMediaUrl, setPromoMediaUrl] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasAdmin } = useAuth();
  const { marketplace } = useContext(MarketplaceContext);
  const isAdmin = hasAdmin(marketplace?.id);
  const { get } = useRequest();

  const isWebShareSupported = typeof window !== 'undefined' && 'share' in window.navigator;

  useEffect(() => {
    const fetchPromoMedia = async () => {
      if (!listing) {
        return;
      }

      try {
        const response: PromoMediaType = await get({
          url: `/listings/promo_media/${listing?.id}/`,
          useToken: false,
        });

        if (response.media_url) {
          setPromoMediaUrl(response.media_url);
        }
      } catch (error) {
        console.error('Error fetching promo media:', error);
      }
    };

    fetchPromoMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing]);

  const handleWebShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this awesome listing!',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Something went wrong with sharing:', error);
      }
    }
  };

  if (!isWebShareSupported && !isAdmin) {
    return null;
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          ml={2}
          rightIcon={<IoIosShareAlt fontSize="lg" color="black" />}
          aria-label="Share"
          size="xs"
          border={'1px solid'}
          py={0}
          isLoading={isLoading}
          colorScheme="black"
          variant="outline"
        >
          Share
        </MenuButton>

        <MenuList>
          {isAdmin && (
            <MenuItem onClick={onOpen} icon={<FiInstagram />}>
              Share to Instagram Story (Mobile Only)
            </MenuItem>
          )}
          {isWebShareSupported && <MenuItem onClick={handleWebShare}>Share elsewhere</MenuItem>}
          <PromoMedia promoMediaUrl={promoMediaUrl} />
        </MenuList>
      </Menu>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="gray.100">
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <InstagramSharePreview listing={listing} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareButton;
