import React, { useMemo } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Flex,
  VStack,
  Link,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useAuth } from 'src/utils/auth';
import { apiRequest, handleNextRevalidation } from 'src/utils/fetchUtils';
import { BasicCategory } from 'src/api/v1-api';
import { FormInputValues } from 'src/components/organisms/listing-form';
import { useState } from 'react';
import { hasValidPaymentForCategory } from 'src/utils/listingUtils';

interface Props {
  selectedCategory?: BasicCategory | null;
  listingId?: number | null;
  listing?: FormInputValues | null;
  submitLoading: boolean;
  setSubmitLoading: (loading: boolean) => void;
  submitDraftLoading: boolean;
  writingMode: 'Add' | 'Edit';
  handleSubmit: (e: any) => Promise<void>;
  isDisabled: boolean;
}

const ListingFormSubmit = ({
  selectedCategory,
  listing,
  listingId,
  submitLoading,
  setSubmitLoading,
  submitDraftLoading,
  writingMode,
  handleSubmit,
  isDisabled,
}: Props) => {
  const router = useRouter();
  const { getToken, isAuthenticated } = useAuth();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const is_pending_or_active =
    listing?.status === 'active' || listing?.status === 'pending_approval';
  const isRejected = listing?.status === 'candidate_rejected';
  const inDraftEditingMode = listing?.status === 'draft' && listingId !== null;
  const inCreateMode = listing?.status === 'draft' && listingId === null;
  const saveButtonText = inDraftEditingMode
    ? 'Save Draft'
    : inCreateMode
    ? 'Save Draft'
    : 'Unpublish (Change to Draft)';
  const deleteButtonText = inDraftEditingMode ? 'Delete Draft' : 'Delete';

  const hasValidPayment = useMemo(
    () => hasValidPaymentForCategory(listing?.category_payments, selectedCategory?.id),
    [listing?.category_payments, selectedCategory?.id],
  );

  const handleDelete = async () => {
    if (!listingId) return;

    setDeleteLoading(true);
    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/listings/${listingId}/delete/`;
    try {
      await apiRequest('DELETE', url, token);
    } catch (err: any) {
      console.error('Error deleting listing', err);
      setDeleteLoading(false);
      return;
    }
    router.push('/?success=true&message=Listing+deleted');
  };

  let publishButtonText = 'Publish';

  if (listingId) {
    if (isRejected) {
      publishButtonText = 'Re-Submit for Approval';
    } else {
      publishButtonText = 'Save';
    }
  } else if (selectedCategory?.functional.requires_approval) {
    publishButtonText = 'Submit for Approval';
  } else if (!selectedCategory) {
    publishButtonText = 'Select Category Before Saving';
  }

  if (writingMode === 'Edit' && listing?.status === 'draft') {
    publishButtonText = 'Publish';
  }

  if (selectedCategory?.functional.requires_payment) {
    if (!hasValidPayment) {
      publishButtonText = selectedCategory?.functional.pay_to_list?.cta?.text || 'Pay and Publish';
    }
  }

  const publishButton = (
    <Button
      isLoading={submitLoading}
      colorScheme="primary"
      variant="solid"
      data-action="publish"
      type="submit"
      isDisabled={!isAuthenticated || isDisabled || !selectedCategory}
      data-test="publish-btn"
    >
      {publishButtonText}
    </Button>
  );

  const paymentInfoBox = () => {
    if (!selectedCategory?.functional.requires_payment || hasValidPayment) return null;

    // @ts-ignore
    const alertStyle = selectedCategory?.functional?.pay_to_list?.info?.style ?? 'info';
    // @ts-ignore
    const alertTitle = selectedCategory?.functional?.pay_to_list?.info?.title ?? 'Payment Required';

    const alertDescription =
      // @ts-ignore
      selectedCategory?.functional?.pay_to_list?.info?.text ??
      'After clicking Publish you will be redirected to a payment page. Your listing will be live soon after payment is received.';

    return (
      <>
        <Alert status={alertStyle} variant="left-accent">
          <AlertIcon />
          <AlertTitle mr={2}>{alertTitle}</AlertTitle>
          <AlertDescription>{alertDescription}</AlertDescription>
        </Alert>
      </>
    );
  };

  return (
    <VStack alignItems="flex-end" spacing={4}>
      {paymentInfoBox()}

      <Flex alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap={4}>
        {listingId && (
          <>
            <Button
              colorScheme="red"
              variant="outline"
              onClick={handleDelete}
              isDisabled={!isAuthenticated || isDisabled}
              data-test="listing-delete-btn"
              isLoading={deleteLoading}
            >
              {deleteButtonText}
            </Button>
            <Button colorScheme="primary" variant="outline">
              <NextLink href={`/listing/${listingId}`}>View Listing</NextLink>
              <Link as={NextLink} href={`/listing/${listingId}`} isExternal>
                <ExternalLinkIcon marginBottom={1} marginLeft={1} />
              </Link>
            </Button>
          </>
        )}
        {!!selectedCategory && (
          <Button
            isLoading={submitDraftLoading}
            colorScheme="primary"
            variant="outline"
            data-action="submitDraft"
            type="submit"
            isDisabled={!isAuthenticated || isDisabled}
            onClick={handleSubmit}
            data-test="save-draft-btn"
          >
            {saveButtonText}
          </Button>
        )}
        {publishButton}
      </Flex>
    </VStack>
  );
};

export default ListingFormSubmit;
