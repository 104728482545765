import {
  AspectRatio,
  Box,
  Text,
  Link,
  Flex,
  FlexProps,
  HStack,
  useTheme,
  Divider,
} from '@chakra-ui/react';
import React, { useState, ReactNode, CSSProperties } from 'react';
import { PriceTag, ImageWithFallback, CategoryAttributesDisplay } from 'src/components/atoms';
import { Category, RootCategory, Listing, BasicCategory, CategoryPreview } from 'src/api/v1-api';
import NextLink from 'next/link';
import { categoryTypeHasPrice } from 'src/utils/categoryUtils';
import { formatPrice } from 'src/utils/common';
import AuctionTimeLeft from 'src/components/atoms/auction-time-left';
import { MdMap, MdOutlineTimer } from 'react-icons/md';
import { get_location_from_listing } from 'src/utils/attributeUtils';
import { calculateTimeSinceListed } from 'src/components/atoms/date-since-added';
import {
  determineAuctionInfo,
  filterHiddenAttributes,
  getListingImage,
  getSoldOutMessage,
  onImageError,
} from 'src/utils/listingUtils';

interface Props {
  listing: Listing;
  category?: BasicCategory | Category | RootCategory | CategoryPreview | null;
  loading: boolean;
  useNextLink?: boolean;
  flexProps?: FlexProps;
}

interface DynamicLinkProps {
  href: string;
  useNextLink: boolean;
  style?: CSSProperties;
  children: ReactNode;
}

const ListingRow = ({ listing, category, useNextLink = true, flexProps }: Props): JSX.Element => {
  const auctionInfo = listing.auction_info ? determineAuctionInfo(listing.auction_info) : undefined;
  const theme = useTheme();

  const [imageError, setImageError] = useState(false);

  if (imageError) {
    return <></>;
  }

  const featuredImage =
    getListingImage(listing?.listing_images, category?.functional?.listing_images_required) ?? '';
  const thumbnailImage = listing?.listing_images?.[0]?.thumbnail ?? '';

  const DynamicLink = ({ href, useNextLink, style, children }: DynamicLinkProps) => {
    if (useNextLink) {
      return (
        <NextLink href={href} style={style}>
          {children}
        </NextLink>
      );
    } else {
      return (
        <Link href={href} style={style}>
          {children}
        </Link>
      );
    }
  };

  const LinkWrapper = ({ children }: { children: ReactNode }) => {
    return (
      <DynamicLink
        href={`/listing/${listing.id}`}
        useNextLink={useNextLink}
        style={{ display: 'flex', flexGrow: 1 }}
      >
        {children}
      </DynamicLink>
    );
  };

  const location = get_location_from_listing(listing);

  let timeSinceListed;
  if (listing?.created_at) {
    timeSinceListed = calculateTimeSinceListed(listing?.listed_at ?? listing.created_at);
  }

  return (
    <Flex
      direction="column"
      overflow="hidden"
      data-test="listing-card"
      data-test-id={`${listing.id}`}
      className="listing-item-list-view"
      {...flexProps}
    >
      <LinkWrapper>
        <Flex direction="row" alignItems={'flex-start'} gap={4} width={'100%'}>
          {(thumbnailImage || featuredImage) && (
            <Flex width={'80px'}>
              <AspectRatio ratio={8 / 7} width={'80px'}>
                <Box borderRadius={{ base: theme.border_radius.border_radius_1 }} overflow="hidden">
                  {thumbnailImage ? (
                    <img
                      key={`${listing.id}_thumb`}
                      src={thumbnailImage}
                      alt={listing?.title ?? 'Listing Image'}
                      width={'200px'}
                    />
                  ) : (
                    <ImageWithFallback
                      key={`${listing.id}_${featuredImage}`}
                      src={featuredImage}
                      alt={listing?.title ?? 'Listing Image'}
                      onError={onImageError(listing, setImageError)}
                      width={200}
                    />
                  )}
                </Box>
              </AspectRatio>
            </Flex>
          )}

          <Flex direction="column" flexGrow={1} gap={2}>
            <Flex direction="row" gap={2} justifyContent="space-between" flexGrow={1}>
              <Text
                fontWeight="400"
                noOfLines={2}
                pb={0}
                fontFamily={theme.fonts.font_2}
                fontSize="18px"
                lineHeight="1.5"
                mb={0}
                data-test="card-listing-title"
              >
                {listing.title}
              </Text>
              <Flex direction="column" gap={2} alignItems={'flex-end'} minWidth={'100px'}>
                {listing.status === 'sold' ? (
                  <Text
                    color={`${theme.colors.mainText}CC`}
                    fontWeight={700}
                    fontFamily={theme.fonts.font_2}
                    fontSize={18}
                  >
                    {getSoldOutMessage(category)}
                  </Text>
                ) : (
                  categoryTypeHasPrice(listing?.category_type) &&
                  (listing.lowest_regular_price || listing.lowest_offered_price) && (
                    <Box pb={0}>
                      <PriceTag
                        regularPrice={listing.lowest_regular_price}
                        offeredPrice={listing.lowest_offered_price}
                        currency={listing.currency}
                        regularPriceProps={{
                          fontSize: 18,
                          fontWeight: 700,
                          fontFamily: theme.fonts.font_2,
                        }}
                        offeredPriceProps={{
                          fontSize: 18,
                          fontWeight: 700,
                          fontFamily: theme.fonts.font_2,
                        }}
                      />
                    </Box>
                  )
                )}
              </Flex>
            </Flex>

            {category?.functional?.show_listing_description &&
              listing.source?.toLowerCase() === 'loma' && (
                <Text
                  fontWeight="400"
                  noOfLines={2}
                  fontSize="14px"
                  fontFamily={theme.fonts.font_2}
                  data-test="card-listing-description"
                >
                  {listing.description}
                </Text>
              )}
            {listing?.category_type === 'auction' && (
              <Box>
                {auctionInfo?.max_bid?.max_bid_amount ? (
                  <>
                    <Text as="span" fontWeight="medium">
                      {formatPrice(auctionInfo.max_bid.max_bid_amount, {
                        currency: listing.currency,
                        alwaysCents: false,
                      })}
                    </Text>
                    <Text as="span" fontSize="sm">
                      &nbsp;&mdash; current bid
                    </Text>
                  </>
                ) : (
                  <Text as="span" fontWeight="light">
                    no bids yet
                  </Text>
                )}
                <AuctionTimeLeft auctionEndStr={auctionInfo?.end} />
              </Box>
            )}
            <CategoryAttributesDisplay
              attributeValues={filterHiddenAttributes(listing?.listing_attribute_values)}
            />
            <Flex direction="row" gap={4}>
              {timeSinceListed && (
                <HStack pb={1}>
                  <MdOutlineTimer />
                  <Text fontSize="12px" fontFamily={theme.fonts.font_2}>
                    {timeSinceListed}
                  </Text>
                </HStack>
              )}
              {location && (
                <HStack pb={1}>
                  <MdMap />
                  <Text fontSize="12px" fontFamily={theme.fonts.font_2}>
                    {location}
                  </Text>
                </HStack>
              )}
            </Flex>
          </Flex>
        </Flex>
      </LinkWrapper>
      <Divider pt={2} />
    </Flex>
  );
};

export default ListingRow;
