// @ts-nocheck

import { CircularProgress, Flex, useTheme, useToast } from '@chakra-ui/react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { apiRequest } from 'src/utils/fetchUtils';
import { useAuth } from 'src/utils/auth';
import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect, useRef } from 'react';
import * as path from 'path';
import * as Sentry from '@sentry/nextjs';
import { FilePath } from 'src/components/organisms/listing-form';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
import isEqual from 'lodash/isEqual';

interface LomaUploaderProps {
  maxFiles: number;
  onFileDelete?: (fileToDelete: string) => void;
  onFileUpload?: (filePaths: FilePath[]) => void;
  file_type?: string | undefined;
  filePathsLength: number;
  filePaths?: FilePath[];
  updatedFilePaths?: FilePath[];
  setFilePaths?: ((filePaths: FilePath[]) => void) | undefined;
  uploadingFiles?: string[];
  setUploadingFiles?: Dispatch<SetStateAction<string[]>>;
  parentName?: string;
}

const isEmbeddedBrowser = () => {
  if (typeof window === 'undefined') return false;

  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    userAgent.includes('instagram') ||
    userAgent.includes('fbios') ||
    userAgent.includes('fb_iab') ||
    userAgent.includes('fban')
  );
};

const convertBmpToPng = async (imageToConvert: File) => {
  return new Promise<File>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              const fileExt = path.extname(imageToConvert.name);
              const fileNameNoExt = path.basename(imageToConvert.name, fileExt);
              const convertedFile = new File([blob], `${fileNameNoExt}.png`, {
                type: 'image/png',
              });
              resolve(convertedFile);
            } else {
              reject(new Error('Canvas toBlob conversion failed'));
            }
          }, 'image/png');
        } else {
          reject(new Error('Failed to get canvas context'));
        }
      };
      img.onerror = () => reject(new Error('Image loading failed'));
      img.src = event.target?.result as string;
    };
    reader.onerror = () => reject(new Error('File reading failed'));
    reader.readAsDataURL(imageToConvert);
  });
};

const convertHeicToPng = async (imageToConvert: File) => {
  const heic2any = (await import('heic2any')).default;

  try {
    const convertedBlob = await heic2any({
      blob: imageToConvert,
      toType: 'image/png',
      quality: 0.8,
    });

    const fileExt = path.extname(imageToConvert.name);
    const fileNameNoExt = path.basename(imageToConvert.name, fileExt);

    const convertedFile = new File([convertedBlob as Blob], `${fileNameNoExt}.png`, {
      type: 'image/png',
    });

    return convertedFile;
  } catch (error) {
    return imageToConvert;
  }
};

const cleanForS3 = (fileName) => {
  /* Image transform CDN chokes on encoded characters, so first encode the filename and replace all
  encoded characters with dashes */
  const noPercentFileName = fileName.replace('%', '-');
  const encodedFileName = encodeURIComponent(noPercentFileName);
  const cleanFileName = encodedFileName.replace(/\%../g, '-');
  return cleanFileName;
};

const LomaUploader = ({
  maxFiles,
  onFileDelete,
  onFileUpload,
  file_type,
  filePathsLength,
  filePaths,
  updatedFilePaths,
  setFilePaths,
  uploadingFiles,
  setUploadingFiles,
}: LomaUploaderProps) => {
  const showToast = useSingleToast();
  const { getToken } = useAuth();
  const [isDisabled, setIsDisabled] = useState(false);
  const [filePathList, setFilePathList] = useState(filePaths ? filePaths : []);
  const theme = useTheme();
  const toast = useToast();
  const toastIdRef = useRef<string | number | undefined>();
  const uploadStartTimeRef = useRef<number | null>(null);

  useEffect(() => {
    // update file paths from changes within uploader
    if (setFilePaths && !isEqual(filePaths, filePathList)) {
      setFilePaths(filePathList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePathList, setFilePaths]);

  useEffect(() => {
    // update file paths from changes in image editor
    if (!isEqual(updatedFilePaths, filePaths)) {
      setFilePathList(updatedFilePaths);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFilePaths]);

  const customStyles = {
    dropzone: {
      overflow: 'auto',
      borderRadius: theme.border_radius.border_radius_1,
    },
    inputLabel: { color: 'rgb(113, 128, 150)', fontFamily: theme.fonts.font_2 },
    inputLabelWithFiles: {
      color: 'rgb(113, 128, 150)',
      fontFamily: theme.fonts.font_2,
      marginBottom: '18px',
    },
    input: {
      borderRadius: theme.border_radius.border_radius_1,
    },
    previewStatusContainer: { color: '#68D391' },
  };

  const getPresignedUploadParams = async (fileName: string, retries = 2, delay = 1000) => {
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/generate_presigned_url/`;
    const token = await getToken();

    const body = {
      file_name: fileName,
    };

    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        const response = await apiRequest('POST', url, token, body);
        const uploadUrl = response.url;
        const fileUrl = `${uploadUrl.split('?')[0]}` + fileName;
        const fields = response.fields;

        return { fields, uploadUrl, fileUrl };
      } catch (error) {
        if (attempt < retries - 1) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          showToast({
            title: 'There was an error uploading your file.',
            description: 'Please try again. Contact support if the problem persists.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          Sentry.captureException(
            new Error(`Photo upload: Error fetching presigned URL: ${fileName}`),
            {
              extra: { fileName },
            },
          );
          throw error;
        }
      }
    }
  };

  const getUploadParams = async ({ file, meta: { name } }) => {
    const convertedName = name
      .replace(/\.heic$/i, '.png')
      .replace(/\.heif$/i, '.png')
      .replace(/\.bmp$/i, '.png');
    const body = new FormData();
    let newFile = file;
    if (typeof window !== 'undefined') {
      if (file.type === 'image/heic') {
        newFile = await convertHeicToPng(file);
      } else if (file.type === 'image/bmp') {
        newFile = await convertBmpToPng(file);
      }
    }

    // Prepend the filename with a unique uuid to prevent collisions
    const s3CleanFileName = cleanForS3(convertedName);
    const uniqueFileName = `${uuidv4()}_${s3CleanFileName}`;

    // Initialize meta object if it doesn't exist
    if (!file.meta) {
      file.meta = {};
    }

    // Store our unique identifier in the file metadata
    file.meta.uniqueId = uniqueFileName;

    // Add the unique id to the uploading list (using functional update)
    if (uploadingFiles !== undefined && setUploadingFiles) {
      setUploadingFiles((prevFiles) => [...prevFiles, uniqueFileName]);
    }

    const { fields, uploadUrl, fileUrl } = await getPresignedUploadParams(uniqueFileName);
    if (newFile) {
      for (const field of Object.keys(fields)) body.append(field, fields[field]);
      // the file has to be added after the other fields
      body.append('file', newFile);
      return { meta: { fileUrl, uniqueId: uniqueFileName }, url: uploadUrl, body };
    }
    return { fields, meta: { fileUrl, uniqueId: uniqueFileName }, url: uploadUrl };
  };

  const handleChangeStatus = (file: IFileWithMeta, status, allFiles) => {
    const meta = file.meta;
    const uniqueId = meta.uniqueId;

    // Add upload status tracking
    Sentry.addBreadcrumb({
      category: 'upload_status',
      message: `File ${uniqueId} status changed to ${status}`,
      level: 'info',
      data: {
        uniqueId,
        status,
        fileSize: file.file?.size,
        currentUploadingFiles: uploadingFiles,
        totalFiles: filePathsLength,
      },
    });

    if (status === 'preparing') {
      file.meta.uploadStartTime = Date.now();
      Sentry.addBreadcrumb({
        category: 'upload_status',
        message: `File ${uniqueId} upload started`,
        level: 'info',
        data: {
          fileName: uniqueId,
          fileType: file.file?.type,
          fileSize: file.file?.size,
          status,
        },
      });
    }

    if (status === 'rejected_file_type') {
      showToast({
        title: 'File type not supported',
        description: 'Please upload an image or video.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      if (uploadingFiles !== undefined && setUploadingFiles && uniqueId) {
        setUploadingFiles((prevFiles) => prevFiles.filter((id) => id !== uniqueId));
      }
      Sentry.captureMessage('File type rejected', {
        level: 'info',
        tags: { rejection_reason: 'file_type' },
        extra: { fileName: uniqueId, fileType: file.file?.type, status },
      });
    }

    if (status === 'rejected_max_files') {
      showToast({
        title: 'Maximum number of files reached',
        description: 'Please remove a file before adding another.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      if (uploadingFiles !== undefined && setUploadingFiles && uniqueId) {
        setUploadingFiles((prevFiles) => prevFiles.filter((id) => id !== uniqueId));
      }
      Sentry.captureMessage('Max files exceeded', {
        level: 'info',
        tags: { rejection_reason: 'max_files' },
        extra: {
          currentFiles: filePathsLength,
          maxFiles,
          attemptedFileName: uniqueId,
          status,
        },
      });
    }

    if (['error_upload', 'exception_upload', 'error_upload_params'].includes(status)) {
      let errorDescription =
        'If you are experiencing slow network speeds, try uploading fewer files at a time';
      if (isEmbeddedBrowser()) {
        errorDescription += " or opening the page in your device's default browser";
      }
      errorDescription += '. Contact support if the problem persists.';

      showToast({
        title: "We couldn't upload your file due to a network issue. Please try again.",
        description: errorDescription,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      Sentry.captureException(new Error(`Photo upload: Error uploading file`), {
        tags: { error_type: status },
        extra: {
          fileName: uniqueId,
          status,
          fileSize: file.file?.size,
          fileType: file.file?.type,
          uploadStartTime: file.meta.uploadStartTime,
          timeSinceStart: file.meta.uploadStartTime ? Date.now() - file.meta.uploadStartTime : 0,
        },
      });
      if (uploadingFiles !== undefined && setUploadingFiles && uniqueId) {
        setUploadingFiles((prevFiles) => prevFiles.filter((id) => id !== uniqueId));
      }
    }

    if (status === 'done') {
      // Track successful upload completion
      Sentry.addBreadcrumb({
        category: 'upload_complete',
        message: `File ${uniqueId} uploaded successfully`,
        level: 'info',
        data: {
          uploadDuration: file.meta.uploadStartTime ? Date.now() - file.meta.uploadStartTime : 0,
          fileSize: file.file?.size,
          status,
        },
      });

      // remove the file from uploading list
      if (uploadingFiles !== undefined && setUploadingFiles && uniqueId) {
        setUploadingFiles((prevFiles) => prevFiles.filter((id) => id !== uniqueId));
      }
      setFilePathList((prevPathList) => [
        ...prevPathList,
        { filePath: meta.fileUrl, position: prevPathList.length },
      ]);
    }

    if (status === 'removed') {
      if (uploadingFiles !== undefined && setUploadingFiles && uniqueId) {
        setUploadingFiles((prevFiles) => prevFiles.filter((id) => id !== uniqueId));
      }
      const updatedFilePathList = filePathList.filter((fp) => fp.filePath !== meta.fileUrl);
      updatedFilePathList.forEach((fp, index) => {
        fp.position = index;
      });
      setFilePathList(updatedFilePathList);
      onFileDelete && onFileDelete(meta.fileUrl);
    }
  };

  useEffect(() => {
    onFileUpload && onFileUpload(filePathList);
  }, [filePathList, onFileUpload]);

  const getAcceptedFileTypes = (fileType: string | undefined) => {
    if (fileType && fileType === 'image') {
      return 'image/*';
    } else if (fileType && fileType === 'video') {
      return 'video/*';
    } else if (fileType && fileType === 'image/video') {
      return 'image/*,video/*';
    }
    if (fileType && fileType === 'pdf') {
      return '.pdf';
    } else {
      return 'image/*,video/*,image/heic';
    }
  };

  useEffect(() => {
    if (filePathsLength >= maxFiles) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [filePathsLength, maxFiles, setIsDisabled]);

  useEffect(() => {
    let warningTimer: NodeJS.Timeout;

    if (uploadingFiles && uploadingFiles.length > 0) {
      warningTimer = setTimeout(() => {
        toastIdRef.current = toast({
          title: 'Upload taking longer than expected',
          description: `Still uploading ${uploadingFiles.length} file(s). Please keep the page open.`,
          status: 'info',
          duration: null,
          isClosable: true,
        });
      }, 15000); // Show warning after 15 seconds
    } else if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }

    return () => {
      if (warningTimer) clearTimeout(warningTimer);
    };
  }, [uploadingFiles, toast]);

  return (
    <>
      {uploadingFiles && uploadingFiles.length > 0 && (
        <Flex justifyContent="center" alignItems="center" pb={2}>
          <CircularProgress isIndeterminate color="green.300" />
        </Flex>
      )}
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept={getAcceptedFileTypes(file_type)}
        maxFiles={maxFiles}
        maxSizeBytes={25_000_000}
        multiple={true}
        canCancel={true}
        inputWithFilesContent="Add more"
        styles={customStyles}
        disabled={isDisabled}
      />
    </>
  );
};

export default LomaUploader;
