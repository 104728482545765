import {
  FaAndroid,
  FaApple,
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPodcast,
  FaReddit,
  FaSnapchat,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaYoutube,
  FaPinterest,
  FaThreads,
} from 'react-icons/fa6';
import { IconType } from 'react-icons/lib';
import { MdGroups } from 'react-icons/md';
import { TbWorldWww } from 'react-icons/tb';

const socialProfileIcons = (networkName: string, size: string, fillColor: string): JSX.Element => {
  const iconMap: Record<string, IconType> = {
    facebook: FaFacebook,
    'facebook-group': MdGroups,
    instagram: FaInstagram,
    twitter: FaTwitter,
    snapchat: FaSnapchat,
    youtube: FaYoutube,
    reddit: FaReddit,
    tiktok: FaTiktok,
    discord: FaDiscord,
    linkedin: FaLinkedin,
    twitch: FaTwitch,
    podcast: FaPodcast,
    ios_app: FaApple,
    android_app: FaAndroid,
    website: TbWorldWww,
    pinterest: FaPinterest,
    threads: FaThreads,
  };
  const Icon = iconMap[networkName];
  if (!Icon) {
    return <TbWorldWww fontSize={size} stroke={fillColor} />;
  }
  return <Icon fontSize={size} fill={fillColor} />;
};

interface GetSocialProfileIconProps {
  networkName: string;
  size?: string;
  fillColor?: string;
}

export function getSocialProfileIcon({
  networkName,
  size = '1.25rem',
  fillColor = '#000000',
}: GetSocialProfileIconProps): JSX.Element {
  return (
    socialProfileIcons(networkName, size, fillColor) || (
      <TbWorldWww fontSize="1.25rem" fill={fillColor} />
    )
  );
}
