import { Box, Button, Flex, Icon, Spinner, Text, useTheme } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AttributeOptions,
  Category,
  Listing,
  PaginatedListingsResponse,
  RootCategory,
} from 'src/api/v1-api';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { useGlobalSearch } from 'src/contexts/globalSearch';
import { useAuth } from 'src/utils/auth';
import { apiRequest } from 'src/utils/fetchUtils';
import * as Sentry from '@sentry/react';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { useRouter } from 'next/router';
import { FiPlusCircle } from 'react-icons/fi';
import { LISTINGS_PER_PAGE } from 'src/constants/marketplace';
import { Pagination } from 'src/components/atoms';
import ListingRow from 'src/components/molecules/listing-row';

interface ListingsListViewProps {
  propListings?: PaginatedListingsResponse;
  fetchUrl: string;
  initialMapFetchUrl?: string;
  requiresAuth?: boolean;
  inventoryDisplay?: string;
  resultsPerPage?: number;
  mapResultsPerPage?: number;
  category?: Category | RootCategory | null;
  propNumDisplayListings?: number;
  userChangedPriceRange?: boolean;
  deactivatedListings?: boolean;
  onMountComplete?: () => void;
}

const ListingsListView = ({
  propListings,
  fetchUrl,
  userChangedPriceRange,
  requiresAuth = false,
  inventoryDisplay = '4_2_grid',
  resultsPerPage = LISTINGS_PER_PAGE,
  category = null,
  propNumDisplayListings = LISTINGS_PER_PAGE,
  deactivatedListings,
  onMountComplete,
}: ListingsListViewProps) => {
  const { selectedAttributes, priceRange, selectedSort, page, setPage, maxPrice } =
    useAttributeSelector();
  const { getToken } = useAuth();
  const theme = useTheme();
  const { searchQuery } = useGlobalSearch();
  const { marketplace } = useMarketplaceContext();
  const listingsGridRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const [listings, setListings] = useState<Listing[]>([]);
  const [displayedListings, setDisplayedListings] = useState<Listing[]>([]);
  const [rerouting, setRerouting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [haveFetched, setHaveFetched] = useState<boolean>(false);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [prevLink, setPrevLink] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [numDisplayListings, setNumDisplayListings] = useState<number>(propNumDisplayListings);

  const shouldUsePropListings =
    !!propListings?.results &&
    page === 1 &&
    !Object.keys(selectedAttributes).length &&
    priceRange[0] === 0 &&
    priceRange[1] === 0 &&
    !selectedSort;

  const fetchListings = useCallback(
    async (
      url: string,
      filters: AttributeOptions = {},
      priceRange: [number, number],
      page: number,
    ) => {
      if (!loading && page > 0) {
        setLoading(true);
        const params = new URLSearchParams();

        // If there's a price range and the user has set a value, add it to the params
        if (priceRange[0] !== 0 || (priceRange[1] !== 0 && priceRange[1] != maxPrice)) {
          params.append('price', `${priceRange[0]}-${priceRange[1]}`);
        }

        // Convert filters object to query parameters
        for (const [key, values] of Object.entries(filters)) {
          if (key === 'page' && url.includes('page')) {
            continue;
          }
          for (const value of values) {
            if (value.toString().length > 0) {
              params.append(key, value.toString());
            }
          }
        }
        const paramsString = params.toString();
        const fullUrl = paramsString ? `${url}&${paramsString}` : url;

        try {
          let token = null;
          if (requiresAuth) {
            token = await getToken();
          }

          const response = await apiRequest('GET', fullUrl, token);
          setListings(response.results);
          setNextLink(response.next);
          setPrevLink(response.previous);
          setTotalPages(response.count / resultsPerPage);
          setPage(response.page_number);
          setHaveFetched(true);
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
              component: 'ListingsListView',
              marketplaceId: marketplace?.id,
              categoryId: category?.id,
              page: page,
              params: paramsString,
              url: fullUrl,
            },
          });
        } finally {
          setLoading(false);
        }
        if (userChangedPriceRange || searchQuery != '') {
          listingsGridRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    [getToken, loading, requiresAuth, userChangedPriceRange, searchQuery],
  );

  useEffect(() => {
    // We need to avoid loading when page is 0. This means that the app is
    // currently loading and waiting the page to be updated from query string.
    // After this is set to page = 1 or page = query params page, it should
    // request the data from the api.
    if (page === 0) {
      return;
    }
    setRerouting(false);

    const url = `${fetchUrl}/?page_size=${resultsPerPage}&page=${page}${
      selectedSort ? `&sort=${selectedSort}` : ''
    }${searchQuery ? `&search=${searchQuery}` : ''}`;

    const shouldFetchListings =
      !url.includes('undefined') &&
      inventoryDisplay !== 'None' &&
      !shouldUsePropListings &&
      !haveFetched;

    if (shouldFetchListings) {
      fetchListings(url, selectedAttributes, priceRange, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // selectedListings,
    selectedAttributes,
    priceRange,
    fetchUrl,
    page,
    selectedSort,
    haveFetched,
    shouldUsePropListings,
    searchQuery,
  ]);

  useEffect(() => {
    // Reset the page to 1 when the filters change
    setPage(1);
    setHaveFetched(false);
  }, [selectedAttributes, priceRange, selectedSort, searchQuery]);

  useEffect(() => {
    setDisplayedListings(listings.slice(0, numDisplayListings));

    // If the number of displayed listings is less than LISTINGS_PER_PAGE,
    // and the next link is not null, trigger a Sentry event
    if (numDisplayListings < LISTINGS_PER_PAGE && nextLink) {
      Sentry.captureEvent({
        message: 'ListingsGrid: Number of displayed listings is less than LISTINGS_PER_PAGE',
        extra: {
          nextLink,
          categoryId: category?.id,
          marketplaceId: marketplace?.id,
          page,
          numDisplayListings,
        },
      });
    }
  }, [listings, numDisplayListings]);

  const updateQueryParamAndContextPage = (link?: string | null) => {
    if (link) {
      let pageNumber = '';
      try {
        pageNumber = new URL(link).searchParams.get('page') ?? '1';
      } catch (error) {
        pageNumber = link.split('page=')[1];
      }
      let pageQueryName = 'page';
      if (deactivatedListings) {
        pageQueryName = 'deactivated_listings_page';
      }
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set(pageQueryName, pageNumber);
      setPage(parseInt(pageNumber));
      // setNumDisplayListings(propNumDisplayListings);
      const searchParams = new URLSearchParams();
      currentUrl.searchParams.forEach((value, key) => {
        searchParams.append(key, value);
      });
      const newUrl = `${currentUrl.origin}${currentUrl.pathname}?${searchParams.toString()}`;
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
      setHaveFetched(false);
    }
  };

  const handleNextPage = () => {
    if (nextLink) {
      setRerouting(true);
      updateQueryParamAndContextPage(nextLink);
      listingsGridRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handlePrevPage = () => {
    if (prevLink) {
      setRerouting(true);
      updateQueryParamAndContextPage(prevLink);
      listingsGridRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (onMountComplete && haveFetched) {
      onMountComplete();
    }
  }, [onMountComplete, haveFetched]);

  return (
    <Flex direction="column" width="100%" gap={4} pb={8} data-test="listing-view">
      <Box ref={listingsGridRef} position="relative">
        {!loading && haveFetched && listings.length === 0 && (
          <Box
            gridColumn="span 4/span 4"
            textAlign="center"
            p={8}
            mt={4}
            border="1px solid"
            borderRadius={theme.border_radius.border_radius_2}
            borderColor="gray.300"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="lg" color="gray">
              No listings yet
            </Text>
            {category?.functional?.show_create_listing_button && (
              <Button
                mt={8}
                colorScheme="primary"
                onClick={() => router.push(`/listing/create?category=${category?.id}`)}
              >
                <Icon as={FiPlusCircle} mr="2" />
                {category?.functional?.text_overrides?.add_listing_btn || 'Add Listing'}
              </Button>
            )}
          </Box>
        )}
      </Box>
      {displayedListings.length > 0 &&
        displayedListings.map((listing) => (
          <ListingRow key={listing.id} listing={listing} category={category} loading={loading} />
        ))}
      {(loading || rerouting) && (
        <Box
          pt={8}
          pb={8}
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor={'rgba(255,255,255,0.7)'}
        >
          <Spinner />
        </Box>
      )}
      <Pagination
        nextLink={nextLink}
        prevLink={prevLink}
        onNext={handleNextPage}
        onPrev={handlePrevPage}
        pageNumber={page.toString()}
        totalPages={totalPages}
      />
    </Flex>
  );
};

export default ListingsListView;
