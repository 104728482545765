import {
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Popover,
  Stack,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FilterPopoverButton, FilterPopoverContent } from 'src/components/atoms';
// TODO(Sean): Handle currency formatting based on attribute
// import { formatPrice } from 'src/utils/common';
import { ListingFilterItemProps } from '.';
import { useAttributeSelector } from 'src/contexts/AttributeSelector';
import { isEqual } from 'lodash';

const NumericFilter = ({
  attributeId,
  isMobileView,
  decimalPlaces,
}: ListingFilterItemProps): JSX.Element => {
  const {
    attributes,
    selectedAttributes,
    setSelectedAttributes,
    stagedAttributes,
    setStagedAttributes,
  } = useAttributeSelector();
  const attribute = attributes.find((a) => a.name === attributeId);
  const [tempValue, setTempValue] = useState<[number | '', number | '']>(['', '']);
  const [defaultValue, setDefaultValue] = useState<[number | '', number | '']>(['', '']);
  const [errors, setErrors] = useState<{ min: string; max: string }>({ min: '', max: '' });

  useEffect(() => {
    if (
      attribute?.options &&
      'min_value' in attribute.options &&
      'max_value' in attribute.options
    ) {
      const _defaultValue: [number | '', number | ''] = [
        Number(attribute.options.min_value),
        Number(attribute.options.max_value),
      ];
      setDefaultValue(_defaultValue);
      // Initialize tempValue with the default values if no selection exists
      if (!(attributeId in selectedAttributes)) {
        setTempValue(_defaultValue);
      }
    }
  }, [attribute]);

  useEffect(() => {
    if (attributeId in selectedAttributes) {
      const selectedMin = selectedAttributes[attributeId][0];
      const selectedMax = selectedAttributes[attributeId][1];
      setTempValue([
        selectedMin === '' ? '' : Number(selectedMin),
        selectedMax === '' ? '' : Number(selectedMax),
      ]);
    }
  }, [selectedAttributes, attributeId]);

  const validateInput = (value: number | '', isMax = false, otherValue: number | ''): string => {
    if (value === '') return '';
    const num = Number(value);
    if (isNaN(num)) return 'Please enter a valid number';
    if (num < 0) return 'Value must be positive';
    if (isMax && otherValue !== '' && num < Number(otherValue)) {
      return 'Max value must be greater than min';
    }
    if (!isMax && otherValue !== '' && num > Number(otherValue)) {
      return 'Min value must be less than max';
    }
    return '';
  };

  const handleMobileChange = (value: [any, any]) => {
    const sanitizedValue: [number | '', number | ''] = [
      value[0] === '' || value[0] === null || isNaN(value[0]) ? '' : Number(value[0]),
      value[1] === '' || value[1] === null || isNaN(value[1]) ? '' : Number(value[1]),
    ];

    setTempValue(sanitizedValue);
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: [sanitizedValue[0].toString(), sanitizedValue[1].toString()],
    });
  };

  const handleChange = (min: number | '', max: number | '') => {
    const sanitizedMin = min === undefined || isNaN(min as number) ? '' : min;
    const sanitizedMax = max === undefined || isNaN(max as number) ? '' : max;

    setTempValue([sanitizedMin, sanitizedMax]);
    setStagedAttributes({
      ...stagedAttributes,
      [attributeId]: [sanitizedMin.toString(), sanitizedMax.toString()],
    });
  };

  return isMobileView ? (
    <Box px="2" pt="2">
      <Stack direction={'column'}>
        <FormControl>
          <FormLabel>Min</FormLabel>
          <NumberInput
            onChange={(valueString, value) =>
              handleMobileChange([valueString === '' ? '' : value, tempValue[1]])
            }
            value={tempValue[0]}
            precision={decimalPlaces}
            keepWithinRange={false}
            allowMouseWheel={false}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <FormControl>
          <FormLabel>Max</FormLabel>
          <NumberInput
            onChange={(valueString, value) =>
              handleMobileChange([tempValue[0], valueString === '' ? '' : value])
            }
            value={tempValue[1]}
            precision={decimalPlaces}
            keepWithinRange={false}
            allowMouseWheel={false}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </Stack>
    </Box>
  ) : (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={attribute?.name} />
      <FilterPopoverContent
        isCancelDisabled={isEqual(defaultValue, tempValue)}
        onClickApply={() => {
          setSelectedAttributes({
            ...selectedAttributes,
            [attributeId]: [tempValue[0].toString(), tempValue[1].toString()],
          });
        }}
        onClickCancel={() => {
          setTempValue(['', '']);
          const newSelectedAttributes = { ...selectedAttributes };
          delete newSelectedAttributes[attributeId];
          setSelectedAttributes(newSelectedAttributes);
        }}
      >
        <Box px="2" pt="2">
          <Stack direction={'column'} gap={4}>
            <FormControl>
              <FormLabel>Min</FormLabel>
              <NumberInput
                onChange={(valueString, value) =>
                  handleChange(valueString === '' ? '' : value, tempValue[1])
                }
                value={tempValue[0]}
                precision={decimalPlaces}
                keepWithinRange={false}
                allowMouseWheel={false}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Max</FormLabel>
              <NumberInput
                onChange={(valueString, value) =>
                  handleChange(tempValue[0], valueString === '' ? '' : value)
                }
                value={tempValue[1]}
                precision={decimalPlaces}
                keepWithinRange={false}
                allowMouseWheel={false}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </Stack>
        </Box>
      </FilterPopoverContent>
    </Popover>
  );
};

export default NumericFilter;
