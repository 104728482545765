import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { GlobalRoles, LoginResponse, MarketplaceAccount } from 'src/api/v1-api';
import { makeHeaders, getCookie } from './fetchUtils';
import { setCookie } from './cookiesUtils';
import { usePostHog } from 'posthog-js/react';
import * as Sentry from '@sentry/nextjs';

const API_BASE = process.env.NEXT_PUBLIC_API_HOST + '/auth';

interface User {
  id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_staff: boolean;
  date_joined: Date;
  image: string;
  last_login: Date;
  global_roles: Record<string, string[]>;
  roles: Record<string, string[]>;
  marketplace_accounts: MarketplaceAccount[];
}
interface TokenResponse {
  access: string;
  access_expires: number;
  refresh: string;
  refresh_expires: number;
}

const makeUrl = (endpoint: string): string => {
  return API_BASE + endpoint;
};

const fetchLogin = (
  username: string,
  password: string,
  marketplaceSlug: string,
): Promise<Response> => {
  const url = makeUrl('/token/');
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ username, password, marketplace_slug: marketplaceSlug }),
    headers: makeHeaders(),
    credentials: 'include',
  });
};

const fetchToken = (username: string, password: string): Promise<Response> => {
  const url = makeUrl('/token/');
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: makeHeaders(),
    credentials: 'include',
  });
};

const fetchNewToken = (): Promise<Response> => {
  const url = makeUrl('/token/refresh/');
  return fetch(url, {
    method: 'POST',
    headers: makeHeaders(),
    credentials: 'include',
  });
};

const bodyRefreshToken = async (): Promise<Response> => {
  const url = makeUrl('/token/body-refresh/');
  const refresh_token = getCookie('refresh_token');

  if (!refresh_token) {
    return new Response('No refresh token found', { status: 400 });
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ refresh_token }),
      headers: makeHeaders(),
      credentials: 'include',
      signal: AbortSignal.timeout(15000),
    });

    return response;
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        action: 'bodyRefreshToken',
        tokenExists: 'true',
        errorType: error instanceof Error ? error.name : 'Unknown',
      },
      extra: {
        url,
        timestamp: new Date().toISOString(),
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
      },
    });

    return new Response(
      JSON.stringify({
        error: 'Failed to refresh token',
        details: error instanceof Error ? error.message : 'Unknown error',
      }),
      {
        status: 503,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
};

async function fetchUser(token: string): Promise<Response> {
  const url = makeUrl('/me/');
  return fetch(url, {
    method: 'GET',
    headers: makeHeaders(token, true),
  });
}

async function fetchSocialLogin(
  email: string,
  first_name: string,
  last_name: string,
  hostname: string,
  username: string,
): Promise<Response> {
  const url = makeUrl('/token/social/');
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ email, first_name, last_name, hostname, username }),
    headers: makeHeaders(),
    credentials: 'include',
  });
}

type AuthContextProps = {
  isAuthenticated: boolean;
  authLoading: boolean;
  socialAuthLoading: boolean;
  userLoaded: boolean;
  user: User | null;
  login: (username: string, password: string, marketplaceSlug: string) => Promise<LoginResponse>;
  logout: () => Promise<boolean>;
  getToken: () => Promise<string>;
  signup: (
    email: string,
    password: string,
    firstName: string | undefined,
    lastName: string | undefined,
    marketplaceSlug: string,
  ) => Promise<LoginResponse>;
  hasRole: (marketplaceId: string | number | null | undefined, role: string) => boolean;
  hasRoles: (marketplaceId: string | number | null | undefined, roles: string[]) => boolean;
  hasAdmin: (marketplaceId: string | number | null | undefined) => boolean;
  hasStaff: () => boolean;
  refreshToken: () => Promise<string>;
  updateUser: () => Promise<User | undefined>;
  getLomaSessionId: () => string | undefined;
};

const initialAuthContextProps: AuthContextProps = {
  isAuthenticated: false,
  authLoading: false,
  socialAuthLoading: false,
  userLoaded: false,
  user: null,
  login: () =>
    Promise.resolve({ access: '', refresh: '', access_expires: 0, refresh_expires: 0, detail: '' }),
  signup: () =>
    Promise.resolve({ access: '', refresh: '', access_expires: 0, refresh_expires: 0, detail: '' }),
  logout: () => Promise.resolve(false),
  getToken: () => Promise.resolve(''),
  hasRole: () => false,
  hasRoles: () => false,
  hasAdmin: () => false,
  hasStaff: () => false,
  refreshToken: () => Promise.resolve(''),
  updateUser: () => Promise.resolve(undefined),
  getLomaSessionId: () => undefined,
};

const AuthContext = React.createContext<AuthContextProps>(initialAuthContextProps);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps): React.ReactElement | null => {
  const router = useRouter();
  const [authLoading, setAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [socialAuthLoading, setSocialAuthLoading] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string>('');
  const [accessTokenExpiry, setAccessTokenExpiry] = useState<number>(0);
  const { data: session } = useSession();
  const { access_token } = router.query;
  const posthog = usePostHog();

  const setNotAuthenticated = useCallback((): void => {
    setCookie('refresh_token', '', {
      expires: 'Thu, 01 Jan 1970 00:00:00 UTC',
    });
    setAccessToken('');
    setAccessTokenExpiry(0);
    setIsAuthenticated(false);
    setAuthLoading(false);
    setUser(null);
    setUserLoaded(true);
  }, []);

  const loginWithAccessToken = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_HOST}/auth/access-token/?token=${access_token}`,
      );
      if (response.status !== 200) {
        return;
      }
      const tokenData: TokenResponse = await response.json();
      handleNewToken(tokenData);
      setCookie('refresh_token', tokenData.refresh, {
        expires: new Date(tokenData.refresh_expires * 1000).toISOString(),
      });
      await initUser(tokenData.access);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (session && !isAuthenticated) {
      const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
      socialLogin(
        session?.user?.email ?? '',
        session?.user?.name ?? '',
        '',
        hostname,
        session?.user?.email ?? '',
      );
    }
  }, [session]);

  useEffect(() => {
    if (access_token) {
      loginWithAccessToken();
    }
  }, [access_token]);

  const initUser = useCallback(async (token: string): Promise<void> => {
    const resp = await fetchUser(token);
    const user = await resp.json();
    setUser(user);
    setUserLoaded(true);
  }, []);

  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
        email: user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const accessTokenIsValid = useCallback((): boolean => {
    if (accessToken === '') {
      return false;
    }
    const expiry = new Date(accessTokenExpiry);
    return expiry.getTime() > Date.now();
  }, [accessToken, accessTokenExpiry]);

  const handleNewToken = useCallback((data: TokenResponse): void => {
    setAccessToken(data.access);
    const expiryInt = data.access_expires * 1000;
    setAccessTokenExpiry(expiryInt);
    setIsAuthenticated(true);
    setAuthLoading(false);
  }, []);

  const refreshToken = useCallback(async (): Promise<string> => {
    const refresh_token = getCookie('refresh_token');
    if (!refresh_token) {
      setNotAuthenticated();
      return '';
    }
    setAuthLoading(true);
    const resp = await bodyRefreshToken();
    if (!resp.ok) {
      console.error('refresh token fail, logging out');
      setNotAuthenticated();
      return '';
    }
    const tokenData = await resp.json();
    handleNewToken(tokenData);
    setCookie('refresh_token', tokenData.refresh, {
      expires: new Date(tokenData.refresh_expires * 1000).toISOString(),
    });
    if (user === null) {
      await initUser(tokenData.access);
    }
    return tokenData.access;
  }, [setAuthLoading, setNotAuthenticated, handleNewToken, user, initUser]);

  const initAuth = useCallback(async (): Promise<void> => {
    if (!accessTokenIsValid()) {
      await refreshToken();
    } else {
      setIsAuthenticated(true);
    }
  }, [accessTokenIsValid, refreshToken]);

  useEffect(() => {
    initAuth().catch((error) => console.error(error));
  }, [initAuth]);

  //Social Login
  const socialLogin = async (
    emailAdd: string,
    firstName: string,
    lastName: string,
    hostname: string,
    userName: string,
  ): Promise<any> => {
    setSocialAuthLoading(true);
    const resp = await fetchSocialLogin(emailAdd, firstName, lastName, hostname, userName);
    if (resp.ok) {
      const tokenData = await resp.json();
      handleNewToken(tokenData);
      setCookie('refresh_token', tokenData.refresh, {
        expires: new Date(tokenData.refresh_expires * 1000).toISOString(),
      });
      await initUser(tokenData.access);
      setAuthLoading(false);
      return tokenData;
    } else {
      setIsAuthenticated(false);
      setAuthLoading(false);
    }
    return resp.json();
  };

  const login = async (
    username: string,
    password: string,
    marketplaceSlug: string,
  ): Promise<LoginResponse> => {
    setAuthLoading(true);
    username = username.toLowerCase();
    const resp = await fetchLogin(username, password, marketplaceSlug);
    if (resp.ok) {
      const tokenData = await resp.json();
      handleNewToken(tokenData);
      setCookie('refresh_token', tokenData.refresh, {
        expires: new Date(tokenData.refresh_expires * 1000).toISOString(),
      });
      await initUser(tokenData.access);
      setAuthLoading(false);
      return tokenData;
    } else {
      setIsAuthenticated(false);
      setAuthLoading(false);
    }
    return resp.json();
  };

  const getToken = async (): Promise<string> => {
    // Returns an access token if there's one or refetches a new one
    if (accessTokenIsValid()) {
      return Promise.resolve(accessToken);
    } else {
      const token = await refreshToken();
      return token;
    }
  };

  const logout = async (): Promise<boolean> => {
    setAuthLoading(true);
    const token = await getToken();
    const url = makeUrl('/token/logout/');
    const response = await fetch(url, {
      method: 'POST',
      headers: makeHeaders(token, true),
      credentials: 'include',
    });

    if (!response.ok) {
      console.error('Logout failed');
      setAuthLoading(false);
      return false;
    }
    await signOut();
    setNotAuthenticated();
    return true;
  };

  const signup = async (
    email: string,
    password: string,
    firstName: string | undefined,
    lastName: string | undefined,
    marketplaceSlug: string,
  ): Promise<LoginResponse> => {
    setAuthLoading(true);
    email = email.toLowerCase();
    const url = makeUrl('/register/');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        username: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        marketplace_slug: marketplaceSlug,
      }),
      headers: makeHeaders(),
      credentials: 'include',
    });

    const data = await resp.json();

    if (resp.ok) {
      const response = await login(email, password, marketplaceSlug);
      setAuthLoading(false);
      response.has_user_roles = data['has_user_roles'];
      return response;
    } else {
      setIsAuthenticated(false);
      setAuthLoading(false);
      return data;
    }
  };

  const hasRole = (marketplaceId: string | number | undefined | null, role: string): boolean => {
    if (!user || !marketplaceId || !isAuthenticated) return false;

    if (!user.roles[marketplaceId]) return false;

    if (user.roles[marketplaceId].includes(role)) {
      return true;
    }

    const user_global_roles = user.global_roles?.[marketplaceId] ?? [];
    if (user.global_roles[GlobalRoles.Admin]) {
      user_global_roles.push(GlobalRoles.Admin);
    }

    if (user.global_roles[GlobalRoles.Configuration]) {
      user_global_roles.push(GlobalRoles.Configuration);
    }

    if (!user_global_roles) return false;
    return user_global_roles.includes(role);
  };

  const hasRoles = (
    marketplaceId: string | number | undefined | null,
    roles: string[],
  ): boolean => {
    if (!user || !marketplaceId) return false;
    if (roles === undefined) return false;
    if (roles.length === 0) return true;
    if (!user.roles || typeof user.roles !== 'object') return false;
    const userRolesForMarketplace = user.roles[marketplaceId];
    if (userRolesForMarketplace && roles.some((role) => userRolesForMarketplace.includes(role))) {
      return true;
    } else {
      return false;
    }
  };

  const hasStaff = (): boolean => {
    if (!user) return false;
    return user.is_staff;
  };

  const hasAdmin = (marketplaceId: string | number | undefined | null): boolean => {
    return hasRole(marketplaceId?.toString() ?? '', 'Admin');
  };

  const updateUser = useCallback(async () => {
    try {
      const token = await getToken();
      if (!token) {
        console.error('No token available to fetch user data');
        return;
      }

      const response = await fetchUser(token);
      if (!response.ok) {
        console.error('Failed to fetch updated user data');
        return;
      }

      const userData = await response.json();
      setUser(userData);

      posthog?.identify(userData.id, {
        email: userData.email,
      });

      return userData;
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error;
    }
  }, [getToken]);

  const getLomaSessionId = (): string | undefined => {
    // Only used for cart management of non-logged in users
    if (user) return undefined;

    // Only proceed with localStorage if in browser environment
    if (typeof window === 'undefined') return undefined;

    try {
      // Try to get existing session id
      const sessionId = localStorage.getItem('lomasessionid');
      if (sessionId) return sessionId;

      // If no session id, create a new one
      const newSessionId = uuidv4();
      try {
        localStorage.setItem('lomasessionid', newSessionId);
      } catch (error) {
        console.warn('Unable to save session ID to localStorage:', error);
      }
      return newSessionId;
    } catch (error) {
      console.warn('Unable to access localStorage for session ID:', error);
      // Return a new session ID even if we can't store it
      return uuidv4();
    }
  };

  const value = {
    isAuthenticated,
    user,
    authLoading,
    userLoaded,
    login,
    logout,
    getToken,
    signup,
    hasRole,
    hasRoles,
    hasAdmin,
    hasStaff,
    socialLogin,
    socialAuthLoading,
    refreshToken,
    updateUser,
    getLomaSessionId,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
