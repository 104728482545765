import { omit } from 'lodash';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Attribute, AttributeOptions, ViewTypes } from 'src/api/v1-api';
import { SortOption } from 'src/constants/sort-options';
import { useGlobalSearch } from './globalSearch';

interface AttributeSelectorContextProps {
  attributes: Attribute[];
  stagedAttributes: AttributeOptions;
  setStagedAttributes: (attributeOptions: AttributeOptions) => void;
  stagedPriceRange: [number, number];
  setStagedPriceRange: (range: [number, number]) => void;
  maxPrice: number;
  commitStagedAttributes: () => void;
  selectedAttributes: AttributeOptions;
  setSelectedAttributes: (attributeOptions: AttributeOptions) => void;
  useToggle: boolean;
  viewType: string;
  setViewType: (type: string) => void;
  priceRange: [number, number];
  setPriceRange: (range: [number, number]) => void;
  sortOptions: SortOption[] | null;
  selectedSort: SortOption | null;
  setSelectedSort: (sort: SortOption | null) => void;
  page: number;
  setPage: (page: number) => void;
}

const AttributeSelectorContext = createContext<AttributeSelectorContextProps>({
  attributes: [],
  stagedAttributes: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStagedAttributes: () => {},
  stagedPriceRange: [0, 0],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStagedPriceRange: () => {},
  maxPrice: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  commitStagedAttributes: () => {},
  selectedAttributes: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedAttributes: () => {},
  useToggle: false,
  viewType: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setViewType: () => {},
  priceRange: [0, 0],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPriceRange: () => {},
  sortOptions: [],
  selectedSort: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedSort: () => {},
  page: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPage: () => {},
});

export const AttributeSelectorProvider = ({
  children,
  attributes,
  useToggle,
  defaultViewType = 'grid',
  maxPrice,
  sortOptions,
  defaultSortOption = null,
}: {
  children: React.ReactNode;
  attributes: Attribute[];
  useToggle: boolean;
  defaultViewType: string;
  maxPrice: number | undefined;
  sortOptions: SortOption[] | null;
  defaultSortOption?: SortOption | null;
}): JSX.Element => {
  const router = useRouter();
  const { searchQuery, setSearchQuery } = useGlobalSearch();
  const [stagedAttributes, setStagedAttributes] = useState<AttributeOptions>({});
  const [stagedPriceRange, setStagedPriceRange] = useState<[number, number]>([0, 0]);
  const [selectedAttributes, setSelectedAttributes] = useState<AttributeOptions>({});
  const [viewType, setViewType] = useState<string>(defaultViewType);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);
  const [selectedSort, setSelectedSort] = useState<SortOption | null>(defaultSortOption);
  const [page, setPage] = useState(0);

  const BLOCK_LIST_ATTRIBUTES = ['id', 'site'];

  useEffect(() => {
    if (maxPrice !== undefined) {
      setPriceRange([0, maxPrice]);
    }
  }, [maxPrice]);

  const commitStagedAttributes = () => {
    setSelectedAttributes(stagedAttributes);
    if (
      stagedPriceRange[0] !== 0 ||
      (stagedPriceRange[1] !== 0 && stagedPriceRange[1] != maxPrice)
    ) {
      setPriceRange(stagedPriceRange);
    }
  };

  const handleSetSelectedAttributes = async (newAttributes: AttributeOptions) => {
    const otherAttributesChanged = Object.entries(newAttributes).some(
      ([key, value]) => !BLOCK_LIST_ATTRIBUTES.includes(key) && selectedAttributes[key] !== value,
    );

    if (otherAttributesChanged) {
      const updatedAttributes = { ...newAttributes };
      setSelectedAttributes(omit(updatedAttributes, BLOCK_LIST_ATTRIBUTES));
    } else {
      setSelectedAttributes(newAttributes);
    }
  };

  const parseFilters = () => {
    const url = new URL(window.location.href);
    const filters: Record<string, string> = {};

    // Add selected attributes as filters
    Object.entries(selectedAttributes)
      .filter(([key]) => !BLOCK_LIST_ATTRIBUTES.includes(key))
      .forEach(([key, value]) => {
        filters[key] = Object.values(value).toString();
      });

    // Add price range if set
    if (priceRange[0] !== 0 || (priceRange[1] !== 0 && priceRange[1] != maxPrice)) {
      filters['price'] = `${priceRange[0]}-${priceRange[1]}`;
    }

    // Add sort option if selected
    if (selectedSort) {
      filters['sort'] = selectedSort;
    }

    const searchParams = new URLSearchParams();
    if (Object.keys(filters).length > 0) {
      searchParams.append('filters', JSON.stringify(filters));
    }

    // Preserve non-filter params like `viewType`
    if (viewType && viewType !== '' && viewType !== 'grid') {
      searchParams.append('viewType', viewType);
    }

    if (page > 1) {
      searchParams.append('page', page.toString());
    }

    if (searchQuery) {
      searchParams.append('search', searchQuery);
    }

    const newUrl = searchParams.toString()
      ? `${url.origin}${url.pathname}?${searchParams.toString()}`
      : `${url.origin}${url.pathname}`;
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
  };

  const handleSetStagedPriceRange = async (range: [number, number]) => {
    setStagedPriceRange(range);
  };

  // Parse URL parameters on the initial load
  useEffect(() => {
    if (Object.keys(router.query).length > 0) {
      parseUrlParameters();
    }
  }, [router.query]);

  useEffect(() => {
    parseFilters();
  }, [selectedAttributes, priceRange, viewType, selectedSort, page, searchQuery]);

  const parseUrlParameters = () => {
    const params = router.query;
    const attributes: AttributeOptions = {};
    let priceRange: string[] = ['0', maxPrice ? maxPrice.toString() : '0'];
    const priceRangeMax = '5000';
    let sortOption: SortOption | null = defaultSortOption;

    if (params.filters) {
      const filters = JSON.parse(params.filters as string);

      Object.entries(filters).forEach(([key, value]) => {
        if (key === 'price') {
          priceRange = (value as string).split('-');
          if (priceRange.length === 1) priceRange.push(priceRangeMax);
          if (priceRange[1] < priceRange[0]) priceRange[1] = priceRangeMax;
        } else if (key === 'sort') {
          sortOption = value as SortOption;
        } else {
          attributes[key] = (value as string).split(',');
        }
      });
    }

    setPriceRange([Number(priceRange[0]), Number(priceRange[1])]);
    setSelectedAttributes(omit(attributes, BLOCK_LIST_ATTRIBUTES));
    setSelectedSort(sortOption);

    // Handle other params (viewType, page)
    if (params.viewType && Object.values(ViewTypes).includes(params.viewType as ViewTypes)) {
      setViewType(params.viewType as string);
    }
    if (params.page) {
      setPage(Number(params.page));
    }
    if (params.search) {
      setSearchQuery(params.search as string);
    }
  };

  return (
    <AttributeSelectorContext.Provider
      value={{
        attributes,
        stagedAttributes,
        setStagedAttributes,
        stagedPriceRange,
        setStagedPriceRange: handleSetStagedPriceRange,
        maxPrice: maxPrice || 0,
        commitStagedAttributes,
        selectedAttributes,
        setSelectedAttributes: handleSetSelectedAttributes,
        useToggle,
        viewType,
        setViewType,
        priceRange,
        setPriceRange,
        sortOptions,
        selectedSort,
        setSelectedSort,
        page,
        setPage,
      }}
    >
      {children}
    </AttributeSelectorContext.Provider>
  );
};

export const useAttributeSelector = (): AttributeSelectorContextProps => {
  const context = useContext(AttributeSelectorContext);
  if (context === undefined) {
    throw new Error('useAttributeSelector must be used within a AttributeSelectorProvider');
  }
  return context;
};
