import {
  Box,
  Button,
  Input,
  Switch,
  Stack,
  Text,
  Link,
  FormControl,
  FormLabel,
  Textarea,
  Spinner,
  Flex,
  Avatar,
  useToast,
  Divider,
  IconButton,
  CheckboxGroup,
  Checkbox,
  Select,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { useContext, useState, useEffect } from 'react';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import { apiRequest } from 'src/utils/fetchUtils';
import ApexDomain from './apexDomain';
import { MdDelete } from 'react-icons/md';
import ColorPicker from 'src/components/atoms/color-picker';
import StripeConnectForm from '../stripe-connect-form';
import useRequest from 'src/utils/hooks/useRequest';
import { ThemeOptions, ThemeVariables } from 'src/api/v1-api';
import { parseUnderscoreName } from 'src/utils/common';
import InfoTooltip from 'src/components/atoms/info-tooltip';
import { useRouter } from 'next/router';
import { downloadJsonAsCsv } from 'src/utils/files';
import { MdDownload } from 'react-icons/md';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';

const DEFAULT_MAIN_COLOR = '#ffffff';
const DEFAULT_NAV_COLOR = '#ffffff';
const DEFAULT_PRIMARY_COLOR = '#3182CE';
const DEFAULT_SECONDARY_COLOR = '#319795';

const PAGES_OPTIONS = [
  { name: 'Home', value: 'home' },
  { name: 'Category', value: 'category' },
  { name: 'Listing', value: 'listing' },
];
const COLLECT_PAGES_OPTIONS = [
  { name: 'Home', value: 'home' },
  { name: 'Category', value: 'category' },
  { name: 'Listing', value: 'listing' },
  { name: 'Link In Bio', value: 'linkinbio' },
];

const MarketplaceForm = () => {
  const router = useRouter();
  const toast = useToast();
  const toastNotification = useSingleToast();
  const { api } = useRequest();
  const { marketplace, setMarketplace, logoIsCircle, setLogoIsCircle } =
    useContext(MarketplaceContext);
  const { getToken, hasStaff } = useAuth();
  const isStaff = hasStaff();

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [shortenedName, setShortenedName] = useState('');
  const [description, setDescription] = useState('');
  const [logoPhoto, setLogoPhoto] = useState('');
  const [logoPhotoFile, setLogoPhotoFile] = useState<File | null>(null);
  const [announcement, setAnnouncement] = useState('');
  const [apexDomain, setApexDomain] = useState('');
  const [domainStatus, setDomainStatus] = useState('');
  const [emailStatus, setEmailStatus] = useState('');
  const [sourceToListedVerb, setSourceToListedVerb] = useState({} as Record<string, string>);
  const [source, setSource] = useState('');
  const [listedVerb, setListedVerb] = useState('');
  const [displayInactiveListingInfo, setDisplayInactiveListingInfo] = useState(true);
  const [showTotalSocialmediaAudience, setShowTotalSocialmediaAudience] = useState(true);
  const [showFirstPartyListingsFirst, setShowFirstPartyListingsFirst] = useState(true);
  const [enableBuyerDigestEmails, setEnableBuyerDigestEmails] = useState(true);
  const [themeOptions, setThemeOptions] = useState<ThemeOptions>({} as ThemeOptions);
  const [marketplaceThemeOptions, setMarketplaceThemeOptions] = useState<ThemeVariables>(
    {} as ThemeVariables,
  );
  const [textOverrides, setTextOverrides] = useState({} as Record<string, string>);
  const [defaultOverrides, setDefaultOverrides] = useState({} as Record<string, string>);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingAnalytics, setIsFetchingAnalytics] = useState(false);

  const shortenedDefault = slug.toUpperCase().substring(0, 16);
  const shortenedNameTooltip = `By default, sales through Loma will appear on credit card statements from "LOMA*" with the marketplace subdomain as a suffix. You may customize the name after "LOMA*" for clarity. There is a limit of 16 characters for the shortened name. Marketplaces that use Stripe Connect will show their full statement descriptor without "LOMA*".`;

  const [marketplaceColors, setMarketplaceColors] = useState({
    main:
      marketplace?.theme?.background_color && marketplace?.theme?.background_color !== ''
        ? marketplace?.theme?.background_color
        : DEFAULT_MAIN_COLOR,
    nav:
      marketplace?.theme?.nav_background_color && marketplace?.theme?.nav_background_color !== ''
        ? marketplace?.theme?.nav_background_color
        : DEFAULT_NAV_COLOR,
    primary:
      marketplace?.theme?.primary_color && marketplace?.theme?.primary_color !== ''
        ? marketplace?.theme?.primary_color
        : DEFAULT_PRIMARY_COLOR,
    secondary:
      marketplace?.theme?.secondary_color && marketplace?.theme?.secondary_color !== ''
        ? marketplace?.theme?.secondary_color
        : DEFAULT_SECONDARY_COLOR,
  });

  const [marketplaceBanner, setMarketplaceBanner] = useState({
    title: marketplace?.functional?.add_listing_banner?.title
      ? marketplace?.functional?.add_listing_banner?.title
      : '',
    description: marketplace?.functional?.add_listing_banner?.description
      ? marketplace?.functional?.add_listing_banner?.description
      : '',
    cta_text: marketplace?.functional?.add_listing_banner?.cta_text
      ? marketplace?.functional?.add_listing_banner?.cta_text
      : '',
    cta_link: marketplace?.functional?.add_listing_banner?.cta_link
      ? marketplace?.functional?.add_listing_banner?.cta_link
      : '',
    pages: marketplace?.functional?.add_listing_banner?.pages
      ? marketplace?.functional?.add_listing_banner?.pages
      : [],
  });
  const [collectEmailModule, setCollectEmailModule] = useState({
    title: marketplace?.functional?.collect_email_module?.title
      ? marketplace?.functional?.collect_email_module?.title
      : 'Get the latest updates',
    description: marketplace?.functional?.collect_email_module?.description
      ? marketplace?.functional?.collect_email_module?.description
      : 'Sign up to receive a weekly email',
    cta_text: marketplace?.functional?.collect_email_module?.cta_text
      ? marketplace?.functional?.collect_email_module?.cta_text
      : 'Signup',
    pages: marketplace?.functional?.collect_email_module?.pages
      ? marketplace?.functional?.collect_email_module?.pages
      : [],
  });
  const [googleSearchConsoleVerifivation, setGoogleSearchConsoleVerification] =
    useState<string>('');

  useEffect(() => {
    const overdefs = {} as Record<string, string>;
    // global defaults
    for (const textover of marketplace?.text_overrides ?? []) {
      overdefs[textover.name] = textover.default_value;
    }
    setDefaultOverrides(overdefs);
  }, [marketplace?.text_overrides]);

  const addListedVerb = () => {
    if (!source || !listedVerb) {
      return;
    }
    setSourceToListedVerb((prev) => ({ ...prev, [source]: listedVerb }));
    setSource('');
    setListedVerb('');
  };

  const setMarketplaceInfo = async () => {
    if (marketplace) {
      setName(marketplace.name);
      setSlug(marketplace.slug);
      setShortenedName(marketplace.shortened_name);
      setDescription(marketplace.description);
      setLogoPhoto(marketplace.logo_image);
      setAnnouncement(marketplace.functional?.announcement);
      setIsLoading(false);
      setSourceToListedVerb(marketplace.functional?.source_to_listed_verb || {});
      setDisplayInactiveListingInfo(marketplace.functional?.show_inactive_listings_info);
      setShowTotalSocialmediaAudience(
        marketplace.functional?.show_total_social_media_audience ?? false,
      );
      setShowFirstPartyListingsFirst(
        marketplace.functional?.show_first_party_listings_first ?? false,
      );
      setEnableBuyerDigestEmails(marketplace.functional?.enable_buyer_digest_emails ?? false);
      setMarketplaceThemeOptions(marketplace.theme);
      setTextOverrides(marketplace.functional?.text_overrides || {});
    }
  };

  const setDomainInfo = async () => {
    if (marketplace?.domain) {
      setApexDomain(marketplace.domain.domain);
      setDomainStatus(marketplace.domain.status);
      setEmailStatus(marketplace.domain.email_status);
    }
  };

  const setGoogleSearchConsoleInfo = async () => {
    if (marketplace?.functional) {
      setGoogleSearchConsoleVerification(
        marketplace.functional.google_search_console_verification ?? '',
      );
    }
  };

  const getMarketplaceOptions = async () => {
    if (!marketplace?.id) return;
    const response = await api.marketplace.theme.options.get(marketplace?.id);
    const filteredOptions = Object.fromEntries(
      Object.entries(response.options).filter(
        ([key, option]) =>
          ![
            'primary_color',
            'secondary_color',
            'nav_background_color',
            'background_color',
            'component_background_color',
          ].includes(key),
      ),
    );
    setThemeOptions(filteredOptions as ThemeOptions);
  };

  useEffect(() => {
    setMarketplaceInfo();
    setDomainInfo();
    getMarketplaceOptions();
    setGoogleSearchConsoleInfo();
  }, [marketplace]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/marketplaces/${marketplace?.id}/`;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (shortenedName !== marketplace?.shortened_name) {
      formData.append('shortened_name', shortenedName);
    }
    if (logoPhotoFile) {
      formData.append('image', logoPhotoFile);
    }

    let trimmedAnnouncement = '';
    if (announcement) {
      trimmedAnnouncement = announcement.trim();
    }
    formData.append('announcement', trimmedAnnouncement);
    formData.append('logo_is_circle', logoIsCircle ? 'true' : 'false');
    formData.append('collect_email_module', JSON.stringify(collectEmailModule));
    formData.append('show_inactive_listings_info', displayInactiveListingInfo ? 'true' : 'false');
    formData.append(
      'show_total_social_media_audience',
      showTotalSocialmediaAudience ? 'true' : 'false',
    );
    if (apexDomain && apexDomain !== marketplace?.domain?.domain) {
      formData.append('domain', apexDomain);
    }

    if (sourceToListedVerb) {
      formData.append('source_to_listed_verb', JSON.stringify(sourceToListedVerb));
    }
    formData.append('marketplace_colors', JSON.stringify(marketplaceColors));
    formData.append('marketplace_banner', JSON.stringify(marketplaceBanner));
    formData.append(
      'show_first_party_listings_first',
      showFirstPartyListingsFirst ? 'true' : 'false',
    );
    formData.append('enable_buyer_digest_emails', enableBuyerDigestEmails ? 'true' : 'false');
    formData.append('marketplace_theme_options', JSON.stringify(marketplaceThemeOptions));
    formData.append('default_overrides', JSON.stringify(defaultOverrides));
    formData.append('text_overrides', JSON.stringify(textOverrides));
    formData.append('google_search_console_verification', googleSearchConsoleVerifivation);

    try {
      const newMarketplaceData = await apiRequest('PUT', url, token, formData, true);
      // Remove the marketplace from local storage
      const hostname = window.location.hostname || marketplace?.domain?.domain;
      localStorage.removeItem(`marketplace-${hostname}`);
      setMarketplace(newMarketplaceData);
      // TODO: better would be to make a reset function, but this is better than not updating after setting
      router.reload();
    } catch (error) {
      toast({
        title: 'Error.',
        description: 'Unable to save marketplace information. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
    setIsSubmitting(false);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setLogoPhotoFile(file);
    if (file) {
      setLogoPhoto(URL.createObjectURL(file));
    }
  };

  const handleColorChange = (colorName: string, color: string) => {
    setMarketplaceColors({ ...marketplaceColors, [colorName]: color });
  };

  const handleBannerChange = (key: string, value: string) => {
    setMarketplaceBanner({ ...marketplaceBanner, [key]: value });
  };

  const handlePagesChanges = (value: (number | string)[]) => {
    setMarketplaceBanner({ ...marketplaceBanner, pages: value as string[] });
  };

  const handleMarketplaceThemeOptionsChange = (key: string, value: string) => {
    const newOptions = { ...marketplaceThemeOptions };
    (newOptions as any)[key] = value;
    setMarketplaceThemeOptions(newOptions);
  };

  const handleCollectEmailModuleChange = (key: string, value: string) => {
    setCollectEmailModule({ ...collectEmailModule, [key]: value });
  };

  const handleCollectPagesChanges = (value: (number | string)[]) => {
    setCollectEmailModule({ ...collectEmailModule, pages: value as string[] });
  };

  const handleDefaultOverrideChange = (overrideKey: string, value: string) => {
    setDefaultOverrides({
      ...defaultOverrides,
      [overrideKey]: value,
    });
  };

  const handleTextOverrideChange = (overrideKey: string, value: string) => {
    setTextOverrides({
      ...textOverrides,
      [overrideKey]: value,
    });
  };

  const fetchAnalytics = async () => {
    if (!marketplace) return;
    try {
      setIsLoading(true);
      return await api.marketplace.analytics(marketplace.id);
    } catch (error) {
      toastNotification({
        title: 'Error Fetching Analytics',
        description:
          'Something went wrong, please try again. Contact support if the issue persists.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        sentryAlert: {
          error,
          level: 'error',
          extras: {
            marketplaceId: marketplace.id,
          },
        },
      });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const donwloadListingViews = async () => {
    if (!marketplace) return;

    const analytics = await fetchAnalytics();
    if (!analytics) return;
    downloadJsonAsCsv(`marketplace-${marketplace.name}-listing-views.csv`)(analytics);
  };

  const sources = Object.keys(sourceToListedVerb);
  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: '100%' }}>
      <Stack justify="flex-start" align="flex-start" spacing="28px" maxWidth="100%">
        <Text fontSize="2xl" pb={2}>
          Marketplace Information
        </Text>

        <Box position="relative" maxWidth="100%">
          <Stack justify="flex-start" align="flex-start" spacing="20px">
            <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input placeholder="-" value={name} onChange={(e) => setName(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Subdomain</FormLabel>
                <Input
                  placeholder="-"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  disabled
                />
              </FormControl>
            </Stack>

            <Stack
              direction="row"
              justify="flex-start"
              align="flex-start"
              spacing="20px"
              width="100%"
            >
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  placeholder="Describe your marketplace."
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  width="100%"
                  data-test="description-input"
                />
              </FormControl>
            </Stack>

            <Stack
              w={'full'}
              direction="row"
              justify="flex-start"
              align="flex-start"
              spacing="20px"
            >
              <FormControl>
                <FormLabel>
                  Shortened Name for Credit Card Statements
                  <InfoTooltip description={shortenedNameTooltip} />
                </FormLabel>
                <Input
                  w={'80%'}
                  placeholder={shortenedDefault}
                  value={shortenedName}
                  onChange={(e) => setShortenedName(e.target.value.toUpperCase().substring(0, 16))}
                  max={16}
                />
                <Text ml={4} lineHeight="2.5em" color="gray.600">
                  Will appear as: LOMA* {shortenedName || shortenedDefault}
                  {marketplace?.functional?.stripe_connect_config?.status &&
                    marketplace.functional.stripe_connect_config.status === 'active' && (
                      <span> - only used for peer-to-peer transactions</span>
                    )}
                </Text>
              </FormControl>
            </Stack>

            <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
              <FormControl>
                <FormLabel>Logo Photo</FormLabel>
                <Flex align="center">
                  <Box ml={4}>
                    {logoPhoto && (
                      <Avatar
                        borderRadius={logoIsCircle ? 'full' : ''}
                        src={logoPhoto}
                        boxSize={10}
                        objectFit="cover"
                      />
                    )}
                  </Box>
                  <Box pl={4}>
                    <Input
                      type="file"
                      id="file-upload"
                      onChange={handleImageUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="file-upload">
                      <Button as="span" colorScheme="gray" size="sm">
                        Choose File
                      </Button>
                    </label>
                  </Box>
                </Flex>
              </FormControl>
              <FormControl>
                <FormLabel>Set rounded logo</FormLabel>
                <Switch
                  mt={'5px'}
                  size="lg"
                  id="email-alerts"
                  isChecked={logoIsCircle}
                  onChange={() => {
                    setLogoIsCircle((st) => !st);
                  }}
                />
              </FormControl>
            </Stack>

            <Divider />

            <Stack justify="flex-start" align="flex-start" spacing="20px" w="100%">
              <Text fontFamily="Inter" lineHeight="1.5" fontWeight="semibold" fontSize="16px">
                Google Search Console
              </Text>
              <FormControl>
                <FormLabel>Verification String</FormLabel>
                <Input
                  placeholder="Verification String"
                  value={googleSearchConsoleVerifivation}
                  onChange={(e) => setGoogleSearchConsoleVerification(e.target.value)}
                />
                <Text mt={1} ml={4} color="gray.600">
                  Use the verification string provided by Google Search Console to verify your
                  marketplace. Use the HTML tag method.
                </Text>
              </FormControl>
            </Stack>

            <ApexDomain
              apexDomain={apexDomain}
              setApexDomain={setApexDomain}
              domainStatus={domainStatus}
              emailStatus={emailStatus}
            />

            {marketplace && (
              <StripeConnectForm
                marketplaceId={marketplace.id}
                stripeConnectConfig={marketplace?.functional?.stripe_connect_config}
              />
            )}

            <Divider />

            {/* Functional Settings */}
            <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px" w="full">
              <FormControl>
                <FormLabel>Announcement</FormLabel>
                <Textarea
                  placeholder="Set an announcement for your marketplace."
                  value={announcement}
                  onChange={(event) => setAnnouncement(event.target.value)}
                  width="100%"
                />
              </FormControl>
            </Stack>
            <Divider />

            {/* Collect Email Module */}
            <Stack justify="flex-start" align="flex-start" spacing="20px" w="100%">
              <Text fontFamily="Inter" lineHeight="1.5" fontWeight="semibold" fontSize="16px">
                Collect User Email Form
              </Text>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  placeholder="Title"
                  value={collectEmailModule.title}
                  onChange={(e) => handleCollectEmailModuleChange('title', e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Signup Button Text</FormLabel>
                <Input
                  placeholder="Signup Button Text"
                  value={collectEmailModule.cta_text}
                  onChange={(e) => handleCollectEmailModuleChange('cta_text', e.target.value)}
                />
              </FormControl>
              <FormControl width={'unset'}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  placeholder="Description"
                  value={collectEmailModule.description}
                  onChange={(e) => handleCollectEmailModuleChange('description', e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Pages</FormLabel>
                <CheckboxGroup
                  onChange={handleCollectPagesChanges}
                  value={collectEmailModule.pages as unknown as number[] | string[]}
                >
                  <Stack direction="column">
                    {COLLECT_PAGES_OPTIONS.map((page) => (
                      <Checkbox colorScheme="primary" key={page.name} value={page.value}>
                        {page.name}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </FormControl>
            </Stack>
            <Divider />

            {/* Inactive Listing Display Config*/}
            <Stack>
              <FormControl>
                <FormLabel>Redirect to Home Screen on non-Active View Listing</FormLabel>
                <Switch
                  mt={'5px'}
                  size="lg"
                  id="display-listing-info"
                  isChecked={displayInactiveListingInfo}
                  onChange={() => {
                    setDisplayInactiveListingInfo((st) => !st);
                  }}
                />
              </FormControl>
            </Stack>

            {/* Show Social Medial Total Followers */}
            <Stack>
              <FormControl>
                <FormLabel>Show Social Medial Total Followers in Header</FormLabel>
                <Switch
                  mt={'5px'}
                  size="lg"
                  id="show-total-socialmedia-audience"
                  isChecked={showTotalSocialmediaAudience}
                  onChange={() => {
                    setShowTotalSocialmediaAudience((st) => !st);
                  }}
                />
              </FormControl>
            </Stack>

            <Divider />

            {/* Show First Party Listings First */}
            <Stack>
              <FormControl>
                <FormLabel>Show First Party Listings First</FormLabel>
                <Switch
                  mt={'5px'}
                  size="lg"
                  id="show-first-party-listings-first"
                  isChecked={showFirstPartyListingsFirst}
                  onChange={() => {
                    setShowFirstPartyListingsFirst((st) => !st);
                  }}
                />
              </FormControl>
            </Stack>

            <Divider />

            {/* Enable Buyer Digest Emails */}
            <Stack>
              <FormControl>
                <FormLabel>Enable Buyer Digest Emails?</FormLabel>
                <Switch
                  mt={'5px'}
                  size="lg"
                  id="enable-buyer-digest-emails"
                  isChecked={enableBuyerDigestEmails}
                  onChange={() => {
                    setEnableBuyerDigestEmails((st) => !st);
                  }}
                />
              </FormControl>
            </Stack>

            <Divider />
            {/* source to listed verb map */}

            <Stack justify="flex-start" align="flex-start" spacing="20px" w="100%">
              <Text lineHeight="1.5" fontWeight="semibold" fontSize="16px">
                Source to Listed Verb
              </Text>
              <Flex
                flexDirection="row"
                w="100%"
                borderBottom="1px solid"
                borderColor="gray.200"
                py={2}
                gap={4}
                alignItems="center"
                fontWeight="semibold"
              >
                <Flex minW={{ base: '35%', md: '260px' }} pl={4}>
                  Source
                </Flex>
                <Flex minW={{ base: '35%', md: '260px' }}>Listed Verb</Flex>
              </Flex>
              {sources.length > 0 ? (
                sources.map((source) => (
                  <Flex
                    flexDirection="row"
                    key={source}
                    w="100%"
                    borderBottom="1px solid"
                    borderColor="gray.200"
                    py={2}
                    gap={4}
                    alignItems="center"
                  >
                    <Flex minW={{ base: '35%', md: '260px' }} pl={4}>
                      {source}
                    </Flex>
                    <Flex minW={{ base: '35%', md: '260px' }}>
                      <Text flex={1} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                        {sourceToListedVerb[source]}
                      </Text>
                    </Flex>
                    <Flex flexGrow={1} justifyContent="flex-end">
                      <IconButton
                        colorScheme="red"
                        icon={<MdDelete />}
                        aria-label="Delete map"
                        onClick={() => {
                          const newMap = { ...sourceToListedVerb };
                          delete newMap[source];
                          setSourceToListedVerb(newMap);
                        }}
                      />
                    </Flex>
                  </Flex>
                ))
              ) : (
                <Text pl={4}>No source to listed verb map</Text>
              )}
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="flex-start"
                align="flex-start"
                gap="20px"
                w="100%"
              >
                <FormControl>
                  <Input
                    placeholder="Source"
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <Input
                    placeholder="Listed verb"
                    value={listedVerb}
                    onChange={(e) => setListedVerb(e.target.value)}
                  />
                </FormControl>
                <Box pt={{ base: 10, md: 0 }}>
                  <Flex align="center">
                    <Button colorScheme="gray" onClick={addListedVerb}>
                      Add
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Stack>
            <Divider />

            {/* Color Cutomization Section */}
            <Stack justify="flex-start" align="flex-start" spacing="20px" w="100%">
              <Text lineHeight="1.5" fontWeight="semibold" fontSize="16px">
                Marketplace Colors
              </Text>
              <Stack
                direction="row"
                justify="flex-start"
                align="flex-start"
                spacing="20px"
                w="100%"
              >
                <FormControl width={'200%'}>
                  <FormLabel>Main Color</FormLabel>
                  <ColorPicker
                    value={marketplaceColors.main}
                    onChange={(color) => handleColorChange('main', color)}
                  />
                </FormControl>
                <FormControl width={'200%'}>
                  <FormLabel>Nav Color</FormLabel>
                  <ColorPicker
                    value={marketplaceColors.nav}
                    onChange={(color) => handleColorChange('nav', color)}
                  />
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                justify="flex-start"
                align="flex-start"
                spacing="20px"
                w="100%"
              >
                <FormControl width={'200%'}>
                  <FormLabel>Primary Color</FormLabel>
                  <ColorPicker
                    value={marketplaceColors.primary}
                    onChange={(color) => handleColorChange('primary', color)}
                  />
                </FormControl>
                <FormControl width={'200%'}>
                  <FormLabel>Secondary Color</FormLabel>
                  <ColorPicker
                    value={marketplaceColors.secondary}
                    onChange={(color) => handleColorChange('secondary', color)}
                  />
                </FormControl>
              </Stack>
              <Text fontFamily="Inter" lineHeight="1.5" fontWeight="semibold" fontSize="16px">
                Marketplace Theme Options
              </Text>
              <SimpleGrid columns={2} rowGap={8} columnGap={6} width={'full'}>
                {themeOptions &&
                  Object.keys(themeOptions).map((key) => (
                    <GridItem colSpan={1} key={key}>
                      <FormControl>
                        <FormLabel>{parseUnderscoreName(key)}</FormLabel>
                        <Select
                          name={key}
                          placeholder={`Select ${key}`}
                          value={(marketplaceThemeOptions as any)[key]}
                          onChange={(e) => handleMarketplaceThemeOptionsChange(key, e.target.value)}
                        >
                          {(themeOptions as any)[key].enum?.map((option: string) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  ))}
              </SimpleGrid>
            </Stack>
            <Divider />

            {/* Marketplace Banner */}
            <Stack justify="flex-start" align="flex-start" spacing="20px" w="100%">
              <Text lineHeight="1.5" fontWeight="semibold" fontSize="16px">
                Marketplace Listing Banner
              </Text>
              <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    placeholder="Title"
                    value={marketplaceBanner.title}
                    onChange={(e) => handleBannerChange('title', e.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
                <FormControl>
                  <FormLabel>CTA Text</FormLabel>
                  <Input
                    placeholder="CTA Text"
                    value={marketplaceBanner.cta_text}
                    onChange={(e) => handleBannerChange('cta_text', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>CTA Link</FormLabel>
                  <Input
                    placeholder="CTA Link"
                    value={marketplaceBanner.cta_link}
                    onChange={(e) => handleBannerChange('cta_link', e.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" justify="flex-start" align="flex-start" spacing="20px">
                <FormControl width={'unset'}>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    placeholder="Description"
                    value={marketplaceBanner.description}
                    onChange={(e) => handleBannerChange('description', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Pages</FormLabel>
                  <CheckboxGroup
                    onChange={handlePagesChanges}
                    value={marketplaceBanner.pages as unknown as number[] | string[]}
                  >
                    <Stack direction="column">
                      {PAGES_OPTIONS.map((page) => (
                        <Checkbox colorScheme="primary" key={page.name} value={page.value}>
                          {page.name}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </Stack>

              {marketplace?.text_overrides &&
                marketplace?.text_overrides.map((override) => {
                  if (override.edit_level === 'staff' && !isStaff) return null;
                  const overrideValue =
                    override.config_type === 'marketplace'
                      ? textOverrides[override.name] ?? override.default_value
                      : override.default_value;
                  return (
                    <Stack
                      key={override.id}
                      direction="row"
                      justify="flex-start"
                      align="flex-start"
                      spacing="20px"
                      w="full"
                    >
                      {isStaff && (
                        <FormControl>
                          <FormLabel w={'100%'}>
                            {override.label ?? override.name} (Global Default)
                            {override.description && (
                              <InfoTooltip description={override.description} />
                            )}
                          </FormLabel>
                          <Input
                            w={'80%'}
                            value={defaultOverrides[override.name] ?? ''}
                            onChange={(e) =>
                              handleDefaultOverrideChange(override.name, e.target.value)
                            }
                          />
                        </FormControl>
                      )}
                      {override.config_type === 'marketplace' && (
                        <FormControl>
                          <FormLabel>
                            {override.label ?? override.name}
                            {override.description && (
                              <InfoTooltip description={override.description} />
                            )}
                          </FormLabel>
                          <Input
                            value={overrideValue ?? ''}
                            onChange={(e) =>
                              handleTextOverrideChange(override.name, e.target.value)
                            }
                          />
                        </FormControl>
                      )}
                    </Stack>
                  );
                })}
            </Stack>
            <Divider />
          </Stack>
          {isLoading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              backgroundColor="rgba(255, 255, 255, 0.7)"
            >
              <Spinner />
            </Box>
          )}
        </Box>
      </Stack>
      <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'} pt={10}>
        <Flex direction={'row'}>
          <Button leftIcon={<MdDownload />} onClick={donwloadListingViews}>
            Listing Views
          </Button>
        </Flex>
        <Box display="flex" justifyContent="flex-end">
          <Link href="/">
            <Button variant="outline" colorScheme="primary" mr={4}>
              Cancel
            </Button>
          </Link>
          <Button
            isLoading={isSubmitting}
            colorScheme="primary"
            type="submit"
            data-test="submit-btn"
          >
            Save
          </Button>
        </Box>
      </Flex>
    </form>
  );
};

export default MarketplaceForm;
