import React from 'react';
import { Code, Flex, Text } from '@chakra-ui/react';
import { PromoAutomationTemplateNamespaceMapping } from 'src/api/v1-api';

interface DynamicFieldsGuideProps {
  globalPromoMediaTypeNamespaceMapping: PromoAutomationTemplateNamespaceMapping | undefined;
}

const DynamicFieldsGuide = ({ globalPromoMediaTypeNamespaceMapping }: DynamicFieldsGuideProps) => {
  return (
    <Flex
      direction="column"
      borderLeft={{ base: 'none', lg: '1px solid #D0D0D0' }}
      paddingLeft={{ base: 0, lg: 8 }}
      basis="100%"
    >
      <Text mt={4} fontSize="md" color="gray.500" fontWeight="bold">
        Dynamic fields
      </Text>
      <Text my={4} fontSize="sm">
        Find below the available dynamic fields that you can use in the template.
        <br />
        You can use these fields in the template by wrapping them in double curly braces. For
        example, if you want to use the title of the listing, you can use{' '}
        <Code>{'Check this {{listing.title}}'}</Code>
      </Text>
      {globalPromoMediaTypeNamespaceMapping?.namespace_mapping && (
        <ul style={{ marginLeft: '24px' }}>
          {globalPromoMediaTypeNamespaceMapping.namespace_mapping.map((field) => (
            <li key={field}>
              {field}
              {globalPromoMediaTypeNamespaceMapping.fields_description &&
                globalPromoMediaTypeNamespaceMapping.fields_description[field] && (
                  <ul style={{ marginLeft: '16px' }}>
                    <li>
                      Type: {globalPromoMediaTypeNamespaceMapping.fields_description[field].type}
                    </li>
                    {globalPromoMediaTypeNamespaceMapping.fields_description[field].type ===
                      'method' &&
                      globalPromoMediaTypeNamespaceMapping.fields_description[field].arguments && (
                        <li>
                          Arguments:
                          <ul style={{ marginLeft: '16px' }}>
                            {(
                              globalPromoMediaTypeNamespaceMapping.fields_description[field]
                                .arguments ?? []
                            ).map((arg) => (
                              <li key={arg}>{arg}</li>
                            ))}
                          </ul>
                        </li>
                      )}
                    {globalPromoMediaTypeNamespaceMapping.fields_description[field].examples && (
                      <li>
                        Examples:
                        <ul style={{ marginLeft: '16px' }}>
                          {globalPromoMediaTypeNamespaceMapping.fields_description[
                            field
                          ].examples.map((example) => (
                            <li key={example}>
                              <Code>{example}</Code>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                  </ul>
                )}
            </li>
          ))}
        </ul>
      )}
    </Flex>
  );
};

export default DynamicFieldsGuide;
