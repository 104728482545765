import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Switch,
  Textarea,
  CheckboxGroup,
  Stack,
  Checkbox,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Attribute, AttributeOption } from 'src/api/v1-api';
import { BasicAttributeValue, ValueObject } from 'src/api/v1-api';
import UrlInput from '../url-input';
import ListingImageEditor from 'src/components/molecules/listing-image-editor';
import { FilePath } from 'src/components/organisms/listing-form';
import InfoTooltip from '../info-tooltip';

export interface AttributeInputProps<T> {
  attribute: Attribute;
  width?: string;
  isMobileView?: boolean;
  value?:
    | string
    | number
    | ValueObject
    | BasicAttributeValue
    | readonly string[]
    | boolean
    | undefined
    | FilePath[]
    | FilePath
    | ValueObject[]
    | AttributeOption[];
  setChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setSelectChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  setSwitchChange?: (checked: boolean) => void;
  disabled?: boolean;
  setUrlChange?: (value: ValueObject) => void;
  setMediaChange?: (value: FilePath[]) => void;
  isInvalid?: boolean;
  setMultiselectChange?: (value: AttributeOption[]) => void;
  skipRequired?: boolean;
  requiredOverride?: boolean;
}

function AttributeInput<T>({
  attribute,
  width,
  value,
  setChange,
  setSelectChange,
  setSwitchChange,
  disabled,
  setUrlChange,
  setMediaChange,
  isInvalid,
  setMultiselectChange,
  skipRequired,
  requiredOverride,
}: AttributeInputProps<T>): JSX.Element {
  const [options, setOptions] = useState<(number | string)[]>([]);

  useEffect(() => {
    if (attribute && attribute.format === 'multiselect' && value) {
      const updatedOptions = (attribute?.options as AttributeOption[]).filter((option) =>
        (value as AttributeOption[]).find((v) => v.name === option.name),
      );
      const values = updatedOptions.map((option) => option.name);
      if (value && attribute.format === 'multiselect') {
        setOptions(values);
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  }, [attribute]);

  if (!attribute) return <></>;

  function extractValueFromObject(obj: ValueObject): string {
    if (typeof obj.value === 'string' || typeof obj.value === 'number') {
      return obj.value.toString();
    } else if (obj.value && typeof obj.value === 'object') {
      return extractValueFromObject(obj.value as ValueObject);
    } else if (obj.values && (obj.values as ValueObject[]).length > 0) {
      return extractValueFromObject((obj.values as unknown as ValueObject[])[0]);
    }
    return '';
  }

  function isValueObject(obj: any): obj is ValueObject {
    return obj && (obj.value !== undefined || obj.values !== undefined);
  }

  function isAttributeValue(obj: any): obj is BasicAttributeValue {
    return obj && obj.value_object !== undefined;
  }

  function handleSwitchChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (setSwitchChange) {
      setSwitchChange(e.target.checked);
    }
  }

  // TODO(Sean): Revisit this to allow for multiple values
  let finalValue: string | number | readonly string[] | undefined = undefined;
  if (typeof value === 'object' && value !== null) {
    if (isValueObject(value)) {
      finalValue = extractValueFromObject(value);
    } else if (isAttributeValue(value)) {
      if (isValueObject(value.value_object.value)) {
        finalValue = extractValueFromObject(value.value_object.value);
      } else {
        finalValue = value.value_object.value?.toString();
      }
    }
  } else {
    finalValue = value?.toString();
  }

  let filePaths: FilePath[] = [];
  // value may temporarily be a function while we are updating setFilePaths via callback to ensure an array of updates
  // merges correctly
  if (
    attribute.format === 'media' &&
    typeof value !== 'function' &&
    value &&
    (value as FilePath[]).length > 0
  ) {
    filePaths = (value as FilePath[])
      .map((file) => ({
        filePath: (file.value as string) ? file.value : file.filePath,
        position: file.position,
      }))
      .sort((a, b) => a.position - b.position);
  }

  const optionalText = attribute?.required || requiredOverride ? '' : ' (Optional)';
  const attributeLabel = attribute?.name + optionalText;

  let rangeText = '';
  const minValue = attribute?.min_value;
  const maxValue = attribute?.max_value;

  const hasMinValue = minValue != null && minValue !== '';
  const hasMaxValue = maxValue != null && maxValue !== '';

  if (hasMinValue && hasMaxValue) {
    const minText = minValue.toString();
    const maxText = maxValue.toString();
    rangeText = `${minText} - ${maxText}`;
  } else if (hasMinValue) {
    const minText = minValue.toString();
    rangeText = `Min: ${minText}`;
  } else if (hasMaxValue) {
    const maxText = maxValue.toString();
    rangeText = `Max: ${maxText}`;
  }
  const handleMultiselectChange = (value: (number | string)[]) => {
    if (
      typeof setMultiselectChange == 'function' &&
      attribute &&
      attribute?.options &&
      (attribute?.options as AttributeOption[])?.length !== 0
    ) {
      const updatedOptions = (attribute?.options as AttributeOption[]).filter((option) =>
        value.includes(option.name),
      );
      setOptions(value);
      setMultiselectChange(updatedOptions);
    }
  };

  switch (attribute.format.toLowerCase()) {
    case 'datetime':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Input
            key={attribute?.id}
            name={attribute?.name}
            width={width}
            size="md"
            type="datetime-local"
            value={finalValue}
            onChange={setChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
          />
        </FormControl>
      );
    case 'date':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Input
            key={attribute?.id}
            name={attribute?.name}
            width={width}
            size="md"
            type="date"
            value={finalValue}
            onChange={setChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
          />
        </FormControl>
      );
    case 'email':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Input
            key={attribute?.id}
            name={attribute?.name}
            width={width}
            size="md"
            type="email"
            value={finalValue}
            onChange={setChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
          />
        </FormControl>
      );
    case 'url':
      return (
        <UrlInput
          key={attribute.id}
          attribute={attribute}
          width="full"
          isMobileView
          urlValue={value as ValueObject}
          setChange={setUrlChange}
          disabled={disabled}
        />
      );
    case 'json':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Textarea
            key={attribute?.id}
            name={attribute?.name}
            width={width}
            value={finalValue}
            onChange={setChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
          />
        </FormControl>
      );
    case 'select':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Select
            key={attribute?.id}
            name={attribute?.name}
            placeholder={`Select ${attribute?.name}`}
            width={width}
            value={finalValue}
            onChange={setSelectChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
          >
            {attribute?.options && Array.isArray(attribute.options)
              ? attribute.options
                  .sort((a, b) => a.position - b.position)
                  .map((option) => (
                    <option key={option?.id} value={option?.value as string | number}>
                      {option?.name}
                    </option>
                  ))
              : null}
          </Select>
        </FormControl>
      );
    case 'phone':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>🇺🇸 +1</InputLeftAddon>
            <Input
              key={attribute?.id}
              name={attribute?.name}
              size="md"
              type="tel"
              width={width}
              value={finalValue}
              // TODO(Sean): Normalize input value here with normalizeInput function
              onChange={setChange}
              disabled={disabled}
              isRequired={(attribute?.required && !skipRequired) || requiredOverride}
            />
          </InputGroup>
        </FormControl>
      );
    case 'boolean':
      return (
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="email-alerts">
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Switch
            key={attribute?.id}
            name={attribute?.name}
            id={attribute?.name}
            colorScheme="primary"
            isChecked={!!finalValue}
            onChange={handleSwitchChange}
            disabled={disabled}
          />
        </FormControl>
      );
    case 'text':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Input
            key={attribute?.id}
            name={attribute?.name}
            width={width}
            size="md"
            type="text"
            value={finalValue}
            onChange={setChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
            data-test={`${attribute?.id}-text-input`}
          />
        </FormControl>
      );
    case 'time':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Input
            key={attribute?.id}
            name={attribute?.name}
            width={width}
            size="md"
            type="time"
            value={finalValue}
            onChange={setChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
          />
        </FormControl>
      );
    case 'number':
    case 'decimal':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            {rangeText ? `, ${rangeText}` : ''}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Input
            key={attribute?.id}
            name={attribute?.name}
            width={width}
            size="md"
            type="number"
            value={finalValue}
            onChange={setChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
            isInvalid={isInvalid || false}
            min={attribute?.min_value}
            max={attribute?.max_value}
            data-test="number-input"
          />
        </FormControl>
      );
    case 'year':
      const currentYear = new Date().getFullYear();
      const startYear = attribute?.min_value ? Number(attribute.min_value) : 1900;
      const endYear = attribute?.max_value ? Number(attribute.max_value) : currentYear + 1;
      const yearList = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <Select
            key={attribute?.id}
            name={attribute?.name}
            placeholder={`Select ${attribute?.name}`}
            width={width}
            value={finalValue}
            onChange={setSelectChange}
            disabled={disabled}
            isRequired={(attribute?.required && !skipRequired) || requiredOverride}
          >
            {yearList.reverse().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    case 'media':
      return (
        <>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          {setMediaChange && (
            <ListingImageEditor
              maxFiles={attribute?.max_value ? Number(attribute.max_value) : 24}
              filePaths={filePaths}
              setFilePaths={setMediaChange}
              attribute={attribute}
            />
          )}
          <Divider my={{ base: 4, lg: 6 }} />
        </>
      );
    case 'multiselect':
      return (
        <FormControl>
          <FormLabel>
            {attributeLabel}
            <InfoTooltip description={attribute?.seller_description} />
          </FormLabel>
          <CheckboxGroup
            onChange={handleMultiselectChange}
            value={options as unknown as number[] | string[]}
          >
            <Stack direction="column">
              {attribute?.options && Array.isArray(attribute.options)
                ? attribute.options
                    .sort((a, b) => a.position - b.position)
                    .map((option) => (
                      <Checkbox colorScheme="primary" key={option?.id} value={option?.name}>
                        {option?.name}
                      </Checkbox>
                    ))
                : null}
            </Stack>
          </CheckboxGroup>
        </FormControl>
      );
    default:
      return <></>;
  }
}

export default AttributeInput;
