import React from 'react';
import {
  Card,
  Flex,
  Text,
  LinkBox,
  LinkOverlay,
  Box,
  Icon,
  Image,
  useTheme,
} from '@chakra-ui/react';
import { FaStoreAlt } from 'react-icons/fa';
import { MarketplaceLinkInBioItem } from 'src/api/v1-api';
import { getSocialProfileIcon } from 'src/utils/socialProfileIcons';
import { srcFromCDN } from 'src/utils/files';
import { capitalize } from 'lodash';

interface LinkInBioProps {
  item: MarketplaceLinkInBioItem;
}

const getCategoryImage = (category: any) => {
  if (category?.image_url && category?.image_url !== '') {
    return category.image_url;
  }

  if (category?.category_card_image_url && category?.category_card_image_url !== '') {
    return category.category_card_image_url;
  }
};

const LinkInBioLink = ({ item }: LinkInBioProps) => {
  const theme = useTheme();

  const renderImage = (imageSize = 32) => {
    if (!item.show_image) return null;

    if (item.image_url) {
      return (
        <Image
          src={srcFromCDN(item.image_url, imageSize)}
          alt={item.title}
          boxSize={`${imageSize}px`}
          width={`${imageSize}px`}
          height={`${imageSize}px`}
          objectFit="cover"
          fallback={<Icon as={FaStoreAlt} color="black" fontSize={imageSize} />}
          borderRadius={'full'}
        />
      );
    }

    switch (item.entity_object_name) {
      case 'Marketplace':
        return <Icon as={FaStoreAlt} color="black" fontSize={imageSize} />;
      case 'Category':
        return (
          <Image
            src={srcFromCDN(getCategoryImage(item.entity_object), imageSize)}
            alt={item.title}
            boxSize={`${imageSize}px`}
            width={`${imageSize}px`}
            height={`${imageSize}px`}
            objectFit="cover"
            fallback={<Icon as={FaStoreAlt} color="black" fontSize={imageSize} />}
            borderRadius={'full'}
          />
        );
      case 'SocialProfile':
        return getSocialProfileIcon({
          networkName: item.entity_object.network ?? '',
          size: `${imageSize}px`,
          fillColor: theme.colors.mainText,
        });
      default:
        return null;
    }
  };

  const renderTitle = () => {
    if (item.title != '') {
      return item.title;
    }

    switch (item.entity_object_name) {
      case 'SocialProfile':
        return capitalize(item.entity_object?.network);
      case 'Marketplace':
      case 'Category':
        return item.entity_object?.name;
      default:
        return '';
    }
  };

  return (
    <LinkBox>
      <Card py={3} pl={2} pr={8}>
        <Flex direction="row" alignItems={'center'}>
          <Box width={'32px'} height={'32px'} mr={2}>
            {renderImage()}
          </Box>
          <Flex flex={1} justifyContent="center">
            <LinkOverlay
              href={item.link}
              isExternal={!['Marketplace', 'Category'].includes(item.entity_object_name ?? '')}
            >
              <Text mr="14px" fontWeight={500} casing="capitalize">
                {renderTitle()}
              </Text>
            </LinkOverlay>
          </Flex>
        </Flex>
      </Card>
    </LinkBox>
  );
};

export default LinkInBioLink;
