import {
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Switch,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { Attribute, AttributeOption, BasicCategory } from 'src/api/v1-api';
import { AttributeInput } from 'src/components/atoms';
import { FilePath, FlatVariant } from 'src/components/organisms/listing-form';
import { useEffect, useState } from 'react';
import { MINIMUM_PRICE_ALLOWED } from 'src/constants/marketplace';
import { BLACK } from 'src/constants/ui';

type VariantFormProps = {
  selectedCategory: BasicCategory | null;
  currentVariant: FlatVariant | null;
  isSingleVariant: boolean;
  setCurrentVariant: (
    updateFunction: (prevVariant: FlatVariant | null) => FlatVariant | null,
  ) => void;
};

const VariantForm = ({
  selectedCategory,
  currentVariant,
  setCurrentVariant,
  isSingleVariant,
}: VariantFormProps) => {
  useEffect(() => {
    if (!currentVariant) {
      setCurrentVariant(() => ({
        regular_price: '',
        offered_price: '',
        variant_attribute_values: {},
      }));
    } else {
      if (!isOnSale) {
        setIsOnSale(!!currentVariant?.regular_price);
      }
    }
  }, [currentVariant, setCurrentVariant]);

  const [isOnSale, setIsOnSale] = useState(!!currentVariant?.regular_price);

  const handleSaleToggle = () => {
    setIsOnSale(!isOnSale);
    if (!isOnSale) {
      setCurrentVariant((prevValue) => ({
        ...prevValue,
        regular_price: prevValue?.offered_price,
      }));
    } else {
      setCurrentVariant((prevValue) => ({
        ...prevValue,
        regular_price: '',
      }));
    }
  };

  const handleAttributeChange = (
    attributeId: number | string,
    value: string | number | boolean | FilePath[] | AttributeOption[],
  ) => {
    setCurrentVariant((prevValue) => {
      if (prevValue) {
        return {
          ...prevValue,
          variant_attribute_values: {
            ...(prevValue.variant_attribute_values || {}),
            [attributeId]: value,
          },
        };
      }
      return prevValue;
    });
  };

  if (!selectedCategory) return <></>;

  return (
    <>
      <GridItem colSpan={{ base: 3, md: 3 }}>
        <FormControl display="flex" alignItems="center">
          <FormLabel mb="0">On Sale:</FormLabel>
          <Switch
            size="md"
            isChecked={isOnSale}
            onChange={handleSaleToggle}
            colorScheme="primary"
          />
        </FormControl>
      </GridItem>

      {!isSingleVariant && (
        <GridItem colSpan={{ base: 3, md: 1 }}>
          <HStack>
            <FormControl>
              <FormLabel>Quantity:</FormLabel>
              <Input
                placeholder="0"
                type="number"
                min="0"
                max={selectedCategory?.functional.max_price}
                value={currentVariant?.quantity}
                onChange={(e) =>
                  setCurrentVariant((prevValue) => ({
                    ...prevValue,
                    quantity: parseInt(e.target.value),
                  }))
                }
                data-test="variant-quantity"
              />
            </FormControl>
          </HStack>
        </GridItem>
      )}

      {isOnSale && (
        <GridItem colSpan={{ base: 3, md: 1 }}>
          <HStack>
            <FormControl>
              <FormLabel>Old Price (will be crossed out):</FormLabel>
              <InputGroup>
                <InputLeftAddon color={BLACK}>$</InputLeftAddon>
                <NumberInput
                  name="regular_price"
                  min={MINIMUM_PRICE_ALLOWED}
                  max={selectedCategory?.functional.max_price}
                  value={currentVariant?.regular_price || ''}
                  isDisabled={!isOnSale}
                  precision={2}
                  onChange={(v) =>
                    setCurrentVariant((prevValue) => ({
                      ...prevValue,
                      regular_price: v,
                    }))
                  }
                  data-test="variant-on-sale-price"
                >
                  <NumberInputField borderLeftRadius={0} />
                </NumberInput>
              </InputGroup>
            </FormControl>
          </HStack>
        </GridItem>
      )}

      <GridItem colSpan={{ base: 3, md: 1 }}>
        <HStack>
          <FormControl>
            {isOnSale && <FormLabel>Sale Price:</FormLabel>}
            {!isOnSale && <FormLabel>Price:</FormLabel>}
            <InputGroup>
              <InputLeftAddon color={BLACK}>$</InputLeftAddon>
              <NumberInput
                name="offered_price"
                min={MINIMUM_PRICE_ALLOWED}
                precision={2}
                value={currentVariant?.offered_price || ''}
                max={selectedCategory?.functional.max_price}
                onChange={(v) =>
                  setCurrentVariant((prevValue) => ({
                    ...prevValue,
                    offered_price: v,
                  }))
                }
                data-test="offered-price"
              >
                <NumberInputField borderLeftRadius={0} />
              </NumberInput>
            </InputGroup>
          </FormControl>
        </HStack>
      </GridItem>

      {selectedCategory?.attributes
        ?.filter((attr) => attr.nested_content_type_name === 'Variant')
        ?.sort((a, b) => a.position - b.position)
        ?.map((attribute) => {
          const attributeValue = currentVariant?.variant_attribute_values?.[attribute.id];

          return (
            <GridItem
              colSpan={{ base: 2, md: 1 }}
              key={attribute.id}
              data-test="variant-attributes"
            >
              <AttributeInput<Attribute>
                attribute={attribute}
                width="full"
                isMobileView
                value={attributeValue || ''}
                setChange={(e) => handleAttributeChange(attribute.id, e.target.value)}
                setSelectChange={(e) => handleAttributeChange(attribute.id, e.target.value)}
                setSwitchChange={(checked) => handleAttributeChange(attribute.id, checked)}
                setMediaChange={(filePaths) => handleAttributeChange(attribute.id, filePaths)}
                setMultiselectChange={(value) => handleAttributeChange(attribute.id, value)}
                skipRequired
              />
            </GridItem>
          );
        })}
    </>
  );
};

export default VariantForm;
