import { Box, Icon, Image, Stack, Text, Link, Center, Button, Flex } from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import { useMarketplaceContext } from 'src/contexts/marketplace';
import { LomaUploader } from 'src/components/molecules';
import { FilePath } from 'src/components/organisms/listing-form';
import { Attribute } from 'src/api/v1-api/helpers';
import { checkFileFormat, getMediaFileName } from 'src/utils/attributeUtils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FaFile, FaImage, FaVideo } from 'react-icons/fa';
import * as Sentry from '@sentry/nextjs';

interface ListingImageEditorProps {
  maxFiles: number;
  filePaths: FilePath[];
  setFilePaths?: (filePaths: FilePath[]) => void;
  attribute?: Attribute;
  setImageUploading?: Dispatch<SetStateAction<boolean>>;
}

const FileIcon = ({ src }: any) => {
  const fileFormat = checkFileFormat(src);
  if (fileFormat === 'video') {
    return (
      <Center width="100%" height="100%" bgColor={'grey'}>
        <FaVideo color="white" size={28} />
      </Center>
    );
  } else if (fileFormat === 'image') {
    return (
      <Center width="100%" height="100%" bgColor={'grey'}>
        <FaImage color="white" size={28} />
      </Center>
    );
  } else {
    return (
      <Center width="100%" height="100%" bgColor={'grey'}>
        <FaFile color="white" size={28} />
      </Center>
    );
  }
};

const ListingImageEditor = ({
  maxFiles,
  filePaths,
  setFilePaths,
  attribute,
  setImageUploading,
}: ListingImageEditorProps) => {
  const { marketplace, supportEmail } = useMarketplaceContext();
  const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
  const [updatedFilePaths, setUpdatedFilePaths] = useState(filePaths ? filePaths : []);

  useEffect(() => {
    setImageUploading && setImageUploading(uploadingFiles.length > 0);
  }, [setImageUploading, uploadingFiles]);

  useEffect(() => {
    // Track when uploads take longer than 30 seconds
    let uploadTimer: NodeJS.Timeout;

    if (uploadingFiles.length > 0) {
      // Log upload start
      Sentry.addBreadcrumb({
        category: 'upload',
        message: `Started uploading ${uploadingFiles.length} files`,
        level: 'info',
        data: {
          uploadingFiles,
          existingFiles: filePaths.length,
        },
      });

      // Set timer to detect stuck uploads
      uploadTimer = setTimeout(() => {
        Sentry.captureMessage('Photo upload potentially stuck', {
          level: 'warning',
          tags: { upload_type: 'listing_images' },
          extra: {
            uploadingFiles,
            uploadStartTime: Date.now() - 30000,
            existingFiles: filePaths.length,
            totalExpectedFiles: maxFiles,
          },
        });
      }, 30000);
    }

    return () => {
      if (uploadTimer) {
        clearTimeout(uploadTimer);
      }
    };
  }, [uploadingFiles, filePaths.length, maxFiles]);

  const onSortEnd = async (sourceIndex: number, targetIndex: number) => {
    if ((attribute && !attribute?.carousel_reorder) || !setFilePaths) {
      return;
    } else {
      const newOrderedFilePaths = arrayMove(filePaths, sourceIndex, targetIndex).map(
        (filePath, index) => ({
          ...filePath,
          position: index,
        }),
      );
      setUpdatedFilePaths(newOrderedFilePaths);
    }
  };

  const onFileDelete = async (filePathToDelete: string | undefined) => {
    if (!setFilePaths || !filePathToDelete) {
      return;
    }
    const updatedFilePaths = filePaths.filter((filePath) => filePath.filePath !== filePathToDelete);
    updatedFilePaths.forEach((filePath, index) => {
      filePath.position = index;
    });
    setUpdatedFilePaths(updatedFilePaths);
  };

  let supportText = (
    <Text fontSize="xs" color="gray.500" mt="2">
      Need more than {maxFiles} {maxFiles === 1 ? 'file' : 'files'}? Email us at{' '}
      <Link
        href={`mailto:${supportEmail}`}
        target="_blank"
        style={{ textDecoration: 'underline' }}
        onClick={(e) => e.stopPropagation()}
      >
        {supportEmail}
      </Link>
    </Text>
  );

  if (attribute) {
    const minFiles = attribute.min_value;
    const minPlural = minFiles === 1 ? 'file' : 'files';
    const maxFiles = attribute.max_value;
    const maxPlural = maxFiles === 1 ? 'file' : 'files';

    if (minFiles && maxFiles) {
      if (minFiles === maxFiles) {
        supportText = (
          <Text fontSize="xs" color="gray.500" mt="2">
            Please upload exactly {minFiles} {minPlural}
          </Text>
        );
      } else {
        supportText = (
          <Text fontSize="xs" color="gray.500" mt="2">
            Please upload between {minFiles} and {maxFiles} {maxPlural}
          </Text>
        );
      }
    } else if (minFiles) {
      supportText = (
        <Text fontSize="xs" color="gray.500" mt="2">
          Please upload at least {minFiles} {minPlural}
        </Text>
      );
    } else if (maxFiles) {
      supportText = (
        <Text fontSize="xs" color="gray.500" mt="2">
          Please upload at most {maxFiles} {maxPlural}
        </Text>
      );
    }
  }

  // Hardcode some marketplace specific support text for Samuraiforsale
  if (marketplace && marketplace.id === 27) {
    if (maxFiles === 10 || maxFiles === 24) {
      supportText = (
        <Text fontSize="xs" color="gray.500" mt="2">
          Need more than {maxFiles} files? Upgrade your listing package above!
        </Text>
      );
    } else if (maxFiles === 100) {
      supportText = (
        <Text fontSize="xs" color="gray.500" mt="2">
          Uploading more than {maxFiles} files at a time? Please save your draft with your first{' '}
          {maxFiles} photos before adding more. Email us at{' '}
          <Link
            href={`mailto:${supportEmail}`}
            target="_blank"
            style={{ textDecoration: 'underline' }}
            onClick={(e) => e.stopPropagation()}
          >
            {supportEmail}
          </Link>{' '}
          for help.
        </Text>
      );
    }
  }

  return (
    <>
      {filePaths && filePaths.length > 0 && (
        <Stack>
          {filePaths.length > 1 && (
            <Text fontSize="xs" color="gray.500" mt="2">
              Drag and drop to reorder
            </Text>
          )}
          <SortableList
            onSortEnd={onSortEnd}
            className="listing-image-list"
            draggedItemClassName="dragged"
            draggable={false}
          >
            {filePaths.map((filePathObj, index) => {
              return (
                <SortableItem key={filePathObj.filePath}>
                  <Stack>
                    <Text fontSize="xs">{getMediaFileName(filePathObj.filePath)}</Text>
                    <div className="item">
                      <Box
                        key={filePathObj.filePath}
                        position="relative"
                        borderRadius="md"
                        overflow="hidden"
                        height="100px"
                        width="100px"
                      >
                        <Image
                          width="100%"
                          height="100%"
                          objectFit="cover"
                          src={filePathObj.filePath}
                          alt="Uploaded Image"
                          fallback={<FileIcon src={filePathObj.filePath} />}
                        />
                        <Box
                          position="absolute"
                          top="0"
                          right="0"
                          width="full"
                          height="full"
                          bg="gray.100"
                          opacity={0}
                          _hover={{ opacity: 0.6 }}
                        >
                          <Icon
                            position="absolute"
                            top="1"
                            right="1"
                            as={MdDelete}
                            boxSize="8"
                            color="red.500"
                            cursor="pointer"
                            onClick={onFileDelete && (() => onFileDelete(filePathObj.filePath))}
                          />
                        </Box>
                      </Box>
                    </div>
                  </Stack>
                </SortableItem>
              );
            })}
          </SortableList>
        </Stack>
      )}
      {filePaths && filePaths.length > 0 && (
        <Text fontSize="xs" color="gray.500" mt="4">
          {filePaths.length} {filePaths.length === 1 ? 'file' : 'files'} uploaded
        </Text>
      )}
      <LomaUploader
        maxFiles={maxFiles}
        file_type={attribute?.file_type}
        filePathsLength={filePaths && filePaths.length ? filePaths.length : 0}
        filePaths={filePaths}
        updatedFilePaths={updatedFilePaths}
        setFilePaths={setFilePaths}
        uploadingFiles={uploadingFiles}
        setUploadingFiles={setUploadingFiles}
      />
      {uploadingFiles && uploadingFiles.length > 0 && (
        <Flex mt={2} justifyContent="center">
          <Button size="sm" variant="outline" onClick={() => setUploadingFiles([])}>
            Cancel uploads
          </Button>
        </Flex>
      )}
      <Text>{supportText}</Text>
    </>
  );
};

export default ListingImageEditor;
