import NextLink from 'next/link';
import { Box, Text, Link, useTheme } from '@chakra-ui/react';
import { getSocialProfileIcon } from 'src/utils/socialProfileIcons';
import { MarketplaceInfo } from 'src/api/v1-api';

interface SocialFansBarProps {
  marketplace: MarketplaceInfo;
  total_social_count: string | number;
}

function SocialFansBar({ marketplace, total_social_count }: SocialFansBarProps) {
  const theme = useTheme();
  if (
    !marketplace?.social_profiles ||
    marketplace?.social_profiles?.length === 0 ||
    !total_social_count
  ) {
    return null;
  }

  return (
    <>
      {marketplace?.functional?.show_total_social_media_audience && (
        <Box display="flex" gap="0.5rem">
          <Text fontSize=".65rem" lineHeight={'1.6 !important'}>
            Join our {total_social_count} social fans on
          </Text>
          <Box display="flex" gap={'0.5rem'} mt={'0.15rem'}>
            {(marketplace?.social_profiles ?? [])
              ?.filter((sp) => sp.show_on_header_menu)
              .map((social_profile) => (
                <Link
                  isExternal={true}
                  as={NextLink}
                  key={social_profile.id}
                  href={social_profile.url}
                >
                  {getSocialProfileIcon({
                    networkName: social_profile.network,
                    size: '0.8rem',
                    fillColor: theme.colors.navText,
                  })}
                </Link>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
}

export default SocialFansBar;
