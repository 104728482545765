/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HStack,
  StackProps,
  Text,
  TextProps,
  useColorModeValue as mode,
  useTheme,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BLACK } from 'src/constants/ui';
import { formatPrice } from 'src/utils/common';

interface PriceProps {
  children?: ReactNode;
  isOnSale?: boolean;
  textProps?: TextProps;
}

const Price = (props: PriceProps) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = 'inherit';
  const theme = useTheme();
  const textColor = theme.colors.mainText ?? BLACK;
  const onSaleColor = `${textColor}66`;
  const color = isOnSale ? onSaleColor : defaultColor;

  return (
    <Text
      as="span"
      fontWeight="medium"
      color={color}
      textDecoration={isOnSale ? 'line-through' : 'none'}
      {...textProps}
    >
      {children}
    </Text>
  );
};

const SalePrice = (props: TextProps) => {
  const theme = useTheme();
  const textColor = theme.colors.mainText ?? BLACK;
  const color = `${textColor}CC`;
  return <Text as="span" fontWeight="semibold" color={color} {...props} />;
};

interface Props {
  regularPrice: any;
  offeredPrice?: any;
  currency?: string;
  rootProps?: StackProps;
  regularPriceProps?: TextProps;
  offeredPriceProps?: TextProps;
}

const PriceTag = ({
  regularPrice,
  offeredPrice,
  currency,
  rootProps,
  regularPriceProps,
  offeredPriceProps,
}: Props) => {
  // NOTE: Regular price is display only and only exists when the variant is on sale
  //       Offered price is the actual price of the variant
  const pricesEqual = regularPrice === offeredPrice;

  return (
    <HStack spacing="1" {...rootProps} wrap="wrap">
      {regularPrice && regularPrice > 0 && !pricesEqual && (
        <Price isOnSale={!!regularPrice && !pricesEqual} textProps={regularPriceProps}>
          {formatPrice(regularPrice, { currency, alwaysCents: false })}
        </Price>
      )}
      {offeredPrice > 0 && (
        <SalePrice {...offeredPriceProps}>
          {formatPrice(offeredPrice, { currency, alwaysCents: false })}
        </SalePrice>
      )}
    </HStack>
  );
};

export default PriceTag;
